import { faColumns3, faPlus } from '@fortawesome/pro-light-svg-icons'
import { CustomerProjectStatusModelType } from '@teinor/erp/types/company/customer/customerProjectStatus'
import { useCallback } from 'react'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../../baseComponents/Button'
import PermissionChecker from '../../../../../../../../baseComponents/PermissionChecker'
import SearchBar from '../../../../../../../../baseComponents/SearchBar'
import TwinIcon from '../../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import useEditDeleteModal from '../../../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import { getUserPermissions } from '../../../../../../../../utils/reducers/getters'
import ModalCECustomerProjectFlow from './ModalCECustomerProjectFlow'
import ModalCreateCustomerProjectFlow from './ModalCreateCustomerProjectFlow'


interface CustomerProjectFlowHeaderProps {
    statuses: CustomerProjectStatusModelType[]
    customerProjectTypeId: number
    getData: () => Promise<void>
    setSearchValue: React.Dispatch<React.SetStateAction<string>>
    searchValue: string
}

const CustomerProjectFlowHeader: React.FC<CustomerProjectFlowHeaderProps> = ({ customerProjectTypeId, getData, setSearchValue, searchValue }) => {
    const { t } = useTwinTranslation()
    const { handleModalCreateOpened, handleModalEditOpened,openModal, setOpenModal } = useCustomerProjectFlowHeaderLogic()
    return (
        <div className='flex justify-between items-end'>
            <div className='flex items-center gap-50'>
                <div className='table_searcher_header'>
                    <SearchBar className='search_bar_table' placeholder={t('searchDots', 'Buscar...')} searchValue={searchValue} onChange={(value) => setSearchValue(value)} />
                </div>
            </div>
            <div className='flex gap-20'>
                <PermissionChecker userPermissions={getUserPermissions('customer.customerProjectType')} permission={'update'}>
                    <ButtonSecondary onClick={handleModalEditOpened}>
                        <TwinIcon icon={faColumns3} className='mr-8' />
                        <TwinTrans transKey='manageStatuses'>Gestionar estados</TwinTrans>
                    </ButtonSecondary>
                </PermissionChecker>
                <PermissionChecker userPermissions={getUserPermissions('customer.projects.main')} permission={'create'}>
                    <ButtonPrimary onClick={handleModalCreateOpened}>
                        <TwinIcon icon={faPlus} className='mr-8' />
                        <TwinTrans transKey='newProject'>Nuevo proyecto</TwinTrans>
                    </ButtonPrimary>
                </PermissionChecker>
                {openModal?.type === 'create' ? <ModalCreateCustomerProjectFlow customerProjectTypeId={customerProjectTypeId} setOpened={() => setOpenModal(null)} onSubmit={getData} /> : null}
                {openModal?.type === 'edit' ? <ModalCECustomerProjectFlow customerProjectTypeId={customerProjectTypeId} setOpened={() => setOpenModal(null)} onSubmit={getData} /> : null}
            </div>
        </div>
    )
}


const useCustomerProjectFlowHeaderLogic = () => {
    const { openModal, setOpenModal } = useEditDeleteModal()

    const handleModalCreateOpened = useCallback(() => {
        setOpenModal({
            type: 'create',
            allRowData: {  }
        })
    }, [setOpenModal])
    const handleModalEditOpened = useCallback(() => {
        setOpenModal({
            type: 'edit',
            allRowData: {  }
        })
    }, [setOpenModal])

    return { handleModalCreateOpened, handleModalEditOpened, openModal, setOpenModal }
}


export default CustomerProjectFlowHeader