import { TaskModelType } from '@teinor/erp/types/company/task'
import { MutableRefObject, useCallback, useMemo } from 'react'
import { SetTaskDataExtended } from '../types'
import { faUser, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { ChangeSubtaskTy } from './types'
import { Link, useLocation } from 'react-router-dom'
import TwinIcon from '../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import { RectangularTextAreaDebounce } from '../../../../forms/Input/TextArea'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import { getPathWithoutLastSlash } from '../../../../utils/globals/link'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { DictionaryEmployee } from '../../../../utils/reducers/company/employees'
import { getEmployees, getGroups } from '../../../../utils/reducers/getters'
import { DictionaryGroup } from '../../../../utils/reducers/groups/groups'
import { addBottomNavAppElement, deleteBottomNavAppElement } from '../../../../utils/reducers/reduxDispatch'
import { CustomRenderTasksFinished, CustomRenderTaskRoundImage } from '../../TaskScreenTable/Subcomponents'
import { sortChildrenTasks } from './functions'
import { addDictionaryExtraLine } from '../../../../utils/globals/dictionary'
import { sortEmployeesByUser } from '../../../../utils/globals/employees'
import { sortGroupsByUser } from '../../../../utils/globals/groups'

interface TaskSubtasksProps {
    childrenTasks: TaskModelType[]
    setData: SetTaskDataExtended
    refSubTask: MutableRefObject<any>
    isFloating?: boolean
    setSelectedSubtaskId?: React.Dispatch<React.SetStateAction<number | null>>
}

const TaskSubtasks: React.FC<TaskSubtasksProps> = ({ childrenTasks, setData, refSubTask, isFloating, setSelectedSubtaskId }) => {
    const employees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getEmployees() || {}))), [])
    const groups = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(getGroups() || {}))), [])
    const renderThis: JSX.Element[] = []
    for (const key in childrenTasks) {
        const subTask = childrenTasks[key]
        renderThis.push(<Subtask key={subTask.id} posArr={parseInt(key)} dataSubTask={subTask} {...{ isFloating, setData, employees, groups, setSelectedSubtaskId }} />)
    }
    return (
        <div className='mb-20'>
            <div className='regular16 mb-10'>
                <TwinTrans transKey='subTasks'>Subtareas</TwinTrans>
            </div>
            <div className='border-b border-gray-EE' ref={refSubTask}>
                {renderThis}
            </div>
        </div>
    )
}

interface SubtaskProps extends SubtaskLogicProps {
    employees: DictionaryEmployee | null
    groups: DictionaryGroup | null
    isFloating?: boolean
    setSelectedSubtaskId?: React.Dispatch<React.SetStateAction<number | null>>
}

const Subtask: React.FC<SubtaskProps> = ({ dataSubTask, employees, groups, isFloating = false, setSelectedSubtaskId, ...rest}) => {
    const {t} = useTwinTranslation()
    const { changeNameSubTask, updateSubtask, handleOpenFloatingSubtask } = useSubtaskLogic({ dataSubTask, ...rest})
    const location = useLocation()
    const myOnClickFunction = setSelectedSubtaskId || handleOpenFloatingSubtask
    return (
        <div className='subtask_container flex items-center border-t border-gray-EE'>
            <div>
                <CustomRenderTasksFinished value={String(dataSubTask?.finished) === 'true'} onChange={(value) => updateSubtask('finished', value)} allRowData={dataSubTask} width={50} extraToolTip='subtask' />
            </div>
            <div className='mr-10 text-green-43'>#{dataSubTask?.id}</div>
            <RectangularTextAreaDebounce label='' placeholder={t('titleTask', 'Título de la tarea')} className='flex-auto input_change_subtask_name mr-20 rounded-md focus:border-red-BA' value={dataSubTask?.name || ''} onChange={changeNameSubTask} onBlur={(e)=> {
                const val = e.currentTarget.value
                if (val !== dataSubTask?.name ){
                    changeNameSubTask(e.currentTarget.value)
            }}} />
            <CustomRenderTaskRoundImage id={dataSubTask?.id} field='EmployeeId' value={dataSubTask?.EmployeeId} items={employees || {}} icon={faUser} img={dataSubTask?.Employee?.profile_image} name={dataSubTask?.Employee?.fullname_short} className='mr-20 cursor-pointer w-30 h-30' onChange={(value) => updateSubtask('EmployeeId', value)} sortFunction={sortEmployeesByUser} />
            <CustomRenderTaskRoundImage id={dataSubTask?.id} field='GroupId' value={dataSubTask?.GroupId} items={groups || {}} icon={faUserGroup} img={dataSubTask?.Group?.photo} name={dataSubTask?.Group?.name} className='mr-20 cursor-pointer w-30 h-30' onChange={(value) => updateSubtask('GroupId', value)} sortFunction={sortGroupsByUser} />
            {isFloating || setSelectedSubtaskId ? 
                <TwinIcon icon={faChevronRight} className='cursor-pointer hover:text-green-43' onClick={() => myOnClickFunction(dataSubTask.id)}/>
                :
                <Link className='hover:text-green-43' to={getPathWithoutLastSlash(location.pathname) + '/' + dataSubTask?.id}>
                    <TwinIcon icon={faChevronRight} className='cursor-pointer' />
                </Link>
            }
        </div>
    )
}

interface SubtaskLogicProps {
    posArr: number
    dataSubTask: TaskModelType
    setData: SetTaskDataExtended
}

const useSubtaskLogic = ({ dataSubTask, setData }: SubtaskLogicProps) => {

    const handleChangeSubtask: ChangeSubtaskTy = useCallback((field, value) => {
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            for (const subtask of copyOld.ChildrenTasks) {
                if (parseInt(String(subtask.id)) === parseInt(String(dataSubTask.id))){
                    subtask[field] = value
                }
            }
            if(field === 'finished'){
                copyOld.ChildrenTasks = sortChildrenTasks(copyOld.ChildrenTasks)
            }
            return { ...copyOld }
        })
    }, [setData, dataSubTask.id])

    const updateSubtask: ChangeSubtaskTy = useCallback(async(field, value) => {
        const result = await twinFetchPostJSON('/api/app/task/updateTask', { id: dataSubTask.id, [field]: value, TaskId: dataSubTask.TaskId  })
        if (result) {
            handleChangeSubtask(field, value)
        }
    }, [dataSubTask?.id, dataSubTask?.TaskId, handleChangeSubtask])

    const changeNameSubTask = useCallback((value: string) => {
        updateSubtask('name', value)
    }, [updateSubtask])
    const handleOpenFloatingSubtask = useCallback(() => {
        deleteBottomNavAppElement('taskModal-' + dataSubTask?.TaskId )
        addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + dataSubTask?.id, extraData: { id: dataSubTask?.id, opened: true, skipAnimation: true } })
    }, [dataSubTask?.TaskId, dataSubTask?.id])

    return { updateSubtask, changeNameSubTask, handleOpenFloatingSubtask }
}



export default TaskSubtasks