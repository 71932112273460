import { ModalEditComponentProps } from '../../../../../../../baseComponents/ModalsLayouts/types'
import useTwinTranslation from '../../../../../../../utils/hooks/useTwinTranslation'
import { FormElement, FormRender } from '../../../../../../../forms/FormRenderer/types'
import { getEmployees } from '../../../../../../../utils/reducers/getters'
import { ModalCreateEditMediumCustomLoading } from '../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { Fragment, useCallback, useState } from 'react'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../../utils/globals/data'
import { TwinDictionary, dictionaryComplexFromJsonArr } from '../../../../../../../utils/globals/dictionary'
import { WCalHolidayBagModelType } from '@teinor/erp/types/company/WCalParent/WCal/WCalHolidayBag'
import TwinCalendarStartEnd from '../../../../../../../baseComponents/TwinCalendar/TwinCalendarStartEnd'
import { changeSuccessMessage, changeErrorMessage } from '../../../../../../../utils/reducers/reduxDispatch'
import { ModalOpenedSetTy } from '../../../../../../../baseComponents/Modal/types'
import { OnSubmit } from '../../../../../../../forms/TwinForm/types'
import { WCalHolidayUsedDayDateSelected, WCalHolidayUsedDayModelTypeExtended } from './types'
import { ButtonPrimary } from '../../../../../../../baseComponents/Button'
import TwinTrans from '../../../../../../../baseComponents/TwinTrans'
import TableHolidayRequests from './TableHolidayRequests'
import useIsLoading from '../../../../../../../utils/hooks/useIsLoading'
import Checkbox from '../../../../../../../forms/Checkbox'
import { sortEmployeesByUser } from '../../../../../../../utils/globals/employees'

interface ModalCreateWCHolidayRequestProps extends ModalEditComponentProps{}

const ModalCreateWCHolidayRequest: React.FC<ModalCreateWCHolidayRequestProps> = ({ setOpened, onSubmit}) => {
    const { t } = useTwinTranslation()
    const employees = getEmployees()
    const { handleEmployeeId, handleHolidayBagId, holidayBags, holidayBagSelected, dateSelected, handleOnChangeDate, handleRequestHolidays, addHoliday, listHolidays, removeHoliday, loading } = useModalCreateWCHolidayRequestLogic({ setOpened, onSubmit })
    const isSelectedOnlyADay = dateSelected.start.toISOString() === dateSelected.end.toISOString()
    const holidayBagsExt: FormElement<keyof WCalHolidayUsedDayModelTypeExtended>[] = []
    if (holidayBags) {
        holidayBagsExt.push(
            {
                name: 'WCalHolidayBagId',
                component: 'CustomSelect',
                label: t('holidayBags', 'Bolsas de vacaciones'),
                items: holidayBags || {},
                onChange: handleHolidayBagId,
                value: holidayBagSelected?.id,
                required: true,
                readOnly: listHolidays.length ? true : false
            },
            {
                name: 'notifyEmployee',
                component: 'CheckboxMargin',
                label: t('notifyEmployee', 'Notificar al empleado'),
                defaultChecked: true
            }
        )
    }

    const fields: FormRender<WCalHolidayUsedDayModelTypeExtended> = [
        {
            cols: 2,
            elements: [
                {
                    name: 'EmployeeId',
                    component: 'CustomSelectWithSearchBar',
                    label: t('employees', 'Empleados'),
                    items: employees || {},
                    onChange: handleEmployeeId,
                    required: true,
                    fieldName: 'fullname_short',
                    readOnly: listHolidays.length ? true : false,
                    sortFunction: sortEmployeesByUser
                },
                ...holidayBagsExt
            ]
        }
    ]

    return (
        <ModalCreateEditMediumCustomLoading url='' onSubmit={() => { }} beforeSubmit={(values) => { handleRequestHolidays(values); return false }} translations={{
            title: t('createEmployeeHolidayRequest', 'Crear solicitud de vacaciones de Empleado'),
            button: t('create', 'Crear')
        }} haveButtonPermissions={true} className='notFlexAutoFormRender' {...{ loading, fields, setOpened}}>
            {holidayBagSelected ? 
                <Fragment>
                    <div className='border-solid border-b border-gray-D6'>
                        <TwinCalendarStartEnd start={dateSelected.start} end={dateSelected.end} minDate={holidayBagSelected.from ? new Date(holidayBagSelected.from) : undefined} maxDate={holidayBagSelected.to ? new Date(holidayBagSelected.to) : undefined} defaultActiveStartDate={dateSelected.start} onChange={handleOnChangeDate} />
                    </div> 
                    <div className='flex'>
                        {isSelectedOnlyADay ? <Checkbox label={t('holidayHalfDay', 'Medio día de vacaciones')} checked={dateSelected.isHalfDay} onChange={(value) => handleOnChangeDate(value, 'isHalfDay')} /> : null}
                        <ButtonPrimary className='ml-auto mt-10' onClick={addHoliday} type='button'>
                            <TwinTrans transKey='add'>
                                Añadir
                            </TwinTrans>
                        </ButtonPrimary>
                    </div>
                </Fragment>
                : null}
            {listHolidays.length?
                <TableHolidayRequests {...{listHolidays, removeHoliday}} />
                : null
            }
        </ModalCreateEditMediumCustomLoading>
    )
}

interface ModalCreateWCHolidayRequestLogicProps {
    setOpened: ModalOpenedSetTy
    onSubmit: OnSubmit
}

const useModalCreateWCHolidayRequestLogic = ({ setOpened, onSubmit }: ModalCreateWCHolidayRequestLogicProps ) => {
    const [employeeId, setEmployeeId] = useState<number | null>(null)
    const [holidayBags, setHolidayBags] = useState<TwinDictionary | null>(null)
    const [holidayBagSelected, setHolidayBagSelected] = useState<WCalHolidayBagModelType | null>(null)
    const { startLoading, endLoading, loading } = useIsLoading()
    const { t } = useTwinTranslation()
    const currentDate = new Date()
    
    const bagDateparsed = holidayBagSelected ? new Date(holidayBagSelected?.from || '') : currentDate
    const defaultDate = bagDateparsed > currentDate ? bagDateparsed : currentDate
    const [dateSelected, setDateSelected] = useState<WCalHolidayUsedDayDateSelected>({
        start: currentDate,
        end: currentDate,
        isHalfDay: false
    })

    const [listHolidays, setListHolidays] = useState<WCalHolidayUsedDayDateSelected[]>([])

    const messages = JSON.stringify({
        errorStartDateNotSelected: t('noStartDateSelected', 'Fecha de inicio no seleccionada'),
        'No left days': t('notEnoughHolidayDaysLeft', 'No quedan suficientes días de vacaciones'),
        'The day picked must be a working day': t('mustBeWorkingDays', 'Una de las fechas seleccionadas debe ser un día laborable'),
        'The day picked is not available': t('datesPickedNotAvailable', 'Una de las fechas seleccionadas no está disponible'),
        success: t('successfullyCompleted', 'Realizado correctamente'),
    })
    const selectedDateAlready = t('selectedDateAlready', 'Fecha ya seleccionada')
    const handleOnChangeDate = useCallback((value: Date | boolean, type: 'start' | 'end' | 'isHalfDay') => {
        setDateSelected((old) => {
            return { ...old, isHalfDay: false, [type]: value }
        })
    }, [setDateSelected])

    const addHoliday = useCallback(() => {
        setListHolidays((old)=>{
            if(old){
                let push: boolean = true
                for (const holiday of old) {
                    const myHolidayStart = new Date(holiday.start)
                    const myHolidayEnd = new Date(holiday.end)
                    if ((myHolidayStart >= dateSelected.start || myHolidayEnd >= dateSelected.start) && myHolidayStart <= dateSelected.end){
                        push = false
                        changeErrorMessage(selectedDateAlready)
                        break
                    }
                }
                if(push){
                    return [...old,  dateSelected ]
                } else{
                    return old
                }
            }
            return [dateSelected]
        })
        setDateSelected({
            start: defaultDate,
            end: defaultDate,
            isHalfDay: false
        })
    }, [setListHolidays, setDateSelected, dateSelected, defaultDate, selectedDateAlready])

    const removeHoliday = useCallback((pos: number) => {
        setListHolidays((old)=> {
            const copyOld = JSON.parse(JSON.stringify(old))
            copyOld.splice(pos, 1)
            return [...copyOld]
        })
    }, [setListHolidays])

    const handleRequestHolidays = useCallback(async (values: TwinDictionary) => {
        startLoading()
        const messagesParsed = JSON.parse(messages)
        let holidays: {start: string, end: string, isHalfDay: boolean}[] = []
        if(listHolidays.length){
            for (const holiday of listHolidays) {
                holidays.push({ start: new Date(holiday.start)?.toDateString(), end: new Date(holiday.end)?.toDateString(), isHalfDay: holiday.isHalfDay})
            }
            const result = await twinFetchPost('/api/app/workingCalendar/workingCalendarHolidayBag/createEmployeeHolidayUsedDay', {
                WCalHolidayBagId: parseInt(String(holidayBagSelected?.id)),
                EmployeeId: holidayBagSelected?.EmployeeId,
                holidays,
                notifyEmployee: values['notifyEmployee'] || false
            })
            let response = await result.json()
            if (result.status === 200) {
                setOpened(null)
                onSubmit(result, {})
                changeSuccessMessage(messagesParsed.success)
            } else if (result.status === 422) {
                changeErrorMessage(messagesParsed[response.errors[0].msg])
            }
        } else {
            changeErrorMessage(messagesParsed.errorStartDateNotSelected)
        }
        endLoading()
    }, [listHolidays, holidayBagSelected?.id, messages, holidayBagSelected?.EmployeeId, setOpened, onSubmit, endLoading, startLoading])

    const getHolidayBags = useCallback(async (EmployeeId: number) => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getAllWorkingCalendarHolidayBags', {
            where: {
                EmployeeId,
                leftDays: {
                    '$not': "0"
                }
            }
        })
        if (result) {
            setHolidayBags(dictionaryComplexFromJsonArr(result))
        }
    }, [setHolidayBags])
    
    const getHolidayBagInstance = useCallback(async (id: number) => {
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getWorkingCalendarHolidayBagInstance', {
            id
        })

        const currentDateInner = new Date()
        if (result) {
            const bagDateparsed = new Date(result?.from || '') || currentDateInner
            const defaultDate = bagDateparsed > currentDateInner ? bagDateparsed : currentDateInner
            setDateSelected({
                start: defaultDate,
                end: defaultDate,
                isHalfDay: false
            })
            setHolidayBagSelected(result)
        }
    }, [setHolidayBagSelected])

    const handleEmployeeId = useCallback((id: number) => {
        setEmployeeId(id)
        getHolidayBags(id)
        setHolidayBagSelected(null)
    }, [setEmployeeId, getHolidayBags])

    const handleHolidayBagId = useCallback((id: number) => {
        getHolidayBagInstance(id)
    }, [getHolidayBagInstance])

    return { handleEmployeeId, employeeId, holidayBags, handleHolidayBagId, holidayBagSelected, dateSelected, handleOnChangeDate, handleRequestHolidays, addHoliday, listHolidays, removeHoliday, loading }
}



export default ModalCreateWCHolidayRequest