import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { ButtonDisabledOrLoader } from '../../../../../baseComponents/Button'
import { SwitchButton, TabItems } from '../../../../../baseComponents/Button/SwitchButton'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import Checkbox from '../../../../../forms/Checkbox'
import TwinForm from '../../../../../forms/TwinForm'
import { OnSubmit, BeforeSubmitHandler } from '../../../../../forms/TwinForm/types'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import LoadingSpinner from '../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import TwinToolTip from '../../../../../baseComponents/TwinToolTip'

interface LoginHeaderProps {
    active: number,
    onChangeTabs: (position: number) => void
}

export const LoginHeader: React.FC<LoginHeaderProps> = ({ active, onChangeTabs }) => {
    const items: TabItems[] = [
        {
            element: <TwinTrans transKey={'erp'}>ERP</TwinTrans>
        },
        {
            element: <TwinTrans transKey={'admin'}>Admin</TwinTrans>
        }
    ]
    return (
        <Fragment>
            <HeaderAuth>
                <SwitchButton active={active} onClick={onChangeTabs} items={items} />
            </HeaderAuth>
            <TitleAuth>
                <TwinTrans transKey='loginPageSubtitleNew'>
                    Bienvenido a la mejor forma de gestionar tu empresa
                </TwinTrans>
            </TitleAuth>
        </Fragment>
    )
}

interface LoginSubHeaderBaseProps {
    title: string
    subtitle: string
}

const LoginSubHeaderBase: React.FC<LoginSubHeaderBaseProps> = ({ title, subtitle }) => {
    return (
        <div className='mt-40 text-center'>
            <h1 className='mb-22'>
                {title}
            </h1>
            <span className='regular14'>
                {subtitle}
            </span>
        </div>
    )
}


interface LoginSubHeaderProps { }

export const LoginSubHeader: React.FC<LoginSubHeaderProps> = () => {
    const { t } = useTwinTranslation()
    return (<LoginSubHeaderBase title={t('login', 'Login')} subtitle={t('enterDataToLogin', 'Introduce tus datos para entrar en el panel')} />)
}

interface LoginAuthNumberSubHeaderProps {
    email: string
}

export const LoginAuthNumberSubHeader: React.FC<LoginAuthNumberSubHeaderProps> = ({ email }) => {
    const { t } = useTwinTranslation()
    return (<LoginSubHeaderBase title={t('enterCode', 'Ingresar código')} subtitle={t('sentTo', 'Enviado a') + ' ' + email} />)
}

interface FooterOfAuthNumberFormProps {
    loading: boolean
    timer: number | null
    resendCodeMail: () => void
    verifiedDevice: () => void
}

export const FooterOfAuthNumberForm: React.FC<FooterOfAuthNumberFormProps> = ({ resendCodeMail, verifiedDevice, timer, loading }) => {
    return (
        <div className='mt-22 auth_number_footer_form text-center'>
            {loading ?
                <LoadingSpinner />
                :
                <Fragment>
                    {timer ?
                        <div className='regular14'>
                            <span className='mr-5'><TwinTrans transKey='ableToResendMailIn'>Podrás reenviar el email en:</TwinTrans></span>
                            {timer}
                        </div>
                        :
                        <div className='regular14  text-green-21 cursor-pointer' onClick={resendCodeMail}>
                            <TwinTrans transKey='resendCodeMail'>Volver a enviar email</TwinTrans>
                        </div>
                    }
                    <div className='text-green-21 mt-10 cursor-pointer' onClick={verifiedDevice} data-tooltip-id='verified-device'>
                        <TwinToolTip id='verified-device' className='max-w-300'>
                            ¿Acabas de verificar este dispositivo en otra ventana? Pulsa sobre este botón.
                        </TwinToolTip>
                        <TwinTrans transKey='alreadyVerifiedDevice'>¿Ya has verificado el dispositivo?</TwinTrans>
                    </div>
                </Fragment>
            }
        </div>
    )
}

interface FooterOfLoginFormProps { }

export const FooterOfLoginForm: React.FC<FooterOfLoginFormProps> = () => {
    const { t } = useTwinTranslation()
    return (
        <div className='flex justify-between items-center mt-22 forgot_password_login_footer_form'>
            <Checkbox name='remember' label={t('rememberMe', 'Recordarme')} className='regular14 color_checkbox_grey' />
            <MyLink link={'/forgot-password'} text={t('forgotPassword', '¿Has olvidado tu contraseña?')} />
        </div>
    )
}

interface FooterLinkProps {
    textLink: string
    link: string
    children?: React.ReactNode
}

export const FooterLink: React.FC<FooterLinkProps> = ({ textLink, link, children }) => {
    return (
        <div className='auth_footer'>
            <MyLink text={textLink} link={link} className='text-16' />
            {children}
        </div>
    )
}

interface TitleAuthProps {
    children?: React.ReactNode
}

export const TitleAuth: React.FC<TitleAuthProps> = ({ children }) => {
    return (
        <div className='auth_title'>
            {children}
        </div>
    )
}

interface HeaderAuthProps {
    children?: React.ReactNode
}

export const HeaderAuth: React.FC<HeaderAuthProps> = ({ children }) => {
    return (
        <div className='flex justify-between items-center'>
            <img src='/teinor_logo.png' alt='teinor_logo' className='auth_logo' />
            {children}
        </div>
    )
}

interface MyLinkProps {
    text: string
    link: string
    className?: string
}

export const MyLink: React.FC<MyLinkProps> = ({ link, text, className }) => {
    return (
        <Link to={link} className={'text-green-21 ' + (className || '')}>{text}</Link>
    )
}

interface FormAuthenticationProps {
    action: string
    buttonText: string
    onSubmit?: OnSubmit
    beforeSubmitHandler?: BeforeSubmitHandler
    children?: React.ReactNode
    loading: boolean
}
export const FormAuthentication: React.FC<FormAuthenticationProps> = ({ buttonText, children, loading, ...rest }) => {
    return (
        <TwinForm method='POST'  {...rest} triggerErrors={false}>
            {children}
            <ButtonDisabledOrLoader textButton={buttonText} loading={loading}  className='auth_button' />
        </TwinForm>
    )
}