import { TaskModelType } from '@teinor/erp/types/company/task'
import { useCallback, useEffect, useRef, useState } from 'react'
import { EventWrapperProps, HeaderProps, SlotInfo } from 'react-big-calendar'
import { ConnectedProps, connect } from 'react-redux'
import { BigTitleAndRightLayout } from '../../../../../../baseComponents/Layout/TitleAndRightLayout'
import { TwinBigCalendarEvent, TwinBigCalendarEvents } from '../../../../../../baseComponents/TwinBigCalendar/types'
import TwinToolTip from '../../../../../../baseComponents/TwinToolTip'
import ModalCreateTaskWithHelper from '../../../../../../specificComponents/Task/ModalCreateTaskWithHelper'
import TaskCalendar from '../../../../../../specificComponents/Task/TaskCalendar'
import { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { getFirstAndLastDayOfThisWeek } from '../../../../../../utils/globals/date'
import { listenSocket, unlistenSocket } from '../../../../../../utils/globals/socket'
import { Modify, StartEndDateTy } from '../../../../../../utils/globals/types'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useEditDeleteModal from '../../../../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { DictionaryEmployee, EmployeesPayload } from '../../../../../../utils/reducers/company/employees'
import { TaskTypePayload } from '../../../../../../utils/reducers/company/taskTypes'
import { DictionaryGroup, GroupPayload } from '../../../../../../utils/reducers/groups/groups'
import { addBottomNavAppElement } from '../../../../../../utils/reducers/reduxDispatch'
import { getStartOfWeek } from '../../../BottomIcons/UserTimeControl/PunchInUser/functions'
import TaskCalendarAdminHeader from './TaskCalendarAdminHeader'
import { taskAdminCalendarGetDefaultSelectedData } from './functions'
import { DictHolidayAbsenceTy, HolidayFreeDayAbsenceTy, TaskAdminCalendarSelectedData } from './types'
import { hexToRgbA } from '../../../../../../utils/globals/colors'
import { haveModuleBuyed } from '../../../../../../utils/globals'
import { getEmployees, getModulesBuyed } from '../../../../../../utils/reducers/getters'
import { twinCalendarDayAndWeekDayFormat } from '../../../../../../baseComponents/TwinCalendar/functions'
import { ModalOpenedTy } from '../../../../../../baseComponents/Modal/types'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import useOnClickOutside from '../../../../../../utils/hooks/useOnClickOut'
import './task_calendar_admin.sass'

interface TaskCalendarAdminProps extends ReduxEmployeeParams {}

const TaskCalendarAdmin: React.FC<TaskCalendarAdminProps> = ({ employees, groups }) => {
    const { t } = useTwinTranslation()
    const { events, onRangeChange, setSelectedData, selectedData, onSelectSlot, openModal, setOpenModal, getTaskCalendarData, onSelectEvent, startWeekDate, holidayFreeDaysAndAbsences } = useTaskCalendarAdminLogic({ groups, employees })
    return (
        <BigTitleAndRightLayout title={t('tasks', 'Tasks')} RightHeader={
            <TaskCalendarAdminHeader
                setSelectedData={setSelectedData}
                selectedData={selectedData}
            />
        }>
            <TaskCalendar className='task_calendar_admin' components={{
                week: { header: (props) => <DayWeekWithHolidays holidayFreeDaysAndAbsences={holidayFreeDaysAndAbsences} {...props} /> },
                eventWrapper: TaskCalendarAdminEvent
            }} min={startWeekDate} events={events || undefined} onRangeChange={onRangeChange} onSelectSlot={onSelectSlot} onSelectEvent={onSelectEvent} selectable />
            {openModal?.type === 'edit' ? <ModalCreateTaskWithHelper setOpened={() => setOpenModal(null)} onSubmit={getTaskCalendarData} allRowData={openModal?.allRowData}  /> : null}
        </BigTitleAndRightLayout>
    )
}

interface TaskCalendarAdminLogic {
    groups: DictionaryGroup | null
    employees: DictionaryEmployee | null
}

const useTaskCalendarAdminLogic = ({ groups, employees }: TaskCalendarAdminLogic) => {
    const [events, setEvents] = useState<TwinBigCalendarEvents | null>(null)
    const [dates, setDates] = useState<StartEndDateTy>(getFirstAndLastDayOfThisWeek())
    const [minHours, setMinHours] = useState(8)
    const [selectedData, setSelectedData] = useState<TaskAdminCalendarSelectedData>(
        taskAdminCalendarGetDefaultSelectedData({ name: 'task_calendar_admin_filter', groups, employees })
    )
    const [holidayFreeDaysAndAbsences, setHolidayFreeDaysAndAbsences] = useState<DictHolidayAbsenceTy | null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()
    const { t } = useTwinTranslation()
    const taskWithNoName = t('taskWithNoName', 'Tarea sin nombre')

    const onSelectSlot = useCallback((slotInfo: SlotInfo) => {
        setOpenModal({
            type: 'edit',
            allRowData: slotInfo
        })
    }, [setOpenModal])

    const parseDataToEvents = useCallback((data: TaskModelType[]) => {
        const eventsParsed: TwinBigCalendarEvents = []
        let minHour = 8
        for (const task of data) {
            const startDate = task.start_date ? new Date(task.start_date) : undefined
            const endDate = task.end_date ? new Date(task.end_date) : undefined
            const taskTitle = task?.name || taskWithNoName
            let taskTitleComplete = '#' + task.id + ' - ' + (task?.name || taskWithNoName)
            if (task?.Employee?.name) {
                taskTitleComplete += ' - ' + task?.Employee?.fullname_short
            }
            if (task?.Group?.name) {
                taskTitleComplete += ' - ' + task.Group?.name
            }

            const mainColor = (selectedData?.colors?.employees?.[task.EmployeeId] || selectedData?.colors?.groups?.[task.GroupId] || '')
            eventsParsed.push(
                {
                    id: task.id,
                    title: taskTitle,
                    subtitle: taskTitleComplete,
                    start: startDate || endDate,
                    end: endDate || startDate,
                    color: !task.finished ? mainColor : hexToRgbA(mainColor, '0.2'),
                    borderColor: task.finished ? mainColor : '#fff',
                    textColor: !task.finished ? '#fff' : '#515966'
                }
            )
            if (startDate && startDate.getHours() < minHour) {
                minHour = startDate.getHours()
            }
        }
        setMinHours(minHour)
        setEvents(eventsParsed)
    }, [setEvents, selectedData, taskWithNoName])

    const onSelectEvent = useCallback(async (event: any) => {
        addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + event.id, extraData: { id: event.id, opened: true } })
    }, [])

    const onRangeChange = useCallback((range: any) => {
        if (range.length === 12) {
            const newDate = new Date(range[11])
            newDate.setDate(newDate.getDate() + 30)
            setDates({
                start: range[0],
                end: newDate
            })
        } else if (range.length === 7) {
            setDates({
                start: range[0],
                end: range[6]
            })
        } else {
            setDates({
                start: range.start,
                end: range.end
            })
        }
    }, [setDates])

    const getHolidaysData = useCallback(async () => {
        if (haveModuleBuyed(['working_calendar', 'working_calendar_medium', 'working_calendar_full'], getModulesBuyed())) {
            const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getAllFreeDaysHolidaysAndAbsences', {
                dateStart: dates.start,
                dateEnd: dates.end,
                employees: selectedData.filters.employees,
                groups: selectedData.filters.groups,
            })
            if (result) {
                setHolidayFreeDaysAndAbsences(result)
            }
        }
    }, [dates.start, dates.end, selectedData.filters.employees, selectedData.filters.groups])

    const getTaskCalendarData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/task/getAllTaskForCalendar', {
            start_date: dates.start,
            end_date: dates.end,
            employees: selectedData.filters.employees,
            groups: selectedData.filters.groups,
            orderToParse: [['start_date', 'ASC']]
        })
        if (result) {
            parseDataToEvents(result)
        }
    }, [parseDataToEvents, dates, selectedData])

    useEffect(() => {
        getTaskCalendarData()
        getHolidaysData()
    }, [getTaskCalendarData, getHolidaysData])

    useEffect(() => {
        listenSocket('getAllTasks', getTaskCalendarData, true)
        return () => {
            unlistenSocket('getAllTasks')
        }
    }, [getTaskCalendarData])

    const startWeekDate = getStartOfWeek(dates.start?.toDateString())
    startWeekDate.setHours(minHours)

    return { events, onRangeChange, setSelectedData, selectedData, onSelectSlot, openModal, setOpenModal, getTaskCalendarData, onSelectEvent, dates, startWeekDate, holidayFreeDaysAndAbsences }
}
export type EventWrapperExtProps = Modify<EventWrapperProps, {
    event: TwinBigCalendarEvent
}>
interface TaskCalendarAdminEventProps extends EventWrapperExtProps { }

const TaskCalendarAdminEvent: React.FC<TaskCalendarAdminEventProps> = (props) => {
    const { event, label, style } = props
    console.log(props)
    return (
        <div className='rbc-event' style={{'backgroundColor': event.color, 'borderColor': event.borderColor, top: style?.top + '%', height: style?.height + '%', width: style?.width + '%', left: style?.xOffset + '%' }} onClick={props.onClick} onDoubleClick={props.onDoubleClick}>
            <TwinToolTip place='top' anchorSelect={'#task' + event?.id} className='max-w-300' positionStrategy='fixed' >
                <div>{event.subtitle}</div>
            </TwinToolTip>
            <div id={'task' + event?.id} className='flex flex-col flex-auto w-full' style={{ 'color': event.textColor }}>
                <div className='rbc-event-content twin_elipsis'>{event.title}</div>
                <div className='rbc-event-label'>{label}</div>
            </div>
        </div>
    )
}

interface DayWeekWithHolidaysProps extends HeaderProps {
    holidayFreeDaysAndAbsences: DictHolidayAbsenceTy | null
}

const DayWeekWithHolidays: React.FC<DayWeekWithHolidaysProps> = ({ date, holidayFreeDaysAndAbsences }) => {
    const { day, weekDay } = twinCalendarDayAndWeekDayFormat('es-ES', date)
    const { opened, handleOpen, ref } = useDayWeekWithHolidaysLogic()
    const renderThis: JSX.Element[] = []
    const holidayFreeDayAbsence = holidayFreeDaysAndAbsences?.[date.toDateString()]
    if (holidayFreeDayAbsence) {
        renderThis.push(<ModalHolidayFreeDayAbsence key={'holiday' + date.toDateString} {...{ holidayFreeDayAbsence }} />)
    }
    return (
        <div className=' flex flex-col items-center'>
            <div ref={ref} className='flex items-center cursor-pointer ' onClick={handleOpen}>
                <div>{weekDay}</div>
                {holidayFreeDayAbsence ?
                    <div className='ml-10 min-h-8 min-w-8 rounded-full bg-green-43' />
                    : null
                }
            </div>
            <div className='week_num_day'>{day}</div>
            {opened ?
                renderThis
            :
                null
            }
        </div>
    )
}
const useDayWeekWithHolidaysLogic = () => {
    const [opened, setOpened] = useState<ModalOpenedTy>(null)
    const handleOpen = useCallback(() => {
        setOpened((old) => {
            if (!old) {
                return true
            }
            return null
        })
    }, [setOpened])
    const ref = useRef<any>(null)

    useOnClickOutside(ref, () => setOpened(null))
    return { opened, handleOpen, ref }
}

interface ModalHolidayFreeDayAbsenceProps {
    holidayFreeDayAbsence: HolidayFreeDayAbsenceTy
}

const ModalHolidayFreeDayAbsence: React.FC<ModalHolidayFreeDayAbsenceProps> = ({ holidayFreeDayAbsence }) => {
    const renderThis: JSX.Element[] = []
    const employees = getEmployees()
    if (holidayFreeDayAbsence.absences.length) {
        renderThis.push(<div key='abscences' className='medium16 my-5'><TwinTrans transKey='absence'>Ausencia</TwinTrans>:</div>)
        for (const absence of holidayFreeDayAbsence.absences) {
            const dateStartStr = new Date(absence.dateStart).toLocaleDateString()
            const dateEndStr = new Date(absence.dateEnd).toLocaleDateString()
            renderThis.push(<ModalHolidayRow key={'absence' + absence.id} title={employees?.[absence.EmployeeId]?.fullname_short || ''} subtitle={dateStartStr + ' - ' + dateEndStr} color='#FF9F63' />)
        }
    }
    if (holidayFreeDayAbsence.holidays.length) {
        renderThis.push(<div key='holidays' className='medium16 my-5'><TwinTrans transKey='holiday'>Vacaciones</TwinTrans>:</div>)
        for (const holiday of holidayFreeDayAbsence.holidays) {
            const dateStartStr = new Date(holiday.from)?.toLocaleDateString()
            const dateEndStr = new Date(holiday.to)?.toLocaleDateString()
            renderThis.push(<ModalHolidayRow key={'holiday' + holiday.id} title={employees?.[holiday.WCalHolidayBag.EmployeeId]?.fullname_short || ''} subtitle={dateStartStr + ' - ' + dateEndStr} />)
        }
    }
    if (holidayFreeDayAbsence.freeDays.length) {
        renderThis.push(<div key='freedays' className='medium16 my-5'><TwinTrans transKey='freeDays'>Festivos</TwinTrans>:</div>)
        for (const freeDay of holidayFreeDayAbsence.freeDays) {
            renderThis.push(<ModalHolidayRow key={'freeDays' + freeDay.id} title={freeDay.name || ''} subtitle={freeDay.nameParent} color={freeDay.color} />)
        }
    }

    return (
        <div className='absolute modal_holidays_task_calendar_admin bg-white px-10 py-10 capitalize text-left'>{renderThis}</div>
    )
}

interface ModalHolidayAbsenceFreeDayRowProps {
    title: string
    subtitle: string
    color?: string
}

const ModalHolidayRow: React.FC<ModalHolidayAbsenceFreeDayRowProps> = ({ title, subtitle, color }) => {
    return (
        <div className='flex mb-10'>
            <div className='rounded-full min-w-20 h-20 mt-2' style={{ background: color || '#43BAA5' }} />
            <div className='ml-12'>
                <div className='text-gray-51 medium14 leading-14'>{title}</div>
                <div className='light12 text-gray-51 mt-6 whitespace-pre-wrap'>{subtitle}</div>
            </div>
        </div>
    )
}


const employeeParamsDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setTaskTypes: (payload: TaskTypePayload) => ({ type: 'CHANGE_TASKTYPE', payload }),
}

export type ReduxEmployeeParams = ConnectedProps<typeof employeeParamsConnect>
const mapEmployeeParamsConnector = (state: AllReduxPayloads) => ({ employees: state.employees, groups: state.groups, taskTypes: state.taskTypes })
const employeeParamsConnect = connect(mapEmployeeParamsConnector, employeeParamsDispatch)

const employeeParamsConnectLoading = withLoading(TaskCalendarAdmin, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/taskType/getAllTaskTypes', propName: 'taskTypes', setFunctionName: 'setTaskTypes' }])

const TaskCalendarConnect = employeeParamsConnect(employeeParamsConnectLoading)

export default TaskCalendarConnect