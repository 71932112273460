import TwinTrans from '../../../../baseComponents/TwinTrans'
import { displayFormatedHourFromMins } from '../../../../utils/globals/date'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'

interface CustomRenderSchedulesProps {
    schedule?: string
}

const CustomRenderSchedules: React.FC<CustomRenderSchedulesProps> = ({ schedule }) => {
    const { t } = useTwinTranslation()

    const parsedSchedule = JSON.parse(schedule || '[]')
    const scheduleArr: string[] = []
    for (const mySchedule of parsedSchedule) {
        const parsedStartHours = displayFormatedHourFromMins(mySchedule[0])
        const parsedEndHours = displayFormatedHourFromMins(mySchedule[1])
        scheduleArr.push(parsedStartHours + ' - ' + parsedEndHours)
    }
    if (!scheduleArr.length) {
        scheduleArr.push(t('allDay', 'Todo el día'))
    }
    return (
        <div className='flex flex-col '>
            <span className='light12 text-gray-51 mb-4'>
                <TwinTrans transKey='schedule'>Horario</TwinTrans>
            </span>
            <span className='regular14 overflow-hidden'>{scheduleArr.join(', ')}</span>
        </div>
    )
}
interface CustomRenderSchedulesWithoutHeaderProps {
    schedule?: string
}

export const CustomRenderSchedulesWithoutHeader: React.FC<CustomRenderSchedulesWithoutHeaderProps> = ({ schedule }) => {
    const { t } = useTwinTranslation()
    if (!schedule) {
        return null
    }

    const parsedSchedule = JSON.parse(schedule || '[]')
    const scheduleArr: string[] = []
    for (const mySchedule of parsedSchedule) {
        const parsedStartHours = displayFormatedHourFromMins(mySchedule[0])
        const parsedEndHours = displayFormatedHourFromMins(mySchedule[1])
        scheduleArr.push(parsedStartHours + ' - ' + parsedEndHours)
    }
    if (!scheduleArr.length) {
        scheduleArr.push(t('allDay', 'Todo el día'))
    }
    return (
        <div className='flex flex-col '>
            <span className='regular14 overflow-hidden'>{scheduleArr.join(', ')}</span>
        </div>
    )
}

interface CustomRenderSchedulesRowProps {
    schedule?: number[][]
}

export const CustomRenderSchedulesRow: React.FC<CustomRenderSchedulesRowProps> = ({ schedule }) => {
    const { t } = useTwinTranslation()
    const scheduleArr: string[] = []
    for (const mySchedule of schedule || []) {
        const parsedStartHours = displayFormatedHourFromMins(mySchedule[0])
        const parsedEndHours = displayFormatedHourFromMins(mySchedule[1])
        scheduleArr.push(parsedStartHours + ' - ' + parsedEndHours)
    }
    if (!scheduleArr.length) {
        scheduleArr.push(t('allDay', 'Todo el día'))
    }
    return (
        <span className='regular14 overflow-hidden'>{scheduleArr.join(', ')}</span>
    )
}
export default CustomRenderSchedules