import { faChevronsLeft, faXmark } from '@fortawesome/pro-light-svg-icons'
import { TaskModelType } from '@teinor/erp/types/company/task'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { WhiteBox } from '../../../baseComponents/AppLayout/WhiteBox'
import LoadingSpinner from '../../../baseComponents/LoaderDecider/LoadingSpinner'
import { permissionCheck } from '../../../baseComponents/PermissionChecker/function'
import TwinIcon, { TwinIconToolTip } from '../../../baseComponents/TwinIcon'
import FormRenderer from '../../../forms/FormRenderer'
import { FormRender } from '../../../forms/FormRenderer/types'
import { InputHourMinSecUnlimitedStateFull } from '../../../forms/Input/InputHourMinSec/InputHourMinSecUnlimited'
import { TextAreaDebounce } from '../../../forms/Input/TextArea'
import twinFetchPost, { twinFetchPostJSON } from '../../../utils/globals/data'
import { TwinDictionary, addDictionaryExtraLine, dictionaryComplexFromJsonArr } from '../../../utils/globals/dictionary'
import { getBasePath, getPathWithoutLastSlash } from '../../../utils/globals/link'
import { listenSocket, unlistenSocket } from '../../../utils/globals/socket'
import withLoading from '../../../utils/hoc/withLoading'
import useEditDeleteModal, { SetEditDeleteModalTy, UseEditDeleteModalProps } from '../../../utils/hooks/useEditDeleteModal'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../utils/reducers'
import { DictionaryEmployee, EmployeesPayload } from '../../../utils/reducers/company/employees'
import { TaskCFieldPayload } from '../../../utils/reducers/company/taskCFields'
import { getUserInfo, getUserPermissions } from '../../../utils/reducers/getters'
import { DictionaryGroup, GroupPayload } from '../../../utils/reducers/groups/groups'
import { addBottomNavAppElement, changeErrorMessage, changeSuccessMessage, deleteBottomNavAppElement } from '../../../utils/reducers/reduxDispatch'
import { socketSingleTaskParseFncs } from './functions'
import HeaderTask from './HeaderTask'
import ModalTaskInvertedTime from './ModalTaskInvertedTime'
import { CustomSelectTaskContactPersons, CustomSelectTaskShop, TaskName } from './Subcomponents'
import TaskCFields from './TaskCFields'
import TaskComments from './TaskComments'
import TaskProjects from './TaskProjects'
import TaskSubtasks from './TaskSubtasks'
import { CustomerDictsTy, SetTaskDataExtended, TaskExtendedTy, UpdateTaskTy } from './types'
import TaskLogs from './TaskLogs'
import { sortGroupsByUser } from '../../../utils/globals/groups'
import { sortEmployeesByUser } from '../../../utils/globals/employees'
import { TaskCommentModelTypeExt } from './TaskComments/types'
import { DictionaryTypeTask, TaskTypePayload } from '../../../utils/reducers/company/taskTypes'
import './singleTask.sass'



type WhiteBoxSingleTaskProps = ReduxWhiteBoxSingleTask & WhiteBoxSingleTaskLogicProps & {
    basePath: string
}

const WhiteBoxSingleTask: React.FC<WhiteBoxSingleTaskProps> = ({basePath, limitPath, ...rest}: WhiteBoxSingleTaskProps) => {
    const {t} = useTwinTranslation()
    const params = useParams<{ id: string }>()
    const id = params.id

    const { onInvalidPermissions, onDeletedTask } = useWhiteBoxSingleTaskLogic({limitPath})
    const { refSubTask, data, updateTask, setData, customerDicts, changeDescription, changeName, openModalInvertedTime, setOpenModalInvertedTime } = useSingleTaskLogic({ id: id || '', onInvalidPermissions, onDeletedTask })
    if(!data){
        return <LoadingSpinner />
    }
    return (
        <WhiteBox className='single_task flex flex-col notFlexAutoFormRender'>
            <HeaderTask {...{ refSubTask, updateTask, data, setData, onDeletedTask, setOpenModalInvertedTime }}>
                <Link to={basePath + '/main'}>
                    <TwinIconToolTip id='close' message={t('close', 'Cerrar')} icon={faXmark} />
                </Link>
            </HeaderTask>
            <SingleTaskInner {...{ updateTask, data, setData, changeDescription, changeName, refSubTask, customerDicts, openModalInvertedTime, setOpenModalInvertedTime, ...rest }} />
        </WhiteBox>
    )
}

interface WhiteBoxSingleTaskLogicProps {
    limitPath: string
}

const useWhiteBoxSingleTaskLogic = ({limitPath}: WhiteBoxSingleTaskLogicProps) => {
    const { t } = useTwinTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const taskNotAvailable = t('taskNotAvailable', 'Esta tarea no está disponible')
    const taskDeleted = t('taskDeletedSuccessfully', 'Tarea eliminada con éxito')
    const taskHasBeenDeleted = t('taskHasBeenDeleted', 'Esta tarea ha sido eliminada')

    const onInvalidPermissions = useCallback(() => {
        changeErrorMessage(taskNotAvailable)
        navigate(getBasePath(location.pathname, limitPath) + '/main')
    }, [location.pathname, navigate, limitPath, taskNotAvailable])

    const onDeletedTask = useCallback((otherPerson?: boolean) => {
        if(otherPerson){
            changeErrorMessage(taskHasBeenDeleted)
        } else {
            changeSuccessMessage(taskDeleted)
        }
        navigate(getBasePath(location.pathname, limitPath) + '/main')
    }, [taskDeleted, taskHasBeenDeleted, location.pathname, navigate, limitPath])

    return { onInvalidPermissions, onDeletedTask }
}

interface SingleTaskLogicProps {
    id: string
    onInvalidPermissions: ()=>void
    onDeletedTask: (otherPerson?: boolean) => void
}
export const useSingleTaskLogic = ({ id, onInvalidPermissions, onDeletedTask }: SingleTaskLogicProps) => {
    const [data, setData] = useState<TaskExtendedTy | null>(null)
    const [customerDicts, setCustomerDicts] = useState<CustomerDictsTy | null>(null)
    const refSubTask = useRef<any>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()
    const { t } = useTwinTranslation()
    const messageEmployeeNotWorking = t('employeeNotWorkingThatDay', 'El empleado seleccionado no trabaja ese día')
    const getData = useCallback(async() => {
        await setData(null)
        const response = await twinFetchPost('/api/app/task/getTaskInstance', { id, deleted: false })
        const responseJSON = await response.json()
        if (response.status === 200) {
            if (responseJSON) {
                let dictTaskCFieldValues: TwinDictionary = {}
                if (responseJSON.TaskCFieldValues.length) {
                    for (const taskCFieldValue of responseJSON.TaskCFieldValues) {
                        dictTaskCFieldValues[taskCFieldValue.TaskCFieldId] = taskCFieldValue.id
                    }
                }
                setData({ ...responseJSON, dictTaskCFieldValues })
            } 
        } else {
            if (responseJSON.errors[0].msg === 'Invalid permissions') {
                onInvalidPermissions()
            }
        }
    }, [setData, id, onInvalidPermissions])

    const updateTask = useCallback(async (field: keyof TaskModelType, value: any) => {
        let myUpdate = { [field]: value }
        const isTextAreaField = field === 'name' || field === 'description'
        if (field === 'start_date') {
            myUpdate = {
                start_date: value.valueStart || null,
                end_date: value.valueEnd || null
           }
        }
        if (isTextAreaField) {
            setData((old) => {
                if (!old) {
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                copyOld[field] = value
                return { ...copyOld }
            })
        }
        if(field === 'TaskId'){
            myUpdate.addingToParent = true
        }
        const result = await twinFetchPostJSON('/api/app/task/updateTask', { id, TaskId: data?.TaskId, ...myUpdate }, {myExtraHeaders: {'type-update': 'singleTask'}})
        if (result && !isTextAreaField){
            setData((old)=>{
                if(!old){
                    return null
                }
                const copyOld = JSON.parse(JSON.stringify(old))
                if (field === 'start_date') {
                    copyOld.start_date = value.valueStart || null
                    copyOld.end_date = value.valueEnd || null
                }else if (field === 'remember_date' && typeof value === 'string' && value !== '') {
                    copyOld[field] = new Date(value)
                }else if(field === 'TaskId' && result.ParentTask?.name) {
                    copyOld[field] = value
                    copyOld.ParentTask = result.ParentTask
                }else {
                    copyOld[field] = value
                }
                if (copyOld?.TaskLogs?.[0]?.id !== result?.TaskLogs?.[0]?.id) {
                    copyOld.TaskLogs.unshift(result.TaskLogs[0])
                }
                return {...copyOld}
            })
        }
        if (result.error === 'Employee is not working that day') {
            changeErrorMessage(messageEmployeeNotWorking)
        }
    }, [id, data?.TaskId, messageEmployeeNotWorking])

    const changeDescription = useCallback((value: string) => {
        updateTask('description', value)
    }, [updateTask])

    const changeName = useCallback((value: string) => {
        updateTask('name', value)
    }, [updateTask])

    const updateTaskDataBySocket = useCallback(async(dataStr?: string) => {
        if(dataStr){
            const parsedData = JSON.parse(dataStr)
            const user = getUserInfo()
            if (parsedData['WindowId'] !== user?.WindowId || parsedData['TypeUpdate'] !== 'singleTask'){
                const typeParse = parsedData['updateProp']
                const myUpdatingData = parsedData[typeParse]
                if (typeParse === 'updateTask' && (myUpdatingData?.GroupId !== undefined || myUpdatingData?.EmployeeId !== undefined || myUpdatingData?.WorkingCenterId !== undefined)) {
                    const response = await twinFetchPost('/api/app/task/getTaskInstance', {id} )
                    const result = await response.json()
                    if (response.status === 200) {
                        if(myUpdatingData.GroupId){
                            myUpdatingData['Group'] = result.Group
                        }
                        if(myUpdatingData.EmployeeId){
                            myUpdatingData['Employee'] = result.Employee
                        }
                        if(myUpdatingData.WorkingCenterId){
                            myUpdatingData['WorkingCenter'] = result.WorkingCenter
                        }
                    } else {
                        if (result.errors[0].msg === 'Invalid permissions') {
                            onInvalidPermissions()
                        }
                    }
                } else if (typeParse === 'updateSubTask'){
                    const response = await twinFetchPostJSON('/api/app/task/getSubTaskInstance', { id: myUpdatingData.id }) 
                    myUpdatingData['SubTaskToUpdate'] = response 
                }
                if (typeParse === 'updateTask' && myUpdatingData.deleted){
                    onDeletedTask(true)
                }
                setData((old) => {
                    if(!old){
                        return null
                    }
                    const copyOld = JSON.parse(JSON.stringify(old))
                    socketSingleTaskParseFncs?.[typeParse]?.(copyOld, myUpdatingData, parsedData) 
                    return { ...copyOld }
                })
            }
        }
    }, [setData, id, onInvalidPermissions, onDeletedTask])

    const getCustomerDicts = useCallback(async (CustomerId: number) => {
        let myShops = null
        let myContactPersons = null
        const customerPermissionContact = getUserPermissions('customer.profile.contactPerson')
        if (permissionCheck(customerPermissionContact, 'read')) {
            myContactPersons = await twinFetchPostJSON('/api/app/customer/customerContactPerson/getAllCustomerContactPersons', { where: { CustomerId, '$or': { active: true } } })
        }
        const customerPermissionShop = getUserPermissions('customer.profile.shops')
        if (permissionCheck(customerPermissionShop, 'read')) {
            myShops = await twinFetchPostJSON('/api/app/customer/customerShop/getAllCustomerShops', { where: {CustomerId}})
        }
        setCustomerDicts({ shops: addDictionaryExtraLine(dictionaryComplexFromJsonArr(myShops || [])), contacts: addDictionaryExtraLine(dictionaryComplexFromJsonArr(myContactPersons  || []))})
    }, [setCustomerDicts])

    useEffect(() => {
        if (data?.CustomerProjectId && data?.CustomerProject?.CustomerId) {
            getCustomerDicts(data?.CustomerProject?.CustomerId)
        }
    }, [getCustomerDicts, data?.CustomerProjectId, data?.CustomerProject?.CustomerId])

    useEffect(() => {
        getData()
    }, [getData])
    
    useEffect(() => {
        listenSocket('task_' + id, (changedParams) => {updateTaskDataBySocket(changedParams)})
        return () => {
            unlistenSocket('task_' + id)
        }
    }, [id, updateTaskDataBySocket])
    return { refSubTask, data, updateTask, setData, customerDicts, changeDescription, changeName, openModalInvertedTime: openModal, setOpenModalInvertedTime: setOpenModal }
}

export type SingleTaskInnerProps = SingleTaskInnerLogicProps & {
    customerDicts: CustomerDictsTy | null
    taskTypes: DictionaryTypeTask | null
    updateTask: UpdateTaskTy
    changeName: (value: string) => void
    changeDescription: (value: string) => void
    refSubTask: React.MutableRefObject<any>
    taskCFields: TaskCFieldPayload
    setData: SetTaskDataExtended
    setOpenModalInvertedTime: SetEditDeleteModalTy 
    openModalInvertedTime: UseEditDeleteModalProps
    isFloating?: boolean
    setSelectedSubtaskId?: React.Dispatch<React.SetStateAction<number | null>>
}

export const SingleTaskInner: React.FC<SingleTaskInnerProps> = ({ data, setData, customerDicts, updateTask, changeName, changeDescription, refSubTask, employees, groups, taskCFields, taskTypes, openModalInvertedTime, setOpenModalInvertedTime, isFloating, setSelectedSubtaskId }) => {
    const {t} = useTwinTranslation()
    const { memorizedEmployees, memorizedGroups, memorizedWorkingCenter } = useSingleTaskInnerLogic({ groups, employees, data })
    const fields: FormRender<TaskModelType> = [
        {
            cols: 3,
            elements: [
                {
                    name: 'EmployeeId',
                    label: t('user', 'Usuario'),
                    component: 'CustomSelectWithSearchBar',
                    items: addDictionaryExtraLine(memorizedEmployees || {}),
                    value: data?.EmployeeId,
                    sortFunction: sortEmployeesByUser ,
                    fieldName: 'fullname_short',
                    onChange: (value) => updateTask('EmployeeId', value)
                },
                {
                    name: 'GroupId',
                    label: t('team', 'Equipo'),
                    component: 'CustomSelectWithSearchBar',
                    items: addDictionaryExtraLine(memorizedGroups || {}),
                    value: data?.GroupId,
                    sortFunction: sortGroupsByUser,
                    onChange: (value) => updateTask('GroupId', value)
                },
                {
                    name: 'start_date',
                    label: t('date', 'Fecha'),
                    component: 'InputCalendarWithStartEndHoursStateLess',
                    valueStart: data?.start_date ? new Date(data?.start_date) : undefined,
                    valueEnd: data?.end_date ? new Date(data?.end_date) : undefined,
                    onChange: (value) => updateTask('start_date', value),
                    onDeleteDate: () => data?.start_date ? updateTask('start_date', ''): null
                },
                {
                    name: 'CustomerShopId',
                    component: 'Custom',
                    render: () => <CustomSelectTaskShop TaskId={data.id} value={data?.CustomerShopId} CustomerProject={data?.CustomerProject} shop={data?.CustomerShop} items={customerDicts?.shops || {}} {...{ updateTask }} />,
                    extraProps: { customerDicts, value: data?.CustomerShopId, projects: data?.CustomerProject, shop: data?.CustomerShop }
                },
                {
                    name: 'CustomerContactPersonId',
                    component: 'Custom',
                    render: () => <CustomSelectTaskContactPersons TaskId={data.id} value={data?.CustomerContactPersonId} CustomerProject={data?.CustomerProject} contact={data?.CustomerContactPerson} items={customerDicts?.contacts || {}} {...{ data, updateTask }} />,
                    extraProps: { customerDicts, value: data?.CustomerContactPersonId, projects: data?.CustomerProject, contact: data?.CustomerContactPerson }
                },
                {
                    name: 'inverted_time',
                    component: 'Custom',
                    render: () => <div onClick={() => setOpenModalInvertedTime({type: 'edit', allRowData: {}})}><InputHourMinSecUnlimitedStateFull readOnly={true} label={t('spentHours', 'Horas gastadas')} value={String(data.inverted_time)} /></div>,
                    extraProps: { inverted_time: data.inverted_time }
                },
                {
                    name: 'WorkingCenterId',
                    label: t('yourWorkingCenter', 'Tu centro de trabajo'),
                    component: 'CustomSelectWithSearchBar',
                    items: addDictionaryExtraLine(memorizedWorkingCenter || {}),
                    value: data?.WorkingCenterId,
                    sortItems: true,
                    onChange: (value) => updateTask('WorkingCenterId', value)
                },
                {
                    name: 'TaskTypeId',
                    label: t('taskType', 'Tipo de tarea'),
                    component: 'CustomSelectWithSearchBar',
                    value: data?.TaskTypeId,
                    onChange: (value) => updateTask('TaskTypeId', value),
                    items: taskTypes || {}
                },
            ]
        }
    ]
    return (
        <Fragment>
            <HeaderTaskId data={data} isFloating={isFloating} setSelectedSubtaskId={setSelectedSubtaskId} />
            <div className='px-40 py-30 overflow-y-auto flex flex-auto flex-col h-1 single_task_inner'>
                <div className='flex items-start justify-between'>
                    <TaskName name={data?.name || ''} id={data?.id || 0} changeName={changeName} />
                </div>
                <FormRenderer className='mt-20 flex-initial' sections={fields} />
                <TaskProjects TaskId={String(data?.id) || ''} selectedCustomerProject={data?.CustomerProject}  {...{ setData }} />
                <TaskCFields taskCFields={taskCFields} {...{ data, setData }} />
                <TextAreaDebounce className='mb-30' label={t('description', 'Descripción')} value={data?.description || ''} onChange={changeDescription} onBlur={(e) => {
                    const value = e.currentTarget.value
                    if(value !== data?.description){
                        changeDescription(e.currentTarget.value)
                    }
                }}  />
                {data?.ChildrenTasks?.length ? <TaskSubtasks childrenTasks={data.ChildrenTasks} {...{ isFloating, setData, refSubTask, setSelectedSubtaskId }} /> : null}
                <TaskComments TaskId={String(data?.id) || ''} CustomerProjectId={data?.CustomerProjectId} comments={data?.TaskComments as TaskCommentModelTypeExt[] || []} {...{ setData }} />
                <TaskLogs taskLogs={data?.TaskLogs || [] as any} />
            </div>
            {openModalInvertedTime !== null ? <ModalTaskInvertedTime {...{ openModalInvertedTime, setOpenModalInvertedTime, setData, data }} /> : null}
        </Fragment>
    )
}

interface SingleTaskInnerLogicProps {
    data: TaskExtendedTy
    employees: DictionaryEmployee | null
    groups: DictionaryGroup | null

}

const useSingleTaskInnerLogic = ({data, employees, groups}: SingleTaskInnerLogicProps) => {
    const memorizedEmployees = useMemo(() =>{ 
        const copy = JSON.parse(JSON.stringify(employees))
        if (copy && data?.EmployeeId && data?.Employee && !copy[data.EmployeeId]) {
            copy[data.EmployeeId] = data?.Employee
        }
        return copy
    } , [data?.EmployeeId, data?.Employee, employees])

    const memorizedGroups = useMemo(() => {
        const copy = JSON.parse(JSON.stringify(groups))
        if (copy && data?.GroupId && data?.Group && !copy[data.GroupId]) {
            copy[data.GroupId] = data?.Group
        }
         return copy 
    }, [ data?.GroupId, data?.Group, groups])

    const memorizedWorkingCenter = useMemo(() => {
        const user = getUserInfo()
        const copyWorkingCenters = JSON.parse(JSON.stringify(user?.WorkingCenters))
        if (data.WorkingCenterId && data.WorkingCenter && !copyWorkingCenters[data.WorkingCenterId]) {
            copyWorkingCenters[data.WorkingCenterId] = { id: data.WorkingCenterId, name: data.WorkingCenter?.name }
        }
        return copyWorkingCenters
    }, [data?.WorkingCenterId, data?.WorkingCenter])

    
    return { memorizedEmployees, memorizedGroups, memorizedWorkingCenter }
}


interface HeaderTaskIdProps {
    data: TaskExtendedTy
    isFloating?: boolean
    setSelectedSubtaskId?: React.Dispatch<React.SetStateAction<number | null>>
}

const HeaderTaskId: React.FC<HeaderTaskIdProps> = ({ data, isFloating = false, setSelectedSubtaskId }) => {
    const { handleReturnFloatingParentTask } = useHeaderTaskIdLogic({id: data.id, TaskId: data?.TaskId})
    const location = useLocation()
    if(!data?.TaskId){
        return null
    }
    const myOnClickFunction = setSelectedSubtaskId || handleReturnFloatingParentTask
    return (
        <div className = 'px-40 py-15 border-b border-gray-EE parent_task_return'>
            {isFloating || setSelectedSubtaskId ?
                <div className='cursor-pointer' onClick={() => myOnClickFunction(data.TaskId || 0)}>
                    <TwinIcon icon={faChevronsLeft} />
                    <span className='ml-20'>{data.TaskId} - {data?.ParentTask?.name}</span>
                </div>
                :
                <Link className='cursor-pointer hover:text-green-43' to={getPathWithoutLastSlash(location.pathname) + '/' + data.TaskId}>
                    <TwinIcon icon={faChevronsLeft} />
                    <span className='ml-20'>{data.TaskId} - {data?.ParentTask?.name}</span>
                </Link>
            }
        </div >
    )
}

interface HeaderTaskIdLogicProps {
    id: number
    TaskId?: number
}

const useHeaderTaskIdLogic = ({id, TaskId}: HeaderTaskIdLogicProps) => {
    const handleReturnFloatingParentTask = useCallback(() => {
        deleteBottomNavAppElement('taskModal-'+ id)
        addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + TaskId, extraData: { id: TaskId, opened: true, skipAnimation: true } })
    }, [id, TaskId])
    return { handleReturnFloatingParentTask }
}


const whiteBoxSingleTaskDispatch = {
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
    setTaskTypes: (payload: TaskTypePayload) => ({ type: 'CHANGE_TASKTYPE', payload }),
}

type ReduxWhiteBoxSingleTask = ConnectedProps<typeof whiteBoxSingleTaskConnect>
const mapWhiteBoxSingleTaskConnector = (state: AllReduxPayloads) => ({ employees: state.employees, groups: state.groups, taskCFields: state.taskCFields, taskTypes: state.taskTypes })
const whiteBoxSingleTaskConnect = connect(mapWhiteBoxSingleTaskConnector, whiteBoxSingleTaskDispatch)

const taskConnectLoading = withLoading(WhiteBoxSingleTask, [{ fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/customField/getAllTaskCFields', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }, { fetchUrl: '/api/app/task/taskType/getAllTaskTypes', propName: 'taskTypes', setFunctionName: 'setTaskTypes' }])
const WhiteBoxSingleTaskConnect = whiteBoxSingleTaskConnect(taskConnectLoading)

export default WhiteBoxSingleTaskConnect