import { TwinParameters } from '@teinor/erp/types/company/config/parameter'
import { getConfigParam } from '../reducers/getters'

export const checkFloatValid = (value: string) => {
    if (!value) {
        return value
    }
    const re = new RegExp("^[0-9]*[.,]{0,1}[0-9]*$")
    if (re.test(value)) {
        const {decimalSeparator} = getSeparatorsOfFormatedNumber()
        let newValue = value.replace(decimalSeparator, '.')
        return newValue
    }
    return false
}

export const checkHalfFloatValid = (value: string) => {
    if (!value) {
        return value
    }
    const re = new RegExp("^[0-9]*[.,]{0,1}[05]{0,1}$")
    if (re.test(value)) {
        const { decimalSeparator } = getSeparatorsOfFormatedNumber()
        let newValue = value.replace(decimalSeparator, '.')
        return newValue
    }
    return false
}

export const checkIntegerValid = (value: string) => {
    if (!value) {
        return value
    }
    const re = new RegExp("^[0-9]*$")
    if (re.test(value)) {
        return value
    }
    return false
}

export const isValidDay = (value: string) => {
    const re = new RegExp("^[0-9]*$")
    if (re.test(value)) {
        if (parseInt(value)  === 0 || parseInt(value) > 31){
            return false
        }
        return true
    }
    return false
}

const regexForSeparators = /\p{Number}/gu

export const displayFormatedNumber = (value: string | number, format?: number | false) => {
    let decimals = format || 0
    if (format === undefined) {
        decimals = getConfigParam('decimals') as TwinParameters['decimals']
    }
    if (value !== '') {
        const formated = new Intl.NumberFormat(undefined , {minimumFractionDigits: decimals, maximumFractionDigits: 10}).format(parseFloat(String(value)))
        const { thousandSeparator, decimalSeparator } = getSeparatorsOfFormatedNumber()
        const decimalIndex = formated.indexOf(decimalSeparator)
        if(formated[0] === '-'){
            if ((decimalIndex === -1 && formated.length === 5 && !formated.replace(regexForSeparators, '').length) || (decimalIndex === 5)) {
                return formated.substring(0, 2) + thousandSeparator + formated.substring(2)
            }
            return formated
        }
        if((decimalIndex === -1 && formated.length === 4 && !formated.replace(regexForSeparators, '').length) || (decimalIndex === 4)){
            return formated.substring(0, 1) + thousandSeparator + formated.substring(1)
        }
        return formated
    }
    return ''
}

export const getSeparatorsOfFormatedNumber = () => {
    const thousandSeparator = Intl.NumberFormat().format(11111).replace(regexForSeparators, '');
    const decimalSeparator = Intl.NumberFormat().format(1.1).replace(regexForSeparators, '');
    return {thousandSeparator, decimalSeparator}
}

export const replaceDotsForDecimalSeparator = (number: string) => {
    const { decimalSeparator } = getSeparatorsOfFormatedNumber()    
    return number.replace('.', decimalSeparator)
}