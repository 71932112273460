import { faArrowRotateBack } from '@fortawesome/pro-light-svg-icons'
import { useCallback } from 'react'
import Checkbox from '../../../../forms/Checkbox'
import { SelectedColumnsAndFilters, SelectedColumnsTy, getOnlyDefaultColumns } from '../../../../utils/hooks/useConfigColumns'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import VerticalSortList from '../../../Sorts/VerticalSortList'
import TwinIcon from '../../../TwinIcon'
import TwinTrans from '../../../TwinTrans'
import { ModalCreateEditStructureLayout } from '../../ModalCreateEdit'
import { ModalConfigColumnsBaseProps } from '../types'
import TwinForm from '../../../../forms/TwinForm'
import { VerticalSortItem } from '../../../Sorts/VerticalSortList/types'
import HeaderModalConfigColumns from '../HeaderModalConfigColumns'

interface ModalConfigColumnsProps extends ModalConfigColumnsLogicProps {
}

const ModalConfigColumns: React.FC<ModalConfigColumnsProps> = ({ tmpColumns, columns, onSave, ...logic }) => {
    const { changeChecked, changeOrder, resetSelected } = useModalConfigColumnsLogic({ tmpColumns, columns, onSave, ...logic })
    const { t } = useTwinTranslation()

    const firstColumn: JSX.Element[] = []
    const secondColumn: VerticalSortItem[] = []
    const premiumColumns: JSX.Element[] = []
    for (const key in columns) {
        const item = columns[key]
        if ((item.label || item.simpleLabel) && item.havePermission !== false && !item.justForFilter) {
            if (item.haveModule !== false) {
                const index = Object.keys(tmpColumns.selectedColumns).indexOf(item.id)
                const selected = index !== -1
                firstColumn.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={selected} readOnly={item.requiredColumn} /></div>)
                if (selected) {
                    secondColumn.push({
                        name: item.simpleLabel || item.label as any,
                        id: item.id,
                        order: index + 1
                    })
                }
            } else {
                premiumColumns.push(<div className='mt-15' key={key}><Checkbox label={item.simpleLabel || item.label as any} onChange={() => changeChecked(item.id)} checked={true} readOnly={true} /></div>)
            }
        }
    }
    secondColumn.sort((a, b) => a.order - b.order)

    return (
        <TwinForm beforeSubmitHandler={onSave} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: <HeaderModalConfigColumns {...{ tmpColumns, columns, setTmpColumns: logic.setTmpColumns, name: logic.name}}  />,
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto h-full w-full'>
                    <div className='flex flex-wrap w-full h-fit config_columns_container'>
                        <div className='w-fit max-w-300'>
                            <div className='flex items-center mb-20'>
                                <h4 className='mr-18 '><TwinTrans transKey='columnsToShow'>Columnas a mostrar</TwinTrans></h4>
                                <div className='flex items-center text-green-21 cursor-pointer' onClick={resetSelected}>
                                    <TwinIcon icon={faArrowRotateBack} fontSize={14} className='mr-8' />
                                    <span className='mt-2 lh-14'><TwinTrans transKey='byDefault'>Por defecto</TwinTrans></span>
                                </div>
                            </div>
                            {firstColumn}
                            {premiumColumns.length ? <div className='mt-20'>
                                <h2><TwinTrans transKey='columnsCouldBuy'>Columnas que podrías comprar</TwinTrans></h2>
                                {premiumColumns}
                            </div> : null}
                        </div>
                        <div className='border-r border-gray-D6 w-1 mx-75 config_columns_border' ></div>
                        <div className='flex flex-col min-h-full flex-auto w-1 config_colums_sort_list'>
                            <h4 className='mb-20'><TwinTrans transKey='orderSelectedColumns'>Ordena las columnas seleccionadas</TwinTrans></h4>
                            <VerticalSortList items={secondColumn} setItems={(setItemsFake) => changeOrder(setItemsFake(secondColumn))} />
                        </div>
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface ModalConfigColumnsLogicProps extends ModalConfigColumnsBaseProps { }

const useModalConfigColumnsLogic = ({ setTmpColumns, selectedColumnsAndFilters, columns }: ModalConfigColumnsLogicProps) => {
    const changeChecked = useCallback((itemKey: string) => {
        setTmpColumns((oldTmp) => {
            let tmpCopy: SelectedColumnsAndFilters = JSON.parse(JSON.stringify(oldTmp))
            const item = tmpCopy.selectedColumns[itemKey]
            if (item) {
                delete tmpCopy.selectedColumns[itemKey]
            } else {
                tmpCopy.selectedColumns = { ...tmpCopy.selectedColumns, [itemKey]: selectedColumnsAndFilters.selectedColumns[itemKey] || {} }
            }
            return { ...tmpCopy }
        })
    }, [selectedColumnsAndFilters, setTmpColumns])

    const changeOrder = useCallback((items: VerticalSortItem[]) => {
        setTmpColumns((oldTmp) => {
            let columns: SelectedColumnsTy = {}
            for (const item of items) {
                columns[item.id] = selectedColumnsAndFilters.selectedColumns[item.id] || {}
            }
            return { ...oldTmp, selectedColumns: columns }
        })
    }, [selectedColumnsAndFilters, setTmpColumns])

    const resetSelected = useCallback(() => {
        setTmpColumns((tmpColumns) => {
            const defaultCols = getOnlyDefaultColumns(columns)
            const myColumns = { ...tmpColumns, ...defaultCols, filters: tmpColumns.filters}
            return myColumns
        })
    }, [columns, setTmpColumns])

    return { setTmpColumns, changeChecked, changeOrder, resetSelected }
}


export default ModalConfigColumns