import { useState, useMemo, useCallback } from 'react'
import { ButtonPrimary } from '../../../../../../../../../baseComponents/Button'
import { ComponentWithPermissions } from '../../../../../../../../../baseComponents/CreateRoutering/types'
import { SmallTitleAndRightLayout } from '../../../../../../../../../baseComponents/Layout/TitleAndRightLayout'
import TwinBigCalendar from '../../../../../../../../../baseComponents/TwinBigCalendar'
import { TwinBigCalendarEvents } from '../../../../../../../../../baseComponents/TwinBigCalendar/types'
import TwinTrans from '../../../../../../../../../baseComponents/TwinTrans'
import { displayFormatedHourFromMins, getHoursMinFromMin } from '../../../../../../../../../utils/globals/date'
import useTwinTranslation from '../../../../../../../../../utils/hooks/useTwinTranslation'
import { WCalParentExtendedTy } from '../../types'
import ModalEditScheduleWorkingCalendar from './ModalEditScheduleWorkingCalendar'
import { TwinDictionary } from '../../../../../../../../../utils/globals/dictionary'
import './scheduleWorkingCalendar.sass'
import PermissionChecker from '../../../../../../../../../baseComponents/PermissionChecker'

interface ScheduleWorkingCalendarProps extends ComponentWithPermissions {
    basicData: WCalParentExtendedTy | null
    getData: () => Promise<void>
}

const weekDayDict: TwinDictionary = {
    'monday' : '2024/02/05 ',
    'tuesday' : '2024/02/06 ',
    'wednesday' : '2024/02/07 ',
    'thursday' : '2024/02/08 ',
    'friday' : '2024/02/09 ',
    'saturday' : '2024/02/10 ',
    'sunday' : '2024/02/11 ',
}
const ScheduleWorkingCalendar: React.FC<ScheduleWorkingCalendarProps> = ({basicData, getData, userPermissions}) => {
    const {t} = useTwinTranslation()
    const { handleOpened, openedModalSchedule, setOpenedModalSchedule, defaultDate, parsedSchedule } = useScheduleWorkingCalendarLogic({schedule: basicData?.schedule || ''})
    const events: TwinBigCalendarEvents = []
    for (const day in parsedSchedule) {
        if (parsedSchedule[day].workingTime.length){
            const parsedDate = weekDayDict[day]
            for (const hours of parsedSchedule[day].workingTime) {
                const totalTime = displayFormatedHourFromMins(hours[1] -hours[0])
                const start = getHoursMinFromMin(hours[0])
                const end = getHoursMinFromMin(hours[1])
                events.push(
                    {
                        title: `Jornada laboral \n Total horas: ${totalTime}`,
                        start: new Date(parsedDate  + start.hours +':'+start.mins+':00'),
                        end: new Date(parsedDate + end.hours + ':' + end.mins + ':00'),
                    }
                )
            }
        }
        
    }
    return (
        <div className='schedule_working_calendar flex-auto flex flex-col'>
            <SmallTitleAndRightLayout title={t('schedule', 'Horario')} RightHeader={<PermissionChecker permission='update' userPermissions={userPermissions}><ButtonPrimary onClick={handleOpened}><TwinTrans transKey={'editSchedule'}>Editar Horario</TwinTrans></ButtonPrimary></PermissionChecker>}>
                <TwinBigCalendar events={events} defaultView='week' defaultDate={defaultDate} />
                {openedModalSchedule ? <ModalEditScheduleWorkingCalendar schedules={parsedSchedule} {...{ openedModalSchedule, setOpenedModalSchedule, getData, basicData}} /> : null}
            </SmallTitleAndRightLayout>
        </div>
    )
}

interface ScheduleWorkingCalendarLogicProps {
    schedule?: string
}

const useScheduleWorkingCalendarLogic = ({ schedule }: ScheduleWorkingCalendarLogicProps) => {
    const [openedModalSchedule, setOpenedModalSchedule] = useState<true | null>(null)
    const parsedSchedule = JSON.parse(schedule ||'')

    const { defaultDate } = useMemo(() => ({
        defaultDate: new Date('2024/02/05')
    }), [])

    const handleOpened = useCallback(() => {
        setOpenedModalSchedule((old)=>{if(old){return null} else{ return true}})
    }, [setOpenedModalSchedule])

    return { handleOpened, openedModalSchedule, setOpenedModalSchedule, defaultDate, parsedSchedule }
}


export default ScheduleWorkingCalendar