import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { twinFetchPostJSON } from '../../../../utils/globals/data'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import { DisplayStatusWithoutIcon } from '../../../Displays/DisplayStatus'
import { VirtualTableListingStateLess } from '../../../TwinTable/VirtualTableListing'
import { CustomRenderDate, CustomRenderString } from '../../../TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../TwinTable/VirtualTableListing/Subcomponents/types'
import { CustomRenderSchedulesWithoutHeader } from '../../../../specificComponents/WCal/schedule/CustomRenderSchedules'
import TwinTrans from '../../../TwinTrans'


interface WeekHolidayTableProps { }

const WeekHolidayTable: React.FC<WeekHolidayTableProps> = () => {
    const { t } = useTwinTranslation()
    const title = t('holidaysAndAbsencesThisWeek','Vacaciones y ausencias esta semana')

    return (
        <div className='flex flex-auto flex-col tce_employee_holidays_calendar'>
            <h3 className='pb-15'>{title}</h3>
           <TableHolidayEmployeeThisWeek/>
        </div>
    )
}

interface HolidaysAndAbsencesTogether {
    dateStart: string
    dateEnd: string
    schedule: string
    employeeName: string
    type: 'absence' | 'holiday'
    approved: 0 | 1,
    isHalfDay?: boolean
}

const TableHolidayEmployeeThisWeek = () => {
    const { t } = useTwinTranslation()
    const {data} = useTableHolidayEmployeeThisWeekLogic()

    const columns: ColumnTableSchema<HolidaysAndAbsencesTogether> = [
        {
            id: 'employee',
            dataKey: 'employeeName',
            label: t('employee', 'Empleado'),
            customRender: (parameterValue) => <CustomRenderString value={parameterValue} />,
        },
        {
            id: 'dateStart',
            dataKey: 'dateStart',
            label: t('from', 'Desde'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'dateEnd',
            dataKey: 'dateEnd',
            label: t('to', 'Hasta'),
            customRender: (parameterValue) => <CustomRenderDate value={parameterValue} />,
        },
        {
            id: 'schedule',
            dataKey: 'schedule',
            label: t('schedule', 'Horario'),
            customRender: (parameterValue) => {
                if (parameterValue === 'isHalfDay') {
                    return (
                        <div className='flex flex-col '>
                            <span className='regular14 overflow-hidden'><TwinTrans transKey='halfDay'>Medio día</TwinTrans></span>
                        </div>
                    )
                }
                return <CustomRenderSchedulesWithoutHeader schedule = { parameterValue } />
            },
        },
        {
            id: 'type',
            dataKey: 'type',
            label: t('type', 'Tipo'),
            customRender: (parameterValue) => <RenderEmployeeAbsenceType value={parameterValue as HolidaysAndAbsencesTogether['type']} />,
            minWidth: 120
        },
    ]

    return (
        <div className='flex-auto'>
            <VirtualTableListingStateLess tableData={data} rowHeight={48} headerHeight={48} name='weekHolidayTableDashboard' columns={columns} setSelectedColumnsAndFilters={() => { }} updateEmployeeParameter={false} />
        </div>
    )
}

const useTableHolidayEmployeeThisWeekLogic = () => {
    const [data, setData] = useState<HolidaysAndAbsencesTogether[] | null>(null)

    const getData = useCallback(async () => {
        const dateStart = moment().startOf('week').toDate()
        const dateEnd = moment().endOf('week').toDate()
        const result = await twinFetchPostJSON('/api/app/workingCalendar/workingCalendarHolidayBag/getAllEmployeeHolidaysAndAusencesByDates', { dateStart, dateEnd })
        const myData: HolidaysAndAbsencesTogether[] = []
        if (result) {
            for (const element of result.holidays) {
                myData.push({ dateStart: element.from, dateEnd: element.to, employeeName: element?.WCalHolidayBag?.Employee?.fullname_short, type: 'holiday', approved: element.approved, schedule: element.isHalfDay ? 'isHalfDay' : ''})
            }
            for (const element of result.absences) {
                myData.push({ ...element, employeeName: element?.Employee?.fullname_short, type: 'absence' })
            }
            setData(myData)
        }

        
    }, [setData])

    useEffect(() => {
        getData()
    }, [getData])

    return {data}
}

interface RenderEmployeeAbsenceTypeProps {
    value: HolidaysAndAbsencesTogether['type']
}

const RenderEmployeeAbsenceType: React.FC<RenderEmployeeAbsenceTypeProps> = ({ value }) => {
    const {t} = useTwinTranslation()
    const status = {
        'absence': { name: t('singleAbsence', 'Ausencia'), colorText: 'text-yellow-B9' },
        'holiday': { name: t('holidays', 'Vacaciones'), colorText: 'text-green-21' },
    }
    return <DisplayStatusWithoutIcon colorText={status[value].colorText} text={status[value].name} />
}

export default WeekHolidayTable