import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { ConnectedProps, connect } from 'react-redux'
import { ComponentWithPermissions } from '../../../../../../baseComponents/CreateRoutering/types'
import TwinTable from '../../../../../../baseComponents/TwinTable'
import BaseScreenTable from '../../../../../../baseComponents/TwinTable/BaseScreenTable'
import { TableComponentBaseScreenInnerProps } from '../../../../../../baseComponents/TwinTable/BaseScreenTableInner/types'
import { CustomRenderString, EditRow } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import { ColumnTableSchema } from '../../../../../../baseComponents/TwinTable/VirtualTableListing/Subcomponents/types'
import ModalEditCustomerProject from '../../../../../../specificComponents/Customers/ModalEditCustomerProject'
import withLoading from '../../../../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../../../../utils/reducers'
import { CustomerPayload } from '../../../../../../utils/reducers/customers/customer'
import ModalCreateCustomerProject from './ModalCreateCustomerProject'
import { CustomerProjectTypePayload } from '../../../../../../utils/reducers/customers/customerProjectTypes'
import { RowData } from '../../../../../../baseComponents/TwinTable/types'
import { getConfigParam } from '../../../../../../utils/reducers/getters'
import { CodeCustomerToShowTyInversed } from '@teinor/erp/types/company/config/parameter'
import { CustomerRenderCustomerProjectStatus } from '../../../../../../specificComponents/Customers/Subcomponents'

interface CustomerProjectsProps extends ComponentWithPermissions {
}

const CustomerProjects: React.FC<CustomerProjectsProps> = ({ userPermissions }) => {
    const { t } = useTwinTranslation()
    const pageData = {
        title: t('projects', 'Proyectos'),
        newText: t('addProject', 'Añadir un proyecto'),
    }

    return (
        <BaseScreenTable TableComponent={TableCustomerProjectsConnect} pageData={pageData} CreateModalComponent={ModalCreateCustomerProject} EditModalComponent={ModalEditCustomerProject} userPermissions={userPermissions} />
    )
}

type TableCustomerProjectsProps = TableComponentBaseScreenInnerProps & ReduxCustomerAndProjectTypes & {
}

const TableCustomerProjects: React.FC<TableCustomerProjectsProps> = ({ setTableInstance, setOpened, customers, customerProjectTypes }) => {
    const { t } = useTwinTranslation()
    const columns: ColumnTableSchema<CustomerProjectModelType> = [
        {
            id: 'id',
            dataKey: 'id',
            label: t('id', 'ID'),
            width: 75,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('id', 'ID') }
            },
            searchKey: 'CustomerProject.id'
        },
        {
            id: 'customer',
            dataKey: 'CustomerId',
            label: t('customer', 'Cliente'),
            customRender: (_parameterValue, allRowData) => <CustomRenderCustomer allRowData={allRowData} />,
            sortable: true,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelectSearchBar',
                extraComponentData: { items: customers?.all || {}, label: t('customer', 'Cliente') }
            },
            searchKey:'Customer.name'
        },
        {
            id: 'name',
            dataKey: 'name',
            label: t('name', 'Nombre'),
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('name', 'Nombre') }
            },
            searchKey: 'CustomerProject.name'

        },
        {
            id: 'description',
            dataKey: 'description',
            label: t('description', 'Descripción'),
        },
        {
            id: 'CustomerProjectType',
            dataKey: 'CustomerProjectTypeId',
            label: t('projectType', 'Tipo de proyecto'),
            customRender: (parameterValue, allRowData) => <CustomRenderString value={allRowData?.CustomerProjectType?.name} />,
            searchableComponent: {
                component: 'SearchableComponentsCustomSelect',
                extraComponentData: { items: customerProjectTypes || {}, label: t('projectType', 'Tipo de proyecto') }
            },
            searchKey: 'CustomerProject.CustomerProjectTypeId'
        },
        {
            id: 'CurrentCProjectStatusId',
            dataKey: 'CurrentCProjectStatusId',
            label: t('status', 'Estado'),
            customRender: (_parameterValue, allRowData) => <CustomerRenderCustomerProjectStatus value={allRowData?.CurrentCProjectStatus?.name} color={allRowData?.CurrentCProjectStatus?.color} />,
            searchableComponent: {
                component: 'SearchableComponentBasic',
                extraComponentData: { label: t('status', 'Estado') }
            },
            searchKey: 'CurrentCProjectStatus.name',
            sortable: true,
            sortKey: 'CurrentCProjectStatus.order'
        },
        {
            id: 'edit',
            dataKey: 'id',
            label: '',
            width: 50,
            simpleLabel: t('edit', 'Editar'),
            customRender: (parameterValue, allRowData) => <EditRow key={'edit' + parameterValue} {...{ parameterValue, allRowData }} />
        },
    ]
    return (
        <TwinTable name='CustomerProjects' columns={columns} defaultOrder={{ order: 'desc', defaultOrderField: 'createdAt' }} getDataFrom='/api/app/customer/customerProject/getAllCustomerProjects'
            onRowClick={(_id, rowData) => setOpened({
                type: 'edit',
                allRowData: rowData
            })}
            setTableInstance={setTableInstance} extraWhereParams={{'CustomerProject.active': true}} />
    )
}

interface CustomRenderCustomerProps {
    allRowData: RowData
}

const CustomRenderCustomer: React.FC<CustomRenderCustomerProps> = ({allRowData}) => {
    const codeCustomerToShow = getConfigParam('codeCustomerToShow')
    let fieldId: 'id' | 'code' = 'id'
    if (parseInt(String(codeCustomerToShow)) === parseInt(String(CodeCustomerToShowTyInversed['codeCustomer']))) {
        fieldId = 'code'
    }
    const code = allRowData?.Customer?.[fieldId]
    const value = (code ? (code + ' - ') : '') + allRowData?.Customer?.name
    return (
        <CustomRenderString value={value} />
    )
}


const customersAndProjectTypesDispatch = {
    setCustomers: (payload: CustomerPayload) => ({ type: 'CHANGE_CUSTOMER', payload }),
    setCustomerProjectTypes: (payload: CustomerProjectTypePayload) => ({ type: 'CHANGE_CUSTOMER_PROJECT_TYPES', payload })
}

export type ReduxCustomerAndProjectTypes = ConnectedProps<typeof customersAndProjectTypesConnect>
const mapCustomersAndProjectTypesConnector = (state: AllReduxPayloads) => ({ customers: state.customers, customerProjectTypes: state.customerProjectTypes })
const customersAndProjectTypesConnect = connect(mapCustomersAndProjectTypesConnector, customersAndProjectTypesDispatch)

const customersAndProjectTypesConnectLoading = withLoading(TableCustomerProjects, [{ fetchUrl: '/api/app/customer/getAllCustomersComplete', propName: 'customers', setFunctionName: 'setCustomers' }, { fetchUrl: '/api/app/customer/customerProject/customerProjectTypes/getAllCustomerProjectTypesFull', propName: 'customerProjectTypes', setFunctionName: 'setCustomerProjectTypes' }])

const TableCustomerProjectsConnect = customersAndProjectTypesConnect(customersAndProjectTypesConnectLoading)


export default CustomerProjects