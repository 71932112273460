import { CustomRenderDate, CustomRenderString } from '../../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'

interface CustomRenderWCalHalfDayForMProps {
    value?: boolean
}

const CustomRenderWCalHalfDayForM: React.FC<CustomRenderWCalHalfDayForMProps> = ({ value }) => {
    if(!value){
        return null
    }
    return (
        <div className='custom_render_WCal_half_day'>
            <div className='light12 text-gray-51 mb-4'><TwinTrans transKey='holidayHalfDay'>Medio día de vacaciones</TwinTrans></div>
            <TwinTrans transKey='yes'>Sí</TwinTrans>
        </div>
    )
}

interface CustomRenderWCalHalfDayForTableProps {
    isHalfDay?: boolean
    endDate: string
}

export const CustomRenderWCalHalfDayForTable: React.FC<CustomRenderWCalHalfDayForTableProps> = ({ isHalfDay, endDate }) => {
    const {t} = useTwinTranslation()
    if(!isHalfDay){
        return <CustomRenderDate value={endDate} />
    }
    return <CustomRenderString value={t('halfDay', 'Medio día')} />
}

export default CustomRenderWCalHalfDayForM