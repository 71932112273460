import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../../baseComponents/TwinIcon'
import TwinTrans from '../../../baseComponents/TwinTrans'
import { Link } from 'react-router-dom'
import { ButtonPrimary } from '../../../baseComponents/Button'
import { getBasePath } from '../../../utils/globals/link'
import { useLocation } from 'react-router'
import { useMemo } from 'react'
import TwinToolTip from '../../../baseComponents/TwinToolTip'
import { CustomRenderStringAndColor } from '../../../baseComponents/TwinTable/VirtualTableListing/CustomRenders'

interface NoProjectsMessageProps {
    id: number
}

export const NoProjectsMessage: React.FC<NoProjectsMessageProps> = ({ id }) => {
    const location = useLocation()
    const basePathToProjects = getBasePath(location.pathname, String(id))
    return (
        <div className='flex items-center justify-center flex-col flex-auto'>
            <div className='regular22 text-red-BA'>
                <TwinIcon icon={faExclamationTriangle} className='mr-10 h-25' />
                <TwinTrans transKey='noProjectsInThisCustomer'>No dispone de proyectos en este cliente</TwinTrans>
            </div>
            <div className='my-25'>
                <span className='regular18'><TwinTrans transKey='mustCreateProjectsToSeeSection'>Debe crear proyectos para poder ver esta sección</TwinTrans></span>
            </div>
            <Link to={basePathToProjects + '/projects'}>
                <ButtonPrimary>
                    <TwinTrans transKey='createProjects'>Crear Proyectos</TwinTrans>
                </ButtonPrimary>
            </Link>
        </div>
    )
}

interface CustomRenderTaskNameProps {
    id: number
    name?: string
    img: string
    tooltipName?: string
}

export const CustomerProjectRenderImageTooltip: React.FC<CustomRenderTaskNameProps> = ({ id, name, img, tooltipName }) => {
    const myName = name || ''
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderImage' + tooltipName + '' +String(id), [id, tooltipName])
    return (
        <div className='twin_elipsis'>
            <TwinToolTip id={idTooltip} place='top' className='max-w-600'>
                <p className='text-wrap-initial'>{myName}</p>
            </TwinToolTip>
            <img data-tooltip-id={idTooltip} src={img} alt={name} className='w-27 min-w-27 h-27 rounded-full' />
        </div>
    )
}
interface CustomerRenderCustomerProjectStatusProps {
    value?: string
    color?: string
    className?: string
}

export const CustomerRenderCustomerProjectStatus: React.FC<CustomerRenderCustomerProjectStatusProps> = ({ value, color, className }) => {
    if (!value) {
        return null
    }
    return (
        <CustomRenderStringAndColor className={className} value={value} color={color || ''} />
    )
}
