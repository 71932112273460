import { TaskCommentModelType } from '@teinor/erp/types/company/task/taskComment'
import { TaskExtendedTy } from './types'
import { TwinDictionary } from '../../../utils/globals/dictionary'
import { TaskWorkLogModelType } from '@teinor/erp/types/company/task/taskWorkLog'
import { CustomerProjectModelType } from '@teinor/erp/types/company/customer/customerProject'
import { TaskModelType } from '@teinor/erp/types/company/task'
import { TaskCFieldValTaskRelModelType } from '@teinor/erp/types/company/task/taskCFieldValTaskRel'
import { TaskLogTypeInversed, TaskLogTypeKeys } from '@teinor/erp/types/company/task/taskLog'
import { getUserInfo } from '../../../utils/reducers/getters'
import { sortChildrenTasks } from './TaskSubtasks/functions'

const parseComment = (data: TaskExtendedTy , value: TaskCommentModelType  ) => {
    if(data?.TaskComments){
        const prevComment = data.TaskComments?.[data?.TaskComments.length]
        if (!prevComment || prevComment?.content !== value.content) {
            data.TaskComments.push(value)
        }
    }else {
        data.TaskComments = [value]
    }
}

const parseUpdateComment = (data: TaskExtendedTy , value: TaskCommentModelType  ) => {
    if(data?.TaskComments){
        for (const i in data.TaskComments) {
            let comment = data.TaskComments?.[i]
            if (comment.id === value.id) {
                data.TaskComments[i] = { ...comment, ...value }
            }
        }
    }
}

const parseDeleteComment = (data: TaskExtendedTy , value: TaskCommentModelType  ) => {
    if(data?.TaskComments){
        for (const i in data.TaskComments) {
            let comment = data.TaskComments?.[i]
            if (comment.id === value.id) {
                data.TaskComments.splice(parseInt(String(i)), 1)
            }
        }
    }
}

const parseCreateTaskWorkLog = (data: TaskExtendedTy , value: TaskWorkLogModelType  ) => {
    const myWorkedSecs = value.workedSecs || 0
    data.inverted_time = parseInt(String(data.inverted_time)) + parseInt(String(myWorkedSecs))
    data.TaskWorkLogs?.push({...value, workedSecs: myWorkedSecs})
}
const parseUpdateEndTaskWorkLog = (data: TaskExtendedTy , value: any  ) => {
    if (value.diffWorkedSecs) {
        const myWorkedSecs = value.workedSecs || 0
        data.inverted_time = parseInt(String(data.inverted_time)) + value.diffWorkedSecs
        for (const k in data?.TaskWorkLogs) {
            const workLog = data?.TaskWorkLogs[parseInt(k)]
            if(String(workLog.id) === String(value.id)){
                data.TaskWorkLogs[parseInt(k)] = { ...workLog, ...value, workedSecs: myWorkedSecs }
            }   
        }
    }
}

const parseUpdateTaskWorkLogs = (data: TaskExtendedTy , value: TwinDictionary  ) => {
    const {updatedWorkLogs} = value
    let total = 0
    if(updatedWorkLogs){
        data.TaskWorkLogs = [...updatedWorkLogs]
        if (updatedWorkLogs.length){
            for (const workLog of updatedWorkLogs) {
                if(workLog.workedSecs){
                    total += parseInt(String(workLog.workedSecs))
                }
            }
        }
    }
    data.inverted_time = total
}

const parseCustomerProject = (data: TaskExtendedTy, value: CustomerProjectModelType  ) => {
    data.CustomerProjectId = value.id
    data.CustomerProject = value
}

const parseRemoveCustomerProject = (data: TwinDictionary) => {
    data.CustomerProjectId = null
    data.CustomerProject = null
    data.CustomerContactPersonId = null
    data.CustomerShopId = null
    data.CustomerId = null
}

const parseAssignCustomField = (data: TaskExtendedTy, value: TaskCFieldValTaskRelModelType  ) => {
    data.dictTaskCFieldValues[value.TaskCFieldId] = value.TaskCFieldValueId
}

const parseCreateSubTask = (data: TaskExtendedTy, value: TaskModelType) => {
    if(data?.ChildrenTasks){
        data.ChildrenTasks.push(value)
    }
    if(value?.TaskLogs?.length){
        data.TaskLogs?.unshift({ ...value.TaskLogs[0], TaskId: value.TaskId, type: TaskLogTypeInversed['createSubTask'] as TaskLogTypeKeys, extraData: JSON.stringify({ 'subTaskId': value.id }) },)
    }
}

const parseUpdateSubTask = (data: TaskExtendedTy, value: TwinDictionary  ) => {
    if (data?.ChildrenTasks && data.ChildrenTasks?.length){
        for (const j in data.ChildrenTasks) {
            const subTask = data.ChildrenTasks[j]
            if (parseInt(String(subTask.id)) === parseInt(String(value.id))) {
                const updatedSubTask = value['SubTaskToUpdate']
                data.ChildrenTasks[j] = updatedSubTask
            }
        }
        data.ChildrenTasks = sortChildrenTasks(data.ChildrenTasks)
    }
}

const parseUpdateTask = (data: any, value: TwinDictionary, restData: TwinDictionary) => {
    for (const i in value) {
        if (i !== 'id' && i !== 'TaskLogs') {
            if (i === 'remember_date' && typeof value === 'string') {
                data[i] = new Date(value)
            } else {
                data[i] = value[i]
            }
        }
        if (data?.TaskLogs?.[0]?.id !== value?.TaskLogs?.[0]?.id){
            data.TaskLogs.unshift(value.TaskLogs[0])
        }
        if (restData.ParentTask?.name) {
            data.ParentTask = restData.ParentTask
        }
    }
}

const parseSubscriber = (data: TaskExtendedTy, value: TwinDictionary) => {
    const myEmployeeId = getUserInfo()?.Employee?.id
    if (parseInt(String(myEmployeeId)) === parseInt(value.EmployeeId)){
        if (data.is_subscribed) {
            data.is_subscribed = false
        } else {
            data.is_subscribed = true
        }
    }
}


export const socketSingleTaskParseFncs: TwinDictionary = {
    comment: parseComment,
    updateComment: parseUpdateComment,
    deleteComment: parseDeleteComment,
    createTaskWorkLog: parseCreateTaskWorkLog,
    updateTaskWorkLog: parseUpdateEndTaskWorkLog,
    endTaskWorkLog: parseUpdateEndTaskWorkLog,
    customerProject: parseCustomerProject,
    removeCustomerProject: parseRemoveCustomerProject,
    assignCustomField: parseAssignCustomField, 
    createSubTask: parseCreateSubTask,
    updateSubTask: parseUpdateSubTask,
    updateTask: parseUpdateTask,
    updateTaskWorkLogs: parseUpdateTaskWorkLogs,
    subscriber: parseSubscriber
}