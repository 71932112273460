import { TaskModelType } from '@teinor/erp/types/company/task'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { ModalWithCloseButton } from '../../../baseComponents/Modal'
import { ModalCreateEditStructureLayout } from '../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../baseComponents/ModalsLayouts/types'
import { RowData } from '../../../baseComponents/TwinTable/types'
import FormRenderer from '../../../forms/FormRenderer'
import { FormRender } from '../../../forms/FormRenderer/types'
import TwinForm from '../../../forms/TwinForm'
import { BeforeSubmitHandler, OnSubmit } from '../../../forms/TwinForm/types'
import { twinFetchPostJSON } from '../../../utils/globals/data'
import { TwinDictionary, addDictionaryExtraLine, dictionaryComplexFromJsonArr } from '../../../utils/globals/dictionary'
import { returnClassOfSpanTemplate } from '../../../utils/globals/tailwind'
import withLoading from '../../../utils/hoc/withLoading'
import useTwinTranslation from '../../../utils/hooks/useTwinTranslation'
import { AllReduxPayloads } from '../../../utils/reducers'
import { TaskHelpersPayload } from '../../../utils/reducers/company/taskHelpers'
import { TaskTypePayload } from '../../../utils/reducers/company/taskTypes'
import { getConfigParam, getEmployeeParams, getUserInfo, getUserPermissions } from '../../../utils/reducers/getters'
import { addBottomNavAppElement, changeErrorMessage } from '../../../utils/reducers/reduxDispatch'
import SectionCEContactPerson from './SectionCEContactPerson'
import SectionCEWorkingCenter from './SectionCEWorkingCenter'
import SectionCustomerTasks from './SectionCustomerTasks'
import { ModalCreateTaskWithHelperData } from './types'
import { TaskHelperContactPersonSelectorParent, TaskHelperCustomerProjectSelectorParent, TaskHelperCustomerShopSelectorParent } from './TaskCreateTaskWithHelperSelectors'
import { EmployeesPayload } from '../../../utils/reducers/company/employees'
import { GroupPayload } from '../../../utils/reducers/groups/groups'
import useIsLoading from '../../../utils/hooks/useIsLoading'
import { useNavigate } from 'react-router'
import { sortEmployeesByUser } from '../../../utils/globals/employees'
import { sortGroupsByUser } from '../../../utils/globals/groups'
import './modalCreateTaskWithHelper.sass'

type ModalCreateTaskWithHelperProps = ModalEditComponentProps & ModalCreateTaskWithHelperLogicProps & ReduxTaskHelpers & {}

const ModalCreateTaskWithHelper: React.FC<ModalCreateTaskWithHelperProps> = ({ allRowData, taskHelpers, taskTypes, setOpened, employees, groups}) => {
    const { t } = useTwinTranslation()
    const { handleAddCustomerProject, handleBeforeSubmit, changeField, type, setType, returnModal, dataTask, setDataTask, getCustomerProjectTaskTypeInstance, myOnSubmit, loading, selectedTaskId, setSelectedTaskId } = useModalCreateTaskWithHelperLogic({ allRowData, taskHelpers, taskTypes, setOpened })

    const selectedElements = []
    const myTaskTypes = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(taskTypes))), [taskTypes])
    const myEmployees = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(employees || {}))), [employees])
    const myGroups = useMemo(() => addDictionaryExtraLine(JSON.parse(JSON.stringify(groups || {}))), [groups])

    const allElements: TwinDictionary = {
        name: {
            name: 'name',
            label: t('title', 'Título'),
            component: 'TextArea',
            required: true,
            value: dataTask?.name,
            onChange: (value: string) => changeField(value, 'name'),
        },
        description: {
            name: 'description',
            label: t('description', 'Descripción'),
            component: 'TextArea',
            value: dataTask?.description,
            onChange: (value: string) => changeField(value, 'description'),
        },
        CustomerProjectId: {
            name: 'CustomerProjectId',
            component: 'Custom',
            render: ({ extraProps }: TwinDictionary) => <TaskHelperCustomerProjectSelectorParent extraProps={extraProps} handleAddCustomerProject={handleAddCustomerProject} handleOpenModalBig={setType}/>,
            extraProps: { customerProjectId: dataTask.CustomerProjectId, customerId: dataTask.CustomerId },
        },
        EmployeeId: {
            name: 'EmployeeId',
            label: t('employee', 'Empleado'),
            component: 'CustomSelectWithSearchBar',
            items: myEmployees || {},
            fieldName: 'fullname_short',
            sortFunction: sortEmployeesByUser,
            value: dataTask?.EmployeeId,
            onChange: (value: number) => changeField(value, 'EmployeeId'),
        },
        GroupId: {
            name: 'GroupId',
            label: t('group', 'Grupo'),
            component: 'CustomSelectWithSearchBar',
            items: myGroups || {},
            sortFunction: sortGroupsByUser,
            value: dataTask?.GroupId,
            onChange: (value: number) => changeField(value, 'GroupId'),
        },
        CustomerShopId: {
            name: 'CustomerShopId',
            component: 'Custom',
            render: ({ extraProps }: TwinDictionary) => <TaskHelperCustomerShopSelectorParent extraProps={extraProps} dataTask={dataTask} setDataTask={setDataTask} handleOpenModalBig={setType} />,
            extraProps: { customerProjectId: dataTask.CustomerProjectId, customerShops: dataTask.CustomerShops, CustomerShopId: dataTask.CustomerShopId },
        },
        CustomerContactPersonId: {
            name: 'CustomerContactPersonId',
            component: 'Custom',
            render: ({ extraProps }: TwinDictionary) => <TaskHelperContactPersonSelectorParent extraProps={extraProps} dataTask={dataTask} setDataTask={setDataTask} handleOpenModalBig={setType} />,
            extraProps: { customerProjectId: dataTask.CustomerProjectId, customerContactPersons: dataTask.CustomerContactPersons, CustomerContactPersonId: dataTask.CustomerContactPersonId }
        },
        TaskTypeId: {
            name: 'TaskTypeId',
            label: t('taskType', 'Tipo de tarea'),
            component: 'CustomSelect',
            items: myTaskTypes || {},
            onChange: (value: string) => changeField(value, 'taskTypeId'),
            value: dataTask.taskTypeId,
            sortItems: true
        },
        start_date: {
            name: 'start_date',
            component: 'InputCalendarWithHoursStateLess',
            label: t('dateStart', 'Fecha inicio'),
            value: dataTask.dateStart || '',
            onChange: (val: any) => changeField(val, 'dateStart'),
            onDeleteDate: () => changeField('', 'dateStart' )
        },
        end_date: {
            name: 'end_date',
            component: 'InputCalendarWithHoursStateLess',
            label: t('dateEnd', 'Fecha fin'),
            value: dataTask.dateEnd || '',
            minDate: dataTask.dateStart || '',
            onChange: (val: any) => changeField(val, 'dateEnd'),
            onDeleteDate: () => changeField('', 'dateEnd')
        }
    }
    if (dataTask.taskHelperId !== 0) {
        if (taskHelpers?.[dataTask.taskHelperId].fields) {
            selectedElements.push({
                label: t('taskHelper', 'Ayudante de tareas'),
                component: 'CustomSelect',
                items: taskHelpers || {},
                onChange: (value: number) => changeField(value, 'taskHelperId'),
                value: dataTask.taskHelperId
            })
            for (const element of taskHelpers?.[dataTask.taskHelperId].fields) {
                if (allElements?.[element]) {
                    selectedElements.push(allElements[element])
                }
            }
            const inputHiddenStart = {
                name: 'start_date',
                component: 'InputHidden',
                value: dataTask.dateStart
            }
            const inputHiddenEnd = {
                name: 'end_date',
                component: 'InputHidden',
                value: dataTask.dateEnd
            }
            const isStartDateIncluded = taskHelpers?.[dataTask.taskHelperId].fields.includes('start_date')
            const isEndDateIncluded = taskHelpers?.[dataTask.taskHelperId].fields.includes('end_date')
    
            if (!isStartDateIncluded) {
                selectedElements.push(inputHiddenStart)
            }

            if (!isEndDateIncluded) {
                selectedElements.push(inputHiddenEnd)
            }
        }
    } else {
        for (const key in allElements) {
            selectedElements.push(allElements[key])
        }
    }

    const fields: FormRender<TaskModelType> = [
        {
            cols: 1,
            elements: selectedElements,
            className: 'gap-y-8'
        }
    ]
    const workingCenterPermission = getUserPermissions('customer.profile.shops')
    const contactPersonPermission = getUserPermissions('customer.profile.contactPerson')
    const dictTaskSection: TwinDictionary = {
        'newWorkingCenter': <SectionCEWorkingCenter setDataTask={setDataTask} dataTask={dataTask} type={type || ''} setType={setType}  getCustomerProjectTaskTypeInstance={getCustomerProjectTaskTypeInstance} userPermissions={workingCenterPermission || undefined} />,
        'editWorkingCenter': <SectionCEWorkingCenter setDataTask={setDataTask} dataTask={dataTask} type={type || ''} setType={setType}  getCustomerProjectTaskTypeInstance={getCustomerProjectTaskTypeInstance} userPermissions={workingCenterPermission || undefined} />,
        'newContactPerson': <SectionCEContactPerson  setDataTask={setDataTask} dataTask={dataTask} type={type || ''} setType={setType} getCustomerProjectTaskTypeInstance={getCustomerProjectTaskTypeInstance} userPermissions={contactPersonPermission || undefined} />,
        'editContactPerson': <SectionCEContactPerson  setDataTask={setDataTask} dataTask={dataTask} type={type || ''} setType={setType} getCustomerProjectTaskTypeInstance={getCustomerProjectTaskTypeInstance} userPermissions={contactPersonPermission || undefined} />,
        'customerTask': <SectionCustomerTasks CustomerId={dataTask.CustomerId} selectedTaskId={selectedTaskId} setSelectedTaskId={setSelectedTaskId} />
    }

    const gridCols = type ? 'grid-cols-5' : 'grid-cols-1'
    const secondDivClass = type ? ('border-l border-gray-DD pl-30 ' + returnClassOfSpanTemplate(2)) : ''

    return (
        <ModalWithCloseButton size={type ? 'modal_extrabig' : 'modal_little'} opened={true} setOpened={setOpened} className='text-gray-51 flex flex-col task_create_withhelper' onClickOut={false} showCloseButton={type ? true : false} setOpenedReturn={returnModal}>
            <div className={'grid flex-auto ' + gridCols}>
                {type ? 
                    <div className={'flex flex-col ' + returnClassOfSpanTemplate(3)}>
                        {dictTaskSection[type]}
                    </div>
                : null}
                <TwinForm className={'flex flex-col flex-auto task_helper_form ' + secondDivClass} action='/api/app/task/createTask' beforeSubmitHandler={handleBeforeSubmit} onSubmit={myOnSubmit}>
                    <ModalCreateEditStructureLayout loading={loading} translations={{
                        title: t('createTask', 'Crear tarea'),
                        button: t('createTask', 'Crear tarea')
                    }} haveButtonPermissions={true} className='modal_negative_margin'>
                        <div className='overflow-auto flex flex-auto flex-col'>
                            {fields.length ?
                                <FormRenderer sections={fields} />
                                : null}
                        </div>
                    </ModalCreateEditStructureLayout>
                </TwinForm>
            </div>
        </ModalWithCloseButton>)
}

interface ModalCreateTaskWithHelperLogicProps {
    allRowData?: RowData
    taskHelpers: TaskHelpersPayload
    taskTypes: TaskTypePayload
    setOpened: any
}

const useModalCreateTaskWithHelperLogic = ({ allRowData, taskHelpers, taskTypes, setOpened }: ModalCreateTaskWithHelperLogicProps) => {
    const { t } = useTwinTranslation()
    const [type, setType] = useState<string | null>(null)
    const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null)
    const { endLoading, loading, startLoading } = useIsLoading()
    const navigate = useNavigate()
    const messageEmployeeNotWorking = t('employeeNotWorkingThatDay', 'El empleado seleccionado no trabaja ese día')

    const [dataTask, setDataTask] = useState<ModalCreateTaskWithHelperData>(() => {
        let taskHelperId = 0
        if (taskHelpers && Object.keys(taskHelpers).length > 0) {
            const arrKeys = Object.keys(taskHelpers)
            taskHelperId = parseInt(arrKeys[0])
        } else {
            taskHelperId = 0
        }
        return {
            name: '',
            description: '',
            taskTypeId: '',
            taskHelperId,
            dateStart: allRowData?.start || '',
            dateEnd: allRowData?.end || '',
            CustomerProjectId: '',
            CustomerShops: {},
            CustomerContactPersons: {},
            CustomerId: ''
        }
    })

    const error = t('wrongDateRange', 'El rango de fecha es incorrecto')

    const handleBeforeSubmit: BeforeSubmitHandler = useCallback((values: any) => {
        startLoading()
        const startDateParsed = new Date(values.start_date)
        const endDateParsed = new Date(values.end_date)
        if (endDateParsed < startDateParsed) {
            changeErrorMessage(error)
            endLoading()
            return false
        }
        const user = getUserInfo()
        const workingCenterByDefault = getConfigParam('assignTaskYourWorkingCenterByDefault')
        if (workingCenterByDefault && workingCenterByDefault === 'on') {
            values.WorkingCenterId = user?.WorkingCenterId
        }
        return values
    }, [error, startLoading, endLoading])

    const changeField = useCallback((value: any, field: keyof ModalCreateTaskWithHelperData) => {
        setDataTask((old) => {
            if (!old) {
                return old
            }

            if (field === 'dateStart') {
                if (value === '') {
                    return { ...old, dateStart: '', dateEnd: '' }
                }
                value.setHours(value?.getHours() || 9)
                value.setMinutes(value?.getMinutes() || 0)
                value.setSeconds(0)
                value.setMilliseconds(0)
                // @ts-ignore
                const timeDifference = (value - old.dateStart) / 1000 / 60
                const updatedEnd = moment(old.dateEnd ? old.dateEnd : value)
                if (old.dateStart) {
                    updatedEnd.add(timeDifference, 'minutes')
                } else {
                    updatedEnd.add(60, 'minutes')
                }
                return { ...old, dateStart: value, dateEnd: updatedEnd.toDate() }
            }

            if (field === 'dateEnd' && !old.dateStart) {
                if (value === '') {
                    return { ...old, dateEnd: '' }
                }
                value.setHours(value?.getHours() || 10)
                value.setMinutes(value?.getMinutes() || 0)
                value.setSeconds(0)
                value.setMilliseconds(0)
                const updatedEnd = moment(value)
                updatedEnd.add(-60, 'minutes')
                return { ...old, dateEnd: value, dateStart: updatedEnd.toDate() }
            }

            if (field === 'taskTypeId') {
                const timeDifference = (taskTypes?.[parseInt(value)]?.defaultMins || 0)
                let dateEnd: string | Date = old.dateEnd
                if (old.dateStart) {
                    const updatedEnd = moment(old.dateStart)
                    updatedEnd.add(timeDifference, 'minutes')
                    dateEnd = updatedEnd.toDate()
                }
                return { ...old, dateEnd }
            }

            return { ...old, [field]: value }
        })
    }, [setDataTask, taskTypes])

    const getCustomerProjectTaskTypeInstance = useCallback(async (id: number) => {
        const result = await twinFetchPostJSON('/api/app/customer/customerProject/getCustomerProjectInstance', {
            id
        })
        if (result) {
            setDataTask((old) => {
                return { ...old, taskTypeId: result.CustomerProjectType.TaskTypeId, CustomerProjectId: String(id), CustomerId: String(result.CustomerId), CustomerShops: dictionaryComplexFromJsonArr(result.Customer.CustomerShops), CustomerContactPersons: dictionaryComplexFromJsonArr(result.Customer.CustomerContactPersons) }
            })
        }
    }, [setDataTask])

    const myOnSubmit: OnSubmit = useCallback(async (res, values) => {
        const parameters = getEmployeeParams()
        const floatingTasks = parameters?.['floatingTasks'] === 'on' ? true : false
        if (res.status === 200) {
            const val = await res.json()
            const id = val?.id
            if (val.error === 'Employee is not working that day') {
                await changeErrorMessage(messageEmployeeNotWorking)
            }
            if (id) {
                if (floatingTasks) {
                    addBottomNavAppElement({ type: 'taskModal', key: 'taskModal-' + id, extraData: { id, opened: true } })
                } else {
                    navigate('/task/tasks/' + id)
                }
                setOpened(null)
            }
        }
        endLoading()
    }, [endLoading, setOpened, navigate, messageEmployeeNotWorking])


    const handleAddCustomerProject = useCallback(async (CustomerProjectId: number | null) => {
        if (CustomerProjectId) {
            await getCustomerProjectTaskTypeInstance(CustomerProjectId)
            setType(null)
        } else {
            setDataTask((old) => {
                return { ...old, taskTypeId: '', CustomerProjectId: '', CustomerShops: {}, CustomerContactPersons: {}, CustomerId: '', CustomerShopId: undefined, CustomerContactPersonId: undefined }
            })
            setType(null)
            setSelectedTaskId(null)
        }
        return true
    }, [getCustomerProjectTaskTypeInstance, setDataTask, setType])

    const returnModal = useCallback(() => {
        if (selectedTaskId) {
            setType('customerTask')
            setSelectedTaskId(null)
        }else if (type) {
            setType(null)
        } else {
            setOpened(null)
        }
    }, [type, setType, setOpened, selectedTaskId, setSelectedTaskId])

    return { handleAddCustomerProject, handleBeforeSubmit, taskTypes, dataTask, changeField, type, setType, returnModal, setDataTask, getCustomerProjectTaskTypeInstance, myOnSubmit, loading, selectedTaskId, setSelectedTaskId }
}

const taskHelpersDispatch = {
    setTaskHelpers: (payload: TaskHelpersPayload) => ({ type: 'CHANGE_TASKHELPERS', payload }),
    setTaskTypes: (payload: TaskTypePayload) => ({ type: 'CHANGE_TASKTYPE', payload }),
    setEmployees: (payload: EmployeesPayload) => ({ type: 'CHANGE_EMPLOYEE', payload }),
    setGroups: (payload: GroupPayload) => ({ type: 'CHANGE_GROUP', payload }),
}

export type ReduxTaskHelpers = ConnectedProps<typeof taskHelpersConnect>
const mapTaskHelpersConnector = (state: AllReduxPayloads) => ({ taskHelpers: state.taskHelpers, employees: state.employees, groups: state.groups, taskTypes: state.taskTypes })
const taskHelpersConnect = connect(mapTaskHelpersConnector, taskHelpersDispatch)

const taskHelpersConnectLoading = withLoading(ModalCreateTaskWithHelper, [{ fetchUrl: '/api/app/task/helper/getAllTaskHelpers', propName: 'taskHelpers', setFunctionName: 'setTaskHelpers' }, { fetchUrl: '/api/app/employee/getAllEmployees', propName: 'employees', setFunctionName: 'setEmployees' }, { fetchUrl: '/api/app/group/getAllGroupsListing', propName: 'groups', setFunctionName: 'setGroups' }, { fetchUrl: '/api/app/task/taskType/getAllTaskTypes', propName: 'taskTypes', setFunctionName: 'setTaskTypes' }])


const ModalCreateTaskWithHelperConnect = taskHelpersConnect(taskHelpersConnectLoading)

export default ModalCreateTaskWithHelperConnect