import { memo, useCallback, useState } from 'react';
import NullComponent from '../../baseComponents/NullComponent';
import { memoChecker } from '../../utils/globals/components';
import { returnClassOfGridTemplate } from '../../utils/globals/tailwind';
import { getValueForElement } from '../../utils/globals/valueOrDefaultValue';
import { addAntiUnsaveElement, deleteAntiUnsaveElement } from '../../utils/reducers/reduxDispatch';
import Checkbox, { CheckboxMargin } from '../Checkbox';
import CustomSelect, { CustomSelectBase, CustomSelectWithNotInFilteredItems, CustomSelectWithSearchBar } from '../CustomSelect';
import ImageInput from '../ImageInput';
import MultipleCheckboxSelector, { MultipleCheckboxSelectorStateLess } from '../MultipleCheckboxSelector';
import { InputDefault, InputHidden, InputNumberWithLabelMargin, InputNumberWithLabelMarginStateFull, InputNumberWithLabelMarginTextRight, InputWithLabelMargin, InputWithLabelMarginHalfFloatStateFull, InputWithLabelMarginHalfFloatStateLess, InputWithLabelMarginIntegerStateFull, InputWithLabelMarginIntegerStateLess, InputWithLabelMarginRemove, InputWithLeftIcon } from '../Input';
import TwinEditor from '../TwinEditor';
import { AllFormRenderComponents, AllSectionsClassNameType, FormElement, FormRendererProps, FormSection } from './types';
import { ChangeValueFunc } from '../../utils/globals/types';
import CustomSelectSections, { CustomSelectSectionsSearchbar } from '../CustomSelect/CustomSelectSections';
import CustomSelectBlockedItems, { CustomSelectBlockedItemsSearchbar } from '../CustomSelect/CustomSelectBlockedItems';
import InputSearchProduct from '../Input/InputSearchProduct';
import { InputCalendarStateFull } from '../Input/InputCalendar';
import { ListAdderStateFull, ListAdderStateLess } from '../ListAdder';
import TextArea from '../Input/TextArea';
import InputColor, { InputColorWithMargin } from '../Input/InputColor';
import InputPassword from '../Input/InputPassword';
import InputHour from '../Input/InputHour';
import InputHourUnlimited from '../Input/InputHour/InputHourUnlimited';
import { TextField } from '../TextField';
import InputIcon, { InputIconWithMargin } from '../Input/InputIcon';
import FilesInput from '../FilesInput';
import { InputCalendarWithHoursStateFull, InputCalendarWithHoursStateLess } from '../Input/InputCalendarWithHours';

import { InputCalendarWithStartEndHoursStateLess } from '../../specificComponents/Task/InputCalendarWithStartEndHours'
import { InputHourMinSecUnlimitedStateFull, InputHourMinSecUnlimitedStateLess } from '../Input/InputHourMinSec/InputHourMinSecUnlimited';
import { InputHourMinSecStateFull, InputHourMinSecStateLess } from '../Input/InputHourMinSec';
import InputCurrency from '../Input/InputCurrency';
import InputCurrencyStateFull from '../Input/InputCurrency/InputCurrencyStateFull';
import InputPrice, { InputPriceStateFull } from '../Input/InputPrice';
import { DisplayPriceField } from '../DisplayPriceField';
import './formRenderer.sass'

const FormRenderer: React.FC<FormRendererProps> = ({ className, children, ...props }) => {
    const {sectionsRendered} = useSectionsRendererLogic(props)
    return (
        <div className={'grid grid-cols-1 gap-y-38 form-renderer flex-auto ' + (className || '')}>
            {sectionsRendered}
            {children}
        </div>
    );
}

const useSectionsRendererLogic = (props: FormRendererProps) => {
    const { sections, allSectionsClassName, allSectionsInnerClassName } = props
    const sectionsRendered: JSX.Element[] = []

    for (const sectionKey in sections ) {
        const section = sections[sectionKey]
        const sectionChilds = renderSectionChilds(section.elements as any, props.antiUnsaveTrigger)
        sectionsRendered.push(
            <FormRendererSection key={sectionKey} section={section} allSectionsClassName={allSectionsClassName} allSectionsInnerClassName={allSectionsInnerClassName} >
                {sectionChilds}
            </FormRendererSection>
        )
    }
        
    return {sectionsRendered}
}
type FormRendererSectionProps = AllSectionsClassNameType & {
    section: FormSection
    children?: React.ReactNode
};

const FormRendererSection: React.FC<FormRendererSectionProps> = ({section, allSectionsClassName, allSectionsInnerClassName, children}) => {
    const gridCols = returnClassOfGridTemplate(section.cols)
    return (
        <div className={allSectionsClassName || ''}>
            {section.title && <div className='regular18 mb-17'>{section.title}</div>}
            <div className={'gap-x-60 gap-y-19 grid ' + gridCols + ' ' + (section.className || '') + ' ' + (allSectionsInnerClassName || '') }>{children}</div>
        </div>
    );
}

const renderSectionChilds = (sectionElements: FormElement[], antiUnsaveTrigger?: boolean) => {
    const sectionElementsRenderd: JSX.Element[] = []
    for (const sectionElement of sectionElements) {
        sectionElementsRenderd.push(<SingleElementsRenderer component={sectionElement.component} params={sectionElement} antiUnsaveTrigger={antiUnsaveTrigger} key={sectionElement.name} />)
    }
    return sectionElementsRenderd
}

export type FormRendererSingleElements = Record<AllFormRenderComponents, React.FC<any>>

const elements : FormRendererSingleElements = {
    Checkbox: Checkbox,
    CheckboxMargin: CheckboxMargin,
    Custom: NullComponent,
    CustomSelect: CustomSelect,
    CustomSelectBase: CustomSelectBase,
    CustomSelectBlockedItems: CustomSelectBlockedItems,
    CustomSelectBlockedItemsSearchbar: CustomSelectBlockedItemsSearchbar,
    CustomSelectSections: CustomSelectSections,
    CustomSelectSectionsSearchbar: CustomSelectSectionsSearchbar,
    CustomSelectWithNotInFilteredItems: CustomSelectWithNotInFilteredItems,
    CustomSelectWithSearchBar: CustomSelectWithSearchBar,
    DisplayPriceField: DisplayPriceField,
    FilesInput: FilesInput,
    ImageInput: ImageInput,
    Input: InputDefault,
    InputCalendarStateFull: InputCalendarStateFull,
    InputCalendarWithHoursStateLess: InputCalendarWithHoursStateLess,
    InputCalendarWithHoursStateFull: InputCalendarWithHoursStateFull,
    InputCalendarWithStartEndHoursStateLess: InputCalendarWithStartEndHoursStateLess,
    InputColor: InputColor,
    InputColorWithMargin: InputColorWithMargin,
    InputCurrency: InputCurrency,
    InputCurrencyStateFull: InputCurrencyStateFull,
    InputHidden: InputHidden,
    InputHour: InputHour,
    InputHourUnlimited: InputHourUnlimited,
    InputHourMinSecStateFull: InputHourMinSecStateFull,
    InputHourMinSecStateLess: InputHourMinSecStateLess,
    InputHourMinSecUnlimitedStateFull: InputHourMinSecUnlimitedStateFull,
    InputHourMinSecUnlimitedStateLess: InputHourMinSecUnlimitedStateLess,
    InputIcon: InputIcon,
    InputIconWithMargin: InputIconWithMargin,
    InputNumberWithLabelMargin: InputNumberWithLabelMargin,
    InputNumberWithLabelMarginStateFull: InputNumberWithLabelMarginStateFull,
    InputNumberWithLabelMarginTextRight: InputNumberWithLabelMarginTextRight,
    InputPassword: InputPassword,
    InputPrice: InputPrice,
    InputPriceStateFull: InputPriceStateFull,
    InputSearchProduct: InputSearchProduct,
    InputWithLabelMargin: InputWithLabelMargin,
    InputWithLabelMarginIntegerStateFull: InputWithLabelMarginIntegerStateFull,
    InputWithLabelMarginIntegerStateLess: InputWithLabelMarginIntegerStateLess,
    InputWithLabelMarginHalfFloatStateFull: InputWithLabelMarginHalfFloatStateFull,
    InputWithLabelMarginHalfFloatStateLess: InputWithLabelMarginHalfFloatStateLess,
    InputWithLabelMarginRemove: InputWithLabelMarginRemove,
    InputWithLeftIcon: InputWithLeftIcon,
    ListAdderStateFull: ListAdderStateFull,
    ListAdderStateLess: ListAdderStateLess,
    MultipleCheckboxSelector: MultipleCheckboxSelector,
    MultipleCheckboxSelectorStateLess: MultipleCheckboxSelectorStateLess,
    TextArea: TextArea,
    TextField: TextField,
    TwinEditor: TwinEditor,
}

interface SingleElementsRendererProps extends SingleElementsRendererLogicProps  {
    component: keyof FormRendererSingleElements
}

export const SingleElementsRenderer: React.FC<SingleElementsRendererProps> = memo(({component, params, antiUnsaveTrigger}) => {
    const { onChange, onFinalChange } = useSingleElementsRendererLogic({ params, antiUnsaveTrigger })
    const MyComponent = params.component === 'Custom' ? params.render : elements[component]
    return <MyComponent key={params.name} {...params} onChange={onChange} onFinalChange={onFinalChange} />
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['component', 'params', 'antiUnsaveTrigger']))

interface SingleElementsRendererLogicProps {
    params: FormElement
    antiUnsaveTrigger?: boolean
}

const useSingleElementsRendererLogic = ({ params, antiUnsaveTrigger }: SingleElementsRendererLogicProps) => {
    const [initialValue] = useState(String(getValueForElement(params.component, params as any)) || '')
    const name = params.name
    const paramsOnChange = params.onChange
    const paramsOnFinalChange = (params as any).onFinalChange
    const onChange: ChangeValueFunc = useCallback(async (value) => {
        paramsOnChange?.(value)
        if(String(value) === '' && initialValue === 'undefined'){
            await deleteAntiUnsaveElement(name)
        }else if (String(value) !== initialValue) {
            await addAntiUnsaveElement([name, value])
        } else {
            await deleteAntiUnsaveElement(name)
        }
    }, [initialValue, name, paramsOnChange])
    const onFinalChange: ChangeValueFunc = useCallback(async (value) => {
        paramsOnFinalChange?.(value)
        if(String(value) === '' && initialValue === 'undefined'){
            await deleteAntiUnsaveElement(name)
        }else if (String(value) !== initialValue) {
            await addAntiUnsaveElement([name, value])
        } else {
            await deleteAntiUnsaveElement(name)
        }
    }, [initialValue, name, paramsOnFinalChange])
    return { onChange: antiUnsaveTrigger ? onChange : params.onChange, onFinalChange: antiUnsaveTrigger ? onFinalChange : paramsOnFinalChange }
}


export default FormRenderer;