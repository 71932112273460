import { UserModelType } from '@teinor/erp/types/environment/user'
import { ButtonOrLoader } from '../../../../baseComponents/Button'
import TwinTrans from '../../../../baseComponents/TwinTrans'
import FormRenderer from '../../../../forms/FormRenderer'
import { FormRender } from '../../../../forms/FormRenderer/types'
import { faLockKeyhole } from '@fortawesome/pro-light-svg-icons'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import TwinForm from '../../../../forms/TwinForm'
import useIsLoading from '../../../../utils/hooks/useIsLoading'

interface FixWCalProps { }

const FixWCal: React.FC<FixWCalProps> = () => {
    const { startLoading, endLoading, loading } = useIsLoading()
    const { t } = useTwinTranslation()
    const fields: FormRender<UserModelType> = [
        {
            cols: 1,
            elements: [

                {
                    name: 'password',
                    placeholder: t('password', 'Contraseña'),
                    component: 'InputPassword',
                    required: true,
                    icon: faLockKeyhole
                }
            ]
        }
    ]
    return (
        <div className='flex flex-col flex-auto px-40 py-50 bg-white items-center'>
            <h1>
                <TwinTrans transKey='fixWCals'>Arreglar calendarios laborales</TwinTrans>
            </h1>
            <span className='my-40 regular14'>
                <TwinTrans transKey='introducePassToFixWCal'>Introduce la contraseña para que tus calendarios laborales estén disponibles desde principio del año</TwinTrans>
            </span>
            <TwinForm action='/api/app/workingCalendar/fixWCal' onSubmit={endLoading} beforeSubmitHandler={startLoading} onError={endLoading}>
                <FormRenderer sections={fields} />
                <ButtonOrLoader buttonIsDisabled={loading} className='mt-40 mx-auto' textButton={t('send', 'Enviar')} />
            </TwinForm>
        </div>
    )
}


export default FixWCal