import React, { useCallback, useMemo } from 'react'
import { faCloudArrowDown, faEdit, faTrashCan } from '@fortawesome/pro-light-svg-icons'
import { getCountryNameById, getDictionaryStates } from '../../../../utils/globals/countries'
import { TwinDictionary } from '../../../../utils/globals/dictionary'
import TwinIcon, { TwinIconProp } from '../../../TwinIcon'
import { RowData } from '../../types'
import { ParameterValueType } from '../Subcomponents/types'
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { displayDate, displayDateHours, displayDateTime, displayFormatedHourFromMins, displayFormatedHourMinSecFromSecs } from '../../../../utils/globals/date'
import { getAllLangs } from '../../../../utils/reducers/getters'
import { nanoid } from 'nanoid'
import useTwinTranslation from '../../../../utils/hooks/useTwinTranslation'
import TwinToolTip from '../../../TwinToolTip'
import { getAllCurrencies } from '../../../../utils/reducers/getters'
import DisplayPrice from '../../../Displays/DisplayPrice'

interface CustomRenderCountryNameProps {
    countryId: ParameterValueType
}

export const CustomRenderCountryName: React.FC<CustomRenderCountryNameProps> = ({ countryId }) => {
    const countryName = getCountryNameById(countryId)
    return (
        <div>
            {countryName.name}
        </div>
    )
}

interface CustomRenderLanguageNameProps {
    languageId: ParameterValueType
}

export const CustomRenderLanguageName: React.FC<CustomRenderLanguageNameProps> = ({ languageId }) => {
    const languageName = getAllLangs()[languageId]?.name
    return (
        <div>
            {languageName}
        </div>
    )
}

interface CustomRenderStateNameProps {
    stateId: ParameterValueType
    allRowData: RowData
}

export const CustomRenderStateName: React.FC<CustomRenderStateNameProps> = ({ stateId, allRowData }) => {
    const states = getDictionaryStates(allRowData.country)
    const stateName = states[stateId]?.name
    return (
        <div>
            {stateName}
        </div>
    )
}
interface CustomRenderBooleanProps {
    value: boolean
    width?: number
    onClick?: () => void
    className?: string
}

export const CustomRenderBoolean: React.FC<CustomRenderBooleanProps> = ({ value, width, onClick, className }) => {
    let renderThis = <TwinIcon icon={faTimesCircle} className='text-red-BA' size='lg' />
    if (value) {
        renderThis = <TwinIcon icon={faCheckCircle} className='text-green-43' size='lg' />
    }
    return (
        <div className={'flex items-center w-full custom_render_boolean ' + (className|| '')} onClick={onClick}>
            <div className='text-center' style={{width: width ?? '100%'}}>
                {renderThis}
            </div>
        </div>
    )
}

interface CustomRenderBooleanTextProps {
    value: boolean
    width?: number
    title: {
        on: string
        off: string
    }
    onClick?: () => void
}

export const CustomRenderBooleanText: React.FC<CustomRenderBooleanTextProps> = ({ title, value, width, onClick }) => {
    let className = ' border-red-BA text-red-BA'
    let text = title['off']
    if (value) {
        className = 'border-green-43 text-green-43'
        text = title['on']
    }
    return (
        <div className='flex items-center w-full custom_render_boolean' onClick={onClick} style={{width: width ?? '100%'}}>
            <div className={'border rounded-5 px-10 py-1 text-center mx-auto ' + className} >
                {text}
            </div>
        </div>
    )
}

interface CustomRenderDateProps {
    value: string
    className?: string
}

export const CustomRenderDate: React.FC<CustomRenderDateProps> = ({ value, className }) => {
    const renderThis = value ? displayDate(new Date(value)) : ''
    return (
        <div className={'flex items-center w-full custom_render_date ' + (className || '')}>
            {renderThis}
        </div>
    )
}

interface CustomRenderDateHoursProps {
    value: string
}

export const CustomRenderDateHours: React.FC<CustomRenderDateHoursProps> = ({ value }) => {
    const renderThis = value ? displayDateTime(new Date(value)) : ''
    return (
        <div className='flex items-center w-full custom_render_date twin_elipsis'>
            {renderThis}
        </div>
    )
}
interface CustomRenderDateJustHoursProps {
    value: string
}

export const CustomRenderDateJustHours: React.FC<CustomRenderDateJustHoursProps> = ({ value }) => {
    const renderThis = value ? displayDateHours(new Date(value)) : ''
    return (
        <div className='flex items-center w-full custom_render_date'>
            {renderThis}
        </div>
    )
}
interface CustomRenderStringProps {
    value: string
    className?: string
}

export const CustomRenderString: React.FC<CustomRenderStringProps> = ({ value, className }) => {
    return (
        <div className={className}>
            {value}
        </div>
    )
}

interface CustomRenderStringAndColorProps {
    value: string
    color: string
    className?: string
}

export const CustomRenderStringAndColor: React.FC<CustomRenderStringAndColorProps> = ({ value, color, className }) => {
    return (
        <div className={'flex items-center ' + (className || '')}>
            <div className={'rounded-full h-10 w-10 mr-10 border border-gray-D6 '} style={{ backgroundColor: color }} />
            <div>{value}</div>
        </div>
    )
}
interface CustomRenderImageProps {
    value: string
}

export const CustomRenderImage: React.FC<CustomRenderImageProps> = ({ value }) => {
    return (
        <img src={value} className={'custom_render_image'} alt=''/>
    )
}

interface CustomRenderColorProps {
    value: string
    className?: string
}

export const CustomRenderColor: React.FC<CustomRenderColorProps> = ({ value, className }) => {
    return (
        <div className={'rounded-full h-20 w-20 mr-10 border border-gray-D6 ' + (className || '')} style={{ backgroundColor: value }} />
    )
}

interface CustomRenderIconProps {
    icon: TwinIconProp
    className?: string
}

export const CustomRenderIcon: React.FC<CustomRenderIconProps> = ({ icon, className }) => {
    return (
        <div className={'h-20 w-20 mr-10 ' + (className || '')}>
            <TwinIcon icon={icon} className='w-20 h-20'/>
        </div>
    )
}

interface CustomRenderDisplayHoursByMinsProps {
    value: string
    className?: string
}

export const CustomRenderDisplayHoursByMins: React.FC<CustomRenderDisplayHoursByMinsProps> = ({ value, className }) => {
    return (
        <span className={className}>{displayFormatedHourFromMins(parseInt(value))}</span>
    )
}

interface CustomRenderDisplayHoursMinsSecsBySecsProps {
    value: number
    className?: string
}

export const CustomRenderDisplayHoursMinsSecsBySecs: React.FC<CustomRenderDisplayHoursMinsSecsBySecsProps> = ({ value, className }) => {
    return (
        <span className={className}>{displayFormatedHourMinSecFromSecs(value)}</span>
    )
}

interface CustomRenderProfileImageProps {
    value: string
    name?: string
}

export const CustomRenderProfileImage: React.FC<CustomRenderProfileImageProps> = ({ value, name }) => {
    return (
        <img src={value} className={'custom_render_image'} alt={name} />
    )
}

export interface CustomRenderRoundedImageProps {
    img?: string
    name?: string
    className?: string
    icon: TwinIconProp
}

export const CustomRenderRoundedImage: React.FC<CustomRenderRoundedImageProps> = ({ name, img, className, icon }) => {
    const idTooltip = useMemo(() => 'Tooltip_CustomRenderRoundedImage' + nanoid(), [])
    const {t} = useTwinTranslation()
    const notSelected = t('notSelected', 'No seleccionado')
    return (
        <div className={' text-gray-63 border rounded-full items-center justify-center flex ' + (!img ? 'border-gray-C7 ':' border-none ') + (className || '')} >
            <TwinToolTip id={idTooltip} place='top' content={name || notSelected} />
            {img ? <img data-tooltip-id={idTooltip} src={img} alt={name} className='w-full h-full rounded-full' /> : <TwinIcon data-tooltip-id={idTooltip} icon={icon} />}
        </div>
    )
}

interface DefaultTableCustomRenderProps {
    items: TwinDictionary
    value: string | string[]
}


export const DefaultTableCustomRender: React.FC<DefaultTableCustomRenderProps> = ({ items, value }) => {
    let renderThis: string
    if (Array.isArray(value)) {
        let stringArr: any = []
        for (const element of value) {
               stringArr.push(items[element])
        }
        renderThis = stringArr.join(', ')
    } else {
        renderThis = items[value]
    }
    return (
        <div>
            {renderThis}
        </div>
    )
}
type EditRowClick = (parameterValue: ParameterValueType, allRowData: RowData, e: React.MouseEvent) => void
interface EditRowProps {
    parameterValue: ParameterValueType
    allRowData: RowData
    onClick?: EditRowClick
}
export const EditRow: React.FC<EditRowProps> = ({...rest}) => {
    const {handleEdit} = useEditRowLogic(rest)
    return (
        <div className='flex justify-center items-center' onClick={handleEdit}>
            <TwinIcon icon={faEdit} className='cursor-pointer hover:text-green-43' size='lg' />
        </div>
    )
}

const useEditRowLogic = ({ parameterValue, allRowData, onClick }: EditRowProps) => {
    const handleEdit = useCallback((e: React.MouseEvent) => {
        onClick?.(parameterValue, allRowData, e)
    }, [onClick, parameterValue, allRowData])

    return { handleEdit }
}

export const EditRowNotLink: React.FC<EditRowProps> = ({ onClick, ...rest }) => {
    const { handleEdit } = useEditRowNotLink({ onClick, ...rest })
    return <EditRow onClick={handleEdit} {...rest}></EditRow>
}
const useEditRowNotLink = ({ onClick }: EditRowProps) => {
    const handleEdit = useCallback<EditRowClick>((parameterValue, allRowData, e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick?.(parameterValue, allRowData, e) 
    }, [onClick])

    return { handleEdit }
}
interface DeleteRowProps extends EditRowProps { }

export const DeleteRow: React.FC<DeleteRowProps> = ({...rest }) => {
    const { handleDelete } = useDeleteRowLogic(rest)
    if (rest.allRowData.BuyDeliveryNoteId && rest.allRowData.BuyInvoiceId) {
        return null
    }
    return (
        <div className='flex justify-center items-center' onClick={handleDelete}>
            <TwinIcon icon={faTrashCan} className='cursor-pointer hover:text-red-BA' size='lg' />
        </div>
    )
}
interface DeleteRowProps extends EditRowProps { }

const useDeleteRowLogic = ({ parameterValue, allRowData, onClick }: DeleteRowProps) => {
    const handleDelete = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        onClick?.(parameterValue, allRowData, e)
    }, [onClick, parameterValue, allRowData])

    return { handleDelete }
}

interface CustomRenderCurrencyNameProps {
    currencyId: ParameterValueType
}

export const CustomRenderCurrencyName: React.FC<CustomRenderCurrencyNameProps> = ({ currencyId }) => {
    const currencyName = getAllCurrencies()[currencyId]
    return (
        <div>
            {currencyName.name}
        </div>
    )
}

interface CustomRenderDownloadFileProps {
    url: string
}

export const CustomRenderDownloadFile: React.FC<CustomRenderDownloadFileProps> = ({ url }) => {
    if (!url) {
        return null
    } else {
        return (
            <a href={url} target='_blank' rel="noreferrer">
                <TwinIcon icon={faCloudArrowDown} className='cursor-pointer hover:text-green-43' size='lg' />
            </a>
        )
    }
}

interface CustomRenderDisplayPriceWOParamsProps {
    value: string
    CurrencyId: string
}

export const CustomRenderDisplayPriceWOParams: React.FC<CustomRenderDisplayPriceWOParamsProps> = ({ value, CurrencyId }) => {
    return (
        <DisplayPrice value={parseFloat(value)} CurrencyId={CurrencyId} with_vat={false} calculateParams={{
            decimals: 2,
            rounding: '0.01',
            roundingPriceType: 'medium'
        }} />
    )
}
