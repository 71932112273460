import { useCallback, useEffect, useState } from 'react'
import { ModalLittle } from '../../../../../../../../../../baseComponents/Modal'
import { ModalCreateEditStructureLayout } from '../../../../../../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalEditComponentProps } from '../../../../../../../../../../baseComponents/ModalsLayouts/types'
import FormRenderer from '../../../../../../../../../../forms/FormRenderer'
import { FormRender } from '../../../../../../../../../../forms/FormRenderer/types'
import { WCalHolidayUsedDayModelTypeExtended } from '../../../../../../../../../../specificComponents/WCal/WCalHolidayUsedDay/types'
import { twinFetchPostJSON } from '../../../../../../../../../../utils/globals/data'
import { displayDate } from '../../../../../../../../../../utils/globals/date'
import { valueOrDefaultValue } from '../../../../../../../../../../utils/globals/valueOrDefaultValue'
import useStatusRequestTypes from '../../../../../../../../../../utils/hooks/useStatusRequestTypes'
import useTwinTranslation from '../../../../../../../../../../utils/hooks/useTwinTranslation'
import CustomRenderWCalHalfDayForM from '../../../../../../../../../../specificComponents/WCal/WCalHolidayUsedDay/CustomRenderWCalHalfDay'

interface ModalUserNotificationsHolidayUsedDaysProps extends ModalUserNotificationsHolidayUsedDaysLogicProps {
}

const ModalUserNotificationsHolidayUsedDays: React.FC<ModalUserNotificationsHolidayUsedDaysProps> = ({ userPermissions, setOpened, allRowData, onSubmit }) => {
    const { t } = useTwinTranslation()
    const status = useStatusRequestTypes()
    const { data } = useModalUserNotificationsHolidayUsedDaysLogic({ allRowData, setOpened, onSubmit })

    const fields: FormRender<WCalHolidayUsedDayModelTypeExtended> = [
        {
            cols: 1,
            elements: [
                {
                    name: 'name',
                    component: 'TextField',
                    label: t('holidayBag', 'Bolsa de vacaciones'),
                    value: data?.WCalHolidayBag?.name,
                },
                {
                    name: 'EmployeeId',
                    component: 'TextField',
                    value: data?.WCalHolidayBag?.Employee?.fullname_short,
                    label: t('employee', 'Empleado')
                },
                {
                    name: 'from',
                    component: 'TextField',
                    label: t('from', 'Desde'),
                    value: displayDate(new Date(data?.from || ''))
                },
                {
                    name: 'to',
                    component: 'TextField',
                    label: t('to', 'Hasta'),
                    value: displayDate(new Date(data?.to || '')),
                },
                {
                    name: 'isHalfDay',
                    component: 'Custom',
                    extraProps: { value: data?.isHalfDay },
                    render: () => <CustomRenderWCalHalfDayForM value={data?.isHalfDay} />,
                },
                {
                    name: 'approved',
                    component: 'TextField',
                    label: t('status', 'Estado'),
                    value: status[parseInt(String(data?.approved))]?.name
                },
                {
                    name: 'approvedDate',
                    component: 'TextField',
                    label: t('approvedDate', 'Fecha de aprobación'),
                    value: data?.approvedDate ? displayDate(new Date(data?.approvedDate)) : ''
                },
                {
                    name: 'id',
                    component: 'InputHidden',
                    value: data?.id
                },
            ]
        },
    ]
    const userPermission = userPermissions ?? { n: 1, permission: 'read' }
    const parsedFields = valueOrDefaultValue(fields, userPermission, allRowData)
    return (
        <ModalLittle opened={true} setOpened={setOpened} onClickOut={false} className={'flex flex-col'}>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: t('notificationInfo', 'Información de la notificación'),
                button: ''
            }} haveButtonPermissions={false} >
                <div className='overflow-auto flex flex-auto flex-col'>
                    {fields.length ?
                        <FormRenderer sections={parsedFields} className='grid_mincontent' />
                    : null}
                </div>
            </ModalCreateEditStructureLayout>
        </ModalLittle>
    )
}

interface ModalUserNotificationsHolidayUsedDaysLogicProps extends ModalEditComponentProps {
}

const useModalUserNotificationsHolidayUsedDaysLogic = ({ allRowData }: ModalUserNotificationsHolidayUsedDaysLogicProps) => {
    const [data, setData] = useState<WCalHolidayUsedDayModelTypeExtended | null>(null)

    const getUsedDaysData = useCallback(async () => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeHoliday/getEmployeeHolidayBagUsedDayInstance', { id: allRowData?.SubId })
        if (result) {
            setData(result)
        }
    }, [setData, allRowData?.SubId])

    useEffect(() => {
        getUsedDaysData()
    }, [getUsedDaysData])
    return { data }
}

export default ModalUserNotificationsHolidayUsedDays