import { nanoid } from 'nanoid'
import React, { CSSProperties, Fragment, useCallback, useMemo, useRef, useState } from 'react'
import TwinIcon, { TwinIconProp } from '../../baseComponents/TwinIcon'
import { addClassName, removeClassName } from '../../utils/globals/components'
import {  inputFocusClass } from '../../utils/globals/tailwind'
import { Modify } from '../../utils/globals/types'
import { changeClassListForLabelUp, handleErrorsInput, returnClassByLabelUp, shouldBeLabelUp } from './functions'
import { CustomChangeFunction, InputChangeEvent, InputLabelLogic } from './types'
import './input.sass'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { checkFloatValid, checkHalfFloatValid, checkIntegerValid, displayFormatedNumber, getSeparatorsOfFormatedNumber } from '../../utils/globals/numbers'
import { faXmark } from '@fortawesome/pro-light-svg-icons'

type InputWithIconProps = InputProps & BaseInputWithIcon & {
    icon: TwinIconProp
    iconStyles?: CSSProperties
}
const DEFAULT_ICON_STYLES = { height: '19px', width: '19px' }

export interface InputWithLeftIconProps extends InputWithIconProps {}

export const InputWithLeftIcon: React.FC<InputWithLeftIconProps> = ({ icon, className, iconStyles = DEFAULT_ICON_STYLES, ...inputRest }) => {
    return (
        <InputWithIcon className={className}>
            <TwinIcon {...{ icon }} style={iconStyles} />
            <Input {...inputRest } className='flex-auto' />
        </InputWithIcon>
    )
}

export interface InputWithRightIconProps extends InputWithIconProps {}

export const InputWithRightIcon: React.FC<InputWithRightIconProps> = ({ icon, className, iconStyles = DEFAULT_ICON_STYLES, ...inputRest }) => {
    return (
        <InputWithIcon className={className}>
            <Input className='flex-auto w-1' {...inputRest } />
            <TwinIcon {...{ icon }} style={iconStyles} className='ml-auto' />
        </InputWithIcon>
    )
}

interface BaseInputWithIcon {
    className?: string
    children?: React.ReactNode
}

const InputWithIcon: React.FC<BaseInputWithIcon> = ({className, children}) => {
    return (
        <InputWrapper className={className}>
            <div className='input_with_icon flex items-center'>
                {children}
            </div>
        </InputWrapper>
    )
}

export type InputWithLabelProps = Modify<InputProps, {
    label: string
}>

export const InputWithLabel: React.FC<InputWithLabelProps> = ({ label, className, ...inputRest }) => {
    const id = useMemo(() => inputRest.id || nanoid(), [inputRest.id])
    const { labelUpClass, myref } = useInputLabelLogic({ value: inputRest.value || inputRest.defaultValue })
    return (
        <InputWrapper className={'input_field_with_label ' + (className || '')} >
            <Input className={labelUpClass} {... { id, myref, ...inputRest }} />
            <LabelRender {...{ id, label, required: inputRest.required }} />
        </InputWrapper>
    )
}
interface InputWithLabelIconProps extends InputWithLabelProps {
    icon: IconDefinition
    onClickIcon?: () => void
}
export const InputWithLabelIcon: React.FC<InputWithLabelIconProps> = ({ label, className, icon, onBlur, onFocus, onClickIcon, ...inputRest }) => {
    const id = useMemo(() => inputRest.id || nanoid(), [inputRest.id])
    const { labelUpClass, myref } = useInputLabelLogic({ value: inputRest.value || inputRest.defaultValue })
    const {myOnBlur, myOnFocus, myOnClickIcon} = useInputWithLabelIconLogic({onBlur, onFocus, onClickIcon})
    return (
        <InputWrapper className={'input_field_with_label ' + (className || '')} >
            <div className='flex items-end'>
                <div className='flex-auto flex flex-col'>
                    <Input className={labelUpClass + ' w-full'} {... { id, myref, ...inputRest }} onBlur={myOnBlur} onFocus={myOnFocus} />
                    <LabelRender {...{ id, label, required: inputRest.required }} />
                </div>
                <label htmlFor={id} className={'mb-3 grid ' + (inputRest.readOnly || !onClickIcon ? ' cursor-default' : ' cursor-pointer')}>
                    <TwinIcon icon={icon} onClick={myOnClickIcon} />
                </label>
            </div>
        </InputWrapper>
    )
}
interface InputWithLabelIconLogicProps {
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
    onClickIcon?: () => void
}

export const useInputWithLabelIconLogic = ({ onBlur, onFocus, onClickIcon }: InputWithLabelIconLogicProps) => {
    const myOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget
        const parentCurrentTarget = currentTarget?.parentElement?.parentElement
        handleErrorsInput(currentTarget, parentCurrentTarget!)
        removeClassName(inputFocusClass, parentCurrentTarget)
        onBlur?.(e)
    }, [onBlur])

    const myOnFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget
        const parentCurrentTarget = currentTarget?.parentElement?.parentElement
        addClassName(inputFocusClass, parentCurrentTarget)
        onFocus?.(e)
    }, [onFocus])

    const myOnClickIcon = useCallback((e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        onClickIcon?.()
    }, [onClickIcon])
    return { myOnBlur, myOnFocus, myOnClickIcon }
}

export const useInputLabelLogic = ({ value }: InputLabelLogic) => {
    const myref = useRef<any>(null)

    const getCurrent = useCallback(() => {
        return myref.current
    }, [myref])

    const labelUpClass = returnClassByLabelUp(shouldBeLabelUp(getCurrent(), value))
    return { labelUpClass, myref }
}

export type InputWithLabelMarginProps = InputWithLabelProps & {}

export const InputWithLabelMargin: React.FC<InputWithLabelMarginProps> = ({ className, ...rest  }) => {
    return (
        <div className={'input_field_label_margin ' + (className || '')}>
            <InputWithLabel {...rest}/>
        </div>
    )
}

export type InputWithLabelMarginIntegerStateFullProps = Modify<InputIntegerLogicProps & InputWithLabelMarginProps, {
    value?: string | number
    defaultValue?: string | number
}>

export const InputWithLabelMarginIntegerStateFull: React.FC<InputWithLabelMarginIntegerStateFullProps> = ({ ...rest }) => {
    const [value, setValue] = useState<string | number>(rest.defaultValue || rest.value || '')
    return (
        <InputWithLabelMarginIntegerStateLess  {...rest} value={value} onChange={setValue} />
    )
}

export type InputWithLabelMarginIntegerStateLessProps = InputIntegerLogicProps & InputWithLabelMarginProps & {}

export const InputWithLabelMarginIntegerStateLess: React.FC<InputWithLabelMarginIntegerStateLessProps> = ({ className, ...rest }) => {
    const { myOnChange, onKeyDown } = useInputIntegerLogic({...rest})
    return (
        <div className={'input_field_label_margin ' + (className || '')}>
            <InputWithLabel {...rest} onChange={myOnChange} onKeyDown={onKeyDown}/>
        </div>
    )
}

interface InputIntegerLogicProps extends InputProps {
    value: number | string
}

const useInputIntegerLogic = ({onChange, readOnly, value}: InputIntegerLogicProps) => {
    const myOnChange: CustomChangeFunction = useCallback((value, e) => {
        const validQuantity = checkIntegerValid(value)
        if (value === '' || validQuantity) {
            onChange?.(String(validQuantity), e)
        }
    }, [onChange])

    const onKeyDown: React.KeyboardEventHandler = useCallback((e) => {
        if (!readOnly) {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                let n = value === '' ? 0 : parseFloat(String(value))
                const changeNumberBy = e.shiftKey ? 10 : 1
                if (e.key === 'ArrowDown') {
                    n = n - changeNumberBy
                } else {
                    n = n + changeNumberBy
                }
                myOnChange(String(n), e as any)
            }
        }
    }, [value, myOnChange, readOnly])

    return { myOnChange, onKeyDown }
}

export type InputWithLabelMarginHalfFloatStateFullProps = Modify<InputIntegerLogicProps & InputWithLabelMarginProps, {
    value?: string | number
    defaultValue?: string | number
}>

export const InputWithLabelMarginHalfFloatStateFull: React.FC<InputWithLabelMarginHalfFloatStateFullProps> = ({ ...rest }) => {
    const [value, setValue] = useState<string | number>(rest.defaultValue || rest.value || '')
    return (
        <InputWithLabelMarginHalfFloatStateLess  {...rest} value={value} onChange={setValue} />
    )
}

export type InputWithLabelMarginHalfFloatStateLessProps = InputIntegerLogicProps & InputWithLabelMarginProps & {}

export const InputWithLabelMarginHalfFloatStateLess: React.FC<InputWithLabelMarginHalfFloatStateLessProps> = ({ className, ...rest }) => {
    const { myOnChange } = useInputHalfFloatLogic({ ...rest })
    return (
        <div className={'input_field_label_margin ' + (className || '')}>
            <InputWithLabel {...rest} onChange={myOnChange} />
        </div>
    )
}

interface InputHalfFloatLogicProps extends InputProps {
}

const useInputHalfFloatLogic = ({ onChange }: InputHalfFloatLogicProps) => {
    const myOnChange: CustomChangeFunction = useCallback((value, e) => {
        const validQuantity = checkHalfFloatValid(value)
        if (value === '' || validQuantity) {
            onChange?.(String(validQuantity), e)
        }
    }, [onChange])

    return { myOnChange }
}



export type InputWithLabelMarginIconProps = InputWithLabelProps & {
    icon: IconDefinition
    onClickIcon?: () => void
}

export const InputWithLabelMarginIcon: React.FC<InputWithLabelMarginIconProps> = ({ className, ...rest  }) => {
    return (
        <div className={'input_field_label_margin ' + (className || '')}>
            <InputWithLabelIcon {...rest}/>
        </div>
    )
}

export type InputWithLabelMarginRemoveProps = InputWithLabelProps & {
    removeFunct: ()=>void
}

export const InputWithLabelMarginRemove: React.FC<InputWithLabelMarginRemoveProps> = ({ className, removeFunct, ...rest }) => {
    return (
        <div className={'flex  items-center ' + (className || '')}>
            <InputWithLabelMargin {...rest} className='flex-auto w-1' />
            <TwinIcon className='hover:text-red-BA cursor-pointer' icon={faXmark} onClick={removeFunct}/>
        </div>
    )
}

interface InputWrapperProps {
    className?: string
    children?: React.ReactNode
}

const InputWrapper: React.FC<InputWrapperProps> = ({ className, children }) => {
    return (
        <div className={'input_field relative ' + (className || '')}>
            {children}
            <div className='input_border'></div>
        </div>
    )
}

export interface InputDefaultProps extends InputProps {}

export const InputDefault: React.FC<InputDefaultProps> = ({className, ...inputRest}) => {
    return (
        <InputWrapper className={className}>
            <Input {...inputRest} />
        </InputWrapper>
    )
}

export interface RectangularInputProps extends InputProps {}

export const RectangularInput: React.FC<RectangularInputProps> = ({className, ...inputRest}) => {
    const readOnly = inputRest.readOnly
    return (
        <div className={'border border-gray-D6 px-10 py-6 max-w-full ' + (className || '') + (readOnly ? ' rectangular_readonly' : '') }>
            <Input {...inputRest} className='w-full'/>
        </div>
    )
}


export const RectangularInputSmall: React.FC<RectangularInputProps> = ({ className, ...inputRest }) => {
    const readOnly = inputRest.readOnly
    return (
        <div className={'border border-gray-D6 px-8 py-3 max-w-full ' + (className || '') + (readOnly ? ' rectangular_readonly' : '') }>
            <Input {...inputRest} className='w-full'/>
        </div>
    )
}
export type RectangularInputIntegerProps = InputIntegerLogicProps & {}

export const RectangularInputInteger: React.FC<RectangularInputIntegerProps> = ({ className, ...rest }) => {
    const { myOnChange, onKeyDown } = useInputIntegerLogic({ ...rest })
    return (
        <RectangularInput {...{ className, ...rest }} onChange={myOnChange} onKeyDown={onKeyDown} />
    )
}


export type RectangularInputFormatNumberSmallProps = Modify<InputProps, InputNumberLogicProps & {
}>

export const RectangularInputFormatNumberSmall: React.FC<RectangularInputFormatNumberSmallProps> = ({ className, value, onFocus, onBlur, onChange, name, ...inputRest }) => {
    const readOnly = inputRest.readOnly
    const {myOnBlur, myOnFocus, displayValue, myOnChange, onKeyDown} = useInputNumberLogic({onChange, value, onBlur, onFocus, readOnly})

    return (
        <div className={'border border-gray-D6 px-6 py-3 max-w-full rectangular_input_number_small ' + (className || '') + (readOnly ? ' rectangular_readonly' : '') }>
            <Input {...inputRest} className='w-full text-right' onFocus={myOnFocus} onBlur={myOnBlur} value={displayValue} onChange={myOnChange} onKeyDown={onKeyDown} />
            {name && <input type='hidden' name={name} value={value} readOnly={true} />}
        </div>
    )
}
export type InputNumberWithLabelMarginProps = Modify<InputProps, InputNumberLogicProps & {
    label: string
}>

export const InputNumberWithLabelMargin: React.FC< InputNumberWithLabelMarginProps> = ({ label, className, value, onFocus, onBlur, onChange, name, ...inputRest }) => {
    const readOnly = inputRest.readOnly
    const {myOnBlur, myOnFocus, displayValue, myOnChange, onKeyDown} = useInputNumberLogic({onChange, value, onBlur, onFocus, readOnly})
    return (
        <Fragment>
            <InputWithLabelMargin onFocus={myOnFocus} onBlur={myOnBlur} value={displayValue} onChange={myOnChange} {...inputRest} {...{ className, label, onKeyDown}} />
            {name ? <input type='hidden' name={name} value={value} /> : null}
        </Fragment>
    )
}

export type InputNumberWithLabelMarginStateFullProps = Modify<InputNumberWithLabelMarginProps,  {
    value?: never
    defaultValue?: string | number
}>

export const InputNumberWithLabelMarginStateFull: React.FC<InputNumberWithLabelMarginStateFullProps> = ({ defaultValue, ...rest }) => {
    const [valueNumber, setValueNumber] = useState<string | number>(defaultValue ?? '')
    return (
        <InputNumberWithLabelMargin {...rest}  onChange={(value) => setValueNumber(value)} value={valueNumber} />
    )
}

export type InputNumberWithLabelMarginTextRightProps = Modify<InputNumberWithLabelMarginProps, {
    classNameParent?: string
    textRight: string
}>

export const InputNumberWithLabelMarginTextRight: React.FC<InputNumberWithLabelMarginTextRightProps> = ({classNameParent, textRight, className,  ...inputRest }) => {
    return (
        <div className={'flex items-end ' + classNameParent}>
            <InputNumberWithLabelMargin className={'flex-auto w-1 ' + className} {...inputRest} />
            <span className='ml-10'>{textRight}</span>
        </div>
        )

}

interface InputNumberLogicProps {
    onChange?: CustomChangeFunction
    onFocus?: React.FocusEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    value: string | number
    readOnly?: boolean
}

const useInputNumberLogic = ({ readOnly, onFocus, onBlur, onChange, value }: InputNumberLogicProps) => {
    const [active, setActive] = useState(false)
    const separators = useMemo(() => getSeparatorsOfFormatedNumber(), [])
    
    const myOnFocus: React.FocusEventHandler<any> = useCallback((e) => {
        if (!readOnly) {
            setActive(true)
        }
        onFocus?.(e)
    }, [onFocus, readOnly])

    const myOnBlur: React.FocusEventHandler<any> = useCallback((e) => {
        setActive(false)
        onBlur?.(e)
    }, [onBlur])

    const myOnChange: CustomChangeFunction = useCallback((value, e) => {
        const validQuantity = checkFloatValid(value)
        if(value === '' || validQuantity) {
            onChange?.(String(validQuantity), e)
        }
    }, [onChange])

    const onKeyDown: React.KeyboardEventHandler = useCallback((e) => {
        if (!readOnly) {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                let n = value === '' ? 0 : parseFloat(String(value))
                const changeNumberBy = e.shiftKey ? 10 : 1
                if (e.key === 'ArrowDown') {
                    n = n-changeNumberBy
                } else {
                    n = n+changeNumberBy
                }
                myOnChange(String(n), e as any)
            }
        }
    }, [value, myOnChange, readOnly])

    let displayValue = value
    if (!active) {
        displayValue = displayFormatedNumber(value)
    } else {
        displayValue = String(value).replaceAll('.', separators.decimalSeparator)
    }

    return {active, myOnBlur, myOnFocus, value, displayValue, myOnChange, onKeyDown}
}



export interface InputHiddenProps extends InputProps {}

export const InputHidden: React.FC<InputHiddenProps> = ({className, ...inputRest}) => {
    return (
        <Input {...inputRest} type='hidden' readOnly={true} />
    )
}

interface LabelRenderProps {
    label: string
    id: string
    className?: string
    required?: boolean
}

export const LabelRender: React.FC<LabelRenderProps> = ({ label, id, className, required }) => {
    return (
        <label htmlFor={id} className={'input_field_label text-gray-51 cursor-pointer ' + (className || '')} >{label}{required ? ' *' : ''}</label>
    )
}

export type InputProps = Modify<React.HTMLProps<HTMLInputElement>, {
    myref?: React.MutableRefObject<any>
    onChange?: CustomChangeFunction
}>

const Input: React.FC<InputProps> = ({ onBlur, id, className, myref, onFocus, onChange, ...rest }) => {
    const { inputCustomOnBlur, inputCustomOnFocus, customOnChange } = useInputLogic({ onBlur, onFocus, onChange })
    return (
        <input id={id} onChange={customOnChange} onFocus={inputCustomOnFocus} className={'input_field_input appearance-none block outline-none border-0 bg-transparent regular14 focus:ring-0 placeholder-gray-97 placeholder-font-light  ' + (className || '') + (rest.readOnly? ' cursor-default' : ' cursor-pointer')} ref={myref} onBlur={inputCustomOnBlur}  {...rest} />
    )
}

export interface InputLogic {
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
    onChange?: CustomChangeFunction
}

export const useInputLogic = ({ onBlur, onFocus, onChange }: InputLogic) => {
    const inputCustomOnBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget
        const parentCurrentTarget = currentTarget.parentElement
        changeClassListForLabelUp(currentTarget)
        handleErrorsInput(currentTarget, parentCurrentTarget)
        removeClassName(inputFocusClass, parentCurrentTarget)
        onBlur?.(e)
    }, [onBlur])

    const inputCustomOnFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        const currentTarget = e.currentTarget
        const parentCurrentTarget = currentTarget.parentElement
        addClassName(inputFocusClass, parentCurrentTarget)
        onFocus?.(e)
    }, [onFocus])

    const customOnChange = useCallback((e: InputChangeEvent) => {
        const currentTarget = e.currentTarget
        const inputValue = currentTarget.value
        onChange?.(inputValue, e)
    }, [onChange])

    return { inputCustomOnBlur, inputCustomOnFocus, customOnChange }
}

export default Input