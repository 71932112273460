import TwinBigCalendar, { TwinBigCalendarProps } from '../TwinBigCalendar'
import TwinTrans from '../TwinTrans'
import LoadingSpinner from '../LoaderDecider/LoadingSpinner'
import { memo } from 'react'
import { memoChecker } from '../../utils/globals/components'
import { TwinBigCalendarEvent, TwinBigCalendarEvents } from '../TwinBigCalendar/types'
import './workingCalendar.sass'
interface WorkingCalendarProps extends EventsWorkingCalendarProps {
    className?: string
    calendarProps?: TwinBigCalendarProps
}

const WorkingCalendar: React.FC<WorkingCalendarProps> = memo(({ className, events, calendarProps }) => {
    return (
        <div className={'pt-30 mt-40 white_box flex flex-auto h-1 ' + (className||'')}>
            <TwinBigCalendar {...calendarProps} events={events || []}/>
            <EventsWorkingCalendar events={events}  />
        </div>
    )
}, (oldProps, nextProps) => memoChecker(oldProps, nextProps, ['className', 'events', 'calendarProps']))

export interface EventsWorkingCalendarProps {
    events?: TwinBigCalendarEvents | null
    children?: React.ReactNode
}

export const EventsWorkingCalendar: React.FC<EventsWorkingCalendarProps> = ({ events, children }) => {
    const renderThis: JSX.Element[] = []
    if (events?.length) {
        for (const key in events) {
            const event = events[key]
            renderThis.push(
                <EventWorkingCalendar key={key} event={event} />
            )
        }
    } else {
        renderThis.push(<div key='no-events'><TwinTrans transKey='noEvents'>No hay eventos</TwinTrans></div>)
    }
    return (
        <div className='flex flex-col px-40 pb-16 min-w-350 wcal_event_container'>
            <h2>
                <TwinTrans transKey='events'>Eventos</TwinTrans>
            </h2>
            <div className='flex-auto overflow-auto wcal_event_container_margin '>
                <div className='wcal_event_container_inner flex flex-col gap-15'>
                    {events === null ? <LoadingSpinner /> : renderThis}
                    {children}
                </div>
            </div>
        </div>
    )
}

interface EventWorkingCalendarProps {
    event: TwinBigCalendarEvent
}

export const EventWorkingCalendar: React.FC<EventWorkingCalendarProps> = ({event}) => {
    return (
        <div className='flex'>
            <div className='rounded-full min-w-20 h-20 mt-2' style={{ background: event.color }}>
            </div>
            <div className='ml-12'>
                <div className='text-gray-51 medium14 leading-14'>{event.title}</div>
                <div className='light12 text-gray-51 mt-6 whitespace-pre-wrap'>{event.subtitle}</div>
            </div>
            {event.rightSide}
        </div>
    )
}

export default WorkingCalendar