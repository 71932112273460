import { useCallback } from 'react'
import { ButtonLoadFiltersStateLess, FiltersConfig, NameAndModifyFilters, useButtonsLoadFiltersStateFullLogic } from '../../../../../baseComponents/Button/ButtonLoadFilter'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import { ConfigColumnsColumn } from '../../../../../baseComponents/ModalsLayouts/ModalConfigColumnsTabs/types'
import { TwinDictionary } from '../../../../../utils/globals/dictionary'
import { DictTaskTableHeaderTy } from '../types'
import { getTaskTableSelectedColumns, SelectedTaskColsFiltersHeader } from '../../../../../utils/hooks/tasks/useTaskConfigColumns'

interface HeaderModalTaskConfigColumnsProps extends HeaderModalTaskConfigColumnsLogicProps {
    tmpColumns: SelectedTaskColsFiltersHeader
}

const HeaderModalTaskConfigColumns: React.FC<HeaderModalTaskConfigColumnsProps> = ({ ...rest }) => {
    const defaultValues = getTaskTableSelectedColumns(rest.name, rest.columns, rest.headers)
    const { filters, getAllFilters } = useButtonsLoadFiltersStateFullLogic({ tableName: rest.name, defaultValues })
    const { onChangeFilter } = useHeaderModalTaskConfigColumnsLogic({ ...rest })
    const mySelectedFilter: FiltersConfig = { value: { filters: rest.tmpColumns.filters, selectedColumns: rest.tmpColumns.selectedColumns }, id: rest.tmpColumns.id, name: rest.tmpColumns.name }
    return (
        <div>
            <div className='flex items-center mb-10 header_config_columns'>
                <h2>
                    <TwinTrans transKey='configureFiltersOrderColumnsAndHeader'>Configurar filtros, orden, columnas y cabecera</TwinTrans><span>:</span>
                </h2>
                <ButtonLoadFiltersStateLess tableName={rest.name} allowCreate={true} selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} filters={filters} getAllFilters={getAllFilters} defaultValues={defaultValues} onOpenModal={getAllFilters}/>
            </div>
            <NameAndModifyFilters getAllFilters={getAllFilters} selectedFilters={mySelectedFilter} onChangeFilter={onChangeFilter} filters={filters} />
        </div>
    )
}

interface HeaderModalTaskConfigColumnsLogicProps {
    name: string
    columns: ConfigColumnsColumn[]
    headers: DictTaskTableHeaderTy
    setTmpColumns: React.Dispatch<React.SetStateAction<SelectedTaskColsFiltersHeader>>
}

const useHeaderModalTaskConfigColumnsLogic = ({ setTmpColumns }: HeaderModalTaskConfigColumnsLogicProps) => {

    const onChangeFilter = useCallback((json: TwinDictionary) => {
        setTmpColumns({ filters: json?.value?.filters || {}, selectedColumns: json?.value?.selectedColumns || {}, id: json.id, name: json.name, order: json?.value?.order || {}, selectedHeader: json?.value?.selectedHeader || {} })
    }, [setTmpColumns])

    return { onChangeFilter }
}

export default HeaderModalTaskConfigColumns