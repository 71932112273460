import { faArrowsRotate, faBriefcase, faChevronLeft, faChevronRight, faClock, faEdit, faPartyHorn } from '@fortawesome/pro-light-svg-icons'
import { WhiteBox } from '../../../../../../baseComponents/AppLayout/WhiteBox'
import TwinIcon from '../../../../../../baseComponents/TwinIcon'
import useTwinTranslation from '../../../../../../utils/hooks/useTwinTranslation'
import TwinTrans from '../../../../../../baseComponents/TwinTrans'
import { ButtonPrimary, ButtonRed } from '../../../../../../baseComponents/Button'
import { getEndOfWeek, getStartOfWeek, parseWorkingTimeTimetable } from './functions'
import TwinBigCalendar from '../../../../../../baseComponents/TwinBigCalendar'
import { EmployeeJourneyTy } from '@teinor/erp/types/company/userInner/employee/employeeTimetable'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import twinFetchPost, { twinFetchPostJSON } from '../../../../../../utils/globals/data'
import { changeErrorMessage } from '../../../../../../utils/reducers/reduxDispatch'
import { EventProps } from 'react-big-calendar'
import { DictEmployeeJourneyTy, UserTimeControlEvent } from './types'
import useEditDeleteModal from '../../../../../../utils/hooks/useEditDeleteModal'
import ModalEditUserTimeControl from './ModalEditUserTimeControl'
import moment from 'moment-timezone'
import LoadingSpinner from '../../../../../../baseComponents/LoaderDecider/LoadingSpinner'
import { displayFormatedHourFromMins } from '../../../../../../utils/globals/date'
import useIsLoading from '../../../../../../utils/hooks/useIsLoading'
import { getConfigParam } from '../../../../../../utils/reducers/getters'
import './userTimeControl.sass'

type TableLoaded = 'loadFirstTableThenEmployee' | 'loadEmployee' | 'loadedAll' | 'justLoadTable'

const UserTimeControl: React.FC = () => {
    const [tableLoaded, setTableLoaded] = useState<TableLoaded>('loadFirstTableThenEmployee')
    return (
        <div className='flex flex-col overflow-auto mt-40'>
            <div className='flex items-center gap-40'>
                <MyWorkDay {...{ tableLoaded, setTableLoaded }} />
            </div>
            <UserTimeControlCalendar  {...{ tableLoaded, setTableLoaded }} />
        </div>
    )
}

interface WorkDayPropsBase{
    tableLoaded: TableLoaded
    setTableLoaded: React.Dispatch<React.SetStateAction<TableLoaded>>
}

interface MyWorkDayProps extends MyWorkDayLogicProps{ }

const MyWorkDay: React.FC<MyWorkDayProps> = ({...logic}) => {
    const {t} = useTwinTranslation()
    const { employeeJourneyData, checkHandleWorking, getEmployeeJourney, loading } = useMyWorkDayLogic({ ...logic })
    if(employeeJourneyData === null){
        return (
            <WhiteBox className=' flex flex-auto p-25'>
                <LoadingSpinner />
            </WhiteBox>)
    }
    if(employeeJourneyData === false){
        return (
            <WhiteBox className='flex-auto p-25 text-center'>
                <div className='text-28 mt-auto mb-auto'><TwinTrans transKey='notHaveWCal'>No tienes un horario asignado</TwinTrans></div>
                <span className='text-16'><TwinTrans transKey='adminMustAssignWCalToYou'>El administrador debe asignarte a un calendario con horario para poder fichar.</TwinTrans></span>           
            </WhiteBox>
        )
    }
    const now = new Date()
    const renderThis: JSX.Element[] = []
    const journey = employeeJourneyData?.journey
    if(journey){
        for (const pos in journey.workingTime) {
            const workingTime = journey.workingTime[pos]
            const startJourney = displayFormatedHourFromMins(workingTime[0])
            const endJourney = displayFormatedHourFromMins(workingTime[1])
            renderThis.push(
                <div key={pos}> <TwinTrans transKey='From'>De</TwinTrans> {startJourney} <TwinTrans transKey='To'>a</TwinTrans> {endJourney}</div>
            )
        }
    }
    let totalJourney =  0
    const renderThisTimetables : JSX.Element[] = []
    const timetables = employeeJourneyData?.timetables
    if (timetables){
        for (const timetable of timetables) {
            let startDate = timetable.edited_start_time || timetable.og_start_time 
            let endDate = timetable.edited_end_time || timetable.og_end_time 
            const startDateTime = new Date(startDate).getTime()
            if (endDate){
                totalJourney += Math.round(((new Date(endDate).getTime() - startDateTime)/1000)/60)
            } else {
                const startDateTimeMom = moment(startDate)
                const currentDateInTz = moment().tz(employeeJourneyData.timeZone || 'Europe/Madrid')
                const timeStart = (startDateTimeMom.get('minutes') + (startDateTimeMom.get('hours')*60))
                const time = (currentDateInTz.get('minutes') + (currentDateInTz.get('hours')*60))
                totalJourney += time - timeStart
            }
            let edited = timetable.edited_start_time || timetable.edited_end_time
            renderThisTimetables.push(
                <div key={timetable.id} className='flex items-center '><TwinTrans transKey='From'>De</TwinTrans> {startDate.split(' ')[1]} <TwinTrans transKey='To'>a</TwinTrans> {endDate?.split(' ')[1] || t('workingDots', 'Trabajando...')} {edited ? '('+t('edited', 'Editado')+')' : ''}</div>
            )
        }
    }
    const flexible = journey?.flexible
    const isHalfDay = employeeJourneyData?.isHalfDay
    const type = employeeJourneyData?.type
    const workButtons: { [key: string]: JSX.Element} = {
        'isWorking': <ButtonRed className='w-full' onClick={checkHandleWorking}><TwinTrans transKey='endWork'>Fichar Salida</TwinTrans></ButtonRed>,
        'isNotWorking': <ButtonPrimary className='w-full' onClick={checkHandleWorking}>
            <TwinTrans transKey='startWork'>Fichar Entrada</TwinTrans>
        </ButtonPrimary>
    }
    const mustWorkToday = employeeJourneyData?.type === 'normalDay' || (employeeJourneyData?.type === 'specialDay' && employeeJourneyData?.journey?.workingTime.length) || (employeeJourneyData?.type === 'absenceDay') || (employeeJourneyData?.type === 'holidayDay' && isHalfDay)
    return (
        <WhiteBox className=' flex flex-auto my_work_day_wrapper'>
            <div className='p-25 border-gray-D6 border-r w-1/4 my_work_day_box'>
                <div className='flex items-center justify-start'>
                    <TwinIcon icon={faClock} className=' h-33 w-33 text-green-43 mr-20 my_work_day_icon' />
                    <h2>
                        <TwinTrans transKey='myWorkDay'>Mi jornada</TwinTrans>
                    </h2>
                </div>
                <div className='text-gray-97 mt-20 my_work_day_info'>
                    <div>
                        <TwinTrans transKey='today'>Hoy</TwinTrans>,  {now.toLocaleDateString(undefined, {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}
                    </div>
                    <TimetableToday {...{ type, flexible, isHalfDay}} />
                    <div className='flex flex-auto flex-col justify-end overflow-y-auto'>
                        {renderThis}
                    </div>
                </div>
            </div>
            <div className='p-25 border-gray-D6 border-r w-1/4 my_work_day_box'>
                <div className='flex items-center justify-start'>
                    <TwinIcon icon={faBriefcase} className=' h-33 w-33 text-green-43 mr-20 my_work_day_icon' />
                    <h2>
                        <TwinTrans transKey='myWorkedTimeToday'>Mi tiempo trabajado hoy</TwinTrans>
                    </h2>
                </div>
                <div className='text-gray-97 mt-20 my_work_day_info'>
                    <div className='flex flex-auto flex-col justify-end overflow-y-auto'>
                        {renderThisTimetables}
                    </div>
                </div>
            </div>
            <div className='relative p-25 flex flex-col items-center justify-between flex-auto'>
                <TwinIcon className='time_control_hour_refresh hover:text-green-21' icon={faArrowsRotate} onClick={getEmployeeJourney} />
                {mustWorkToday ? <Fragment>
                    <div className='time_control_hour'>{displayFormatedHourFromMins(totalJourney)}</div>
                    {!loading ? workButtons[employeeJourneyData?.isWorking ? 'isWorking' : 'isNotWorking'] : <LoadingSpinner />}
                </Fragment> : <div className='time_control_hour mt-auto mb-auto'><TwinTrans transKey='freeDayEx'>¡Día festivo!</TwinTrans></div>}
            </div>
        </WhiteBox>
    )
}

interface MyWorkDayLogicProps extends WorkDayPropsBase {}

const useMyWorkDayLogic = ({ setTableLoaded, tableLoaded }: MyWorkDayLogicProps) => {
    const {endLoading, loading, startLoading} = useIsLoading()
    const [employeeJourneyData, setEmployeeJourneyData] = useState<EmployeeJourneyTy | null | false>(null)
    const {t} = useTwinTranslation()
    const errorGPS = t('youMustActivateNavigatorGeolocation', 'Debes activar la geolocación del navegador')
    const needUbication = getConfigParam('requireUbicationOnWorking') === 'on'
    const getEmployeeJourney = useCallback(async() => {
        const data = await twinFetchPostJSON('/api/app/employee/employeeTimetable/getEmployeeTodayJourney', {})
        setEmployeeJourneyData(data)
    }, [setEmployeeJourneyData])

    const handleWorking = useCallback(async (coords: GeolocationPosition) => {
        const handleWorking = await twinFetchPost('/api/app/employee/employeeTimetable/changeEmployeeWorkStatus', { location: JSON.stringify(coords?.coords?.latitude + ',' + coords?.coords?.longitude) })
        setTimeout(() => {
            if (handleWorking) {
                setTableLoaded('loadFirstTableThenEmployee')
            }
            endLoading()
        }, 500)      
    }, [setTableLoaded, endLoading])

    const handleWorkingWithoutCoords = useCallback(async () => {
        const handleWorking = await twinFetchPost('/api/app/employee/employeeTimetable/changeEmployeeWorkStatus', {})
        setTimeout(() => {
            if (handleWorking) {
                setTableLoaded('loadFirstTableThenEmployee')
            }
            endLoading()
        }, 500)            
    }, [setTableLoaded, endLoading])

    const checkHandleWorking = useCallback(async () => {
        startLoading()
        if (needUbication) {
            if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition((coords) => handleWorking(coords), () => { changeErrorMessage(errorGPS); endLoading() }, { enableHighAccuracy: true })
            }
        } else {
            handleWorkingWithoutCoords()
        }
    }, [handleWorking, errorGPS, endLoading, startLoading, needUbication, handleWorkingWithoutCoords])

    useEffect(() => {
        if (tableLoaded === 'loadEmployee'){
            getEmployeeJourney()
        }
    }, [getEmployeeJourney, tableLoaded])

    return { employeeJourneyData, getEmployeeJourney, checkHandleWorking, startLoading, endLoading, loading }
}

interface TimetableTodayProps {
    type?: 'freeDay' | 'holidayDay' | 'specialDay' | 'normalDay' | 'noWorkDay' | 'holidayRequested' | 'absenceDay' | 'absenceRequested' | 'absenceJustifiedDay'
    flexible?: boolean
    isHalfDay?: boolean
}

const TimetableToday: React.FC<TimetableTodayProps> = ({ type, flexible, isHalfDay }) => {
    if(type === 'specialDay'){
        return (
            <div>
                <TwinTrans transKey='specialDay'>Día especial</TwinTrans>
                {flexible ? <span> (<TwinTrans transKey='flexibleS'>flexible</TwinTrans>)</span> : null}
            </div>
        )
    }
    if (type === 'absenceDay' || type === 'absenceJustifiedDay'){
        return (
            <div>
                <TwinTrans transKey='absenceDay'>Día con ausencia</TwinTrans>
                {flexible ? <span> (<TwinTrans transKey='flexibleS'>flexible</TwinTrans>)</span> : null}
            </div>
        )
    }
    if (type === 'holidayDay' && isHalfDay){
        return (
            <div>
                <TwinTrans transKey='halfDay'>Medio día</TwinTrans>
            </div>
        )
    }
    return (
        <div>
            <TwinTrans transKey='timeTable'>Horario</TwinTrans>
            {flexible ? <span> <TwinTrans transKey='flexibleS'>flexible</TwinTrans></span> : null}
        </div>
    )
}
interface UserTimeControlCalendarProps extends UserTimeControlCalendarLogicProps {}

const UserTimeControlCalendar: React.FC<UserTimeControlCalendarProps> = ({...logic}) => {
    const { selectedDay, prevWeek, nextWeek, startWeekDateParsed, endWeekDateParsed, calendarEvents, minHour, totalWeekTime, handleOpenEditModal, openModal, ...rest } = useUserTimeControlCalendarLogic({ ...logic })
    const startWeekDate = getStartOfWeek(selectedDay?.toDateString())
    startWeekDate.setHours(minHour)
    return (
        <WhiteBox className='p-25 mt-40'>
            <div className='flex flex-auto flex-col user_time_control_calendar_toolbar'>
                <div className='flex items-center calendar_toolbar'>
                    <div className='mr-24 calendar_toolbar_arrow'>
                        <TwinIcon className='cursor-pointer p-3 h-24  hover:text-green-21' icon={faChevronLeft} onClick={prevWeek} />
                    </div>
                    <div className='mr-24 calendar_toolbar_arrow'>
                        <TwinIcon className='cursor-pointer p-3 h-24 hover:text-green-21' icon={faChevronRight} onClick={nextWeek} />
                    </div>
                    <div className='toolbar-label text-left text-gray-51 capitalize'>
                        <TwinTrans transKey='week'>Semana</TwinTrans> {startWeekDateParsed.toLocaleString().split(',')[0]} - {endWeekDateParsed.toLocaleString().split(',')[0]}
                    </div>
                    <div className='ml-auto text-green-43 cursor-pointer calendar_toolbar_edit' onClick={handleOpenEditModal}>
                        <TwinIcon icon={faEdit} className='mr-10' />
                        <TwinTrans transKey='editWorkDays'>Editar Jornadas</TwinTrans>
                    </div>
                </div>
                <div className='ml-90 total_hours light20 text-gray-33'>
                    <TwinTrans transKey='totalHours'>Total Horas</TwinTrans>: {displayFormatedHourFromMins(totalWeekTime)}
                </div>
                <TwinBigCalendar min={startWeekDate} components={{ week: { event: DeciderTimetableDay }}as any} defaultView='week' className='user_time_control_calendar' events={calendarEvents} date={selectedDay} />
            </div>
            {openModal? <ModalEditUserTimeControl {...{openModal, ...rest, setTableLoaded: logic.setTableLoaded}} /> : null}
        </WhiteBox>
    )
}

interface UserTimeControlCalendarLogicProps extends WorkDayPropsBase {}

const useUserTimeControlCalendarLogic = ({setTableLoaded, tableLoaded}: UserTimeControlCalendarLogicProps) => {
    const [selectedDay, setSelectedDay] = useState<Date>(new Date())
    const [calendarData, setCalendarData] = useState<DictEmployeeJourneyTy | null>(null)
    const { openModal, setOpenModal } = useEditDeleteModal()
    const startWeekDateParsed = useMemo(() => new Date(getStartOfWeek(selectedDay?.toDateString())), [selectedDay])
    const endWeekDateParsed = useMemo(() => new Date(getEndOfWeek(selectedDay?.toDateString())), [selectedDay])
    const {t} = useTwinTranslation()
    const getCalendarData = useCallback(async() => {
        const result = await twinFetchPostJSON('/api/app/employee/employeeTimetable/getMyJourneyByDates', { dateStart: startWeekDateParsed.toDateString(), dateEnd: endWeekDateParsed.toDateString() })
        if (result) {
            setCalendarData(result)
            setTableLoaded((old) => old === 'justLoadTable' ? 'loadedAll' :  'loadEmployee')
        }
    }, [startWeekDateParsed, endWeekDateParsed, setTableLoaded])

    const nextWeek = useCallback(() => {
        setTableLoaded('justLoadTable')
        setSelectedDay((old) =>{
            const copyOlddate = new Date(old)
            copyOlddate.setDate(copyOlddate.getDate() + 7)
            return copyOlddate
        })
    }, [setSelectedDay, setTableLoaded])

    const prevWeek = useCallback(() => {
        setTableLoaded('justLoadTable')
        setSelectedDay((old) =>{
            const copyOlddate = new Date(old)
            copyOlddate.setDate(copyOlddate.getDate() - 7)
            return copyOlddate
        })
    }, [setSelectedDay, setTableLoaded])

    const handleOpenEditModal = useCallback(() => {
        setOpenModal({ type: 'edit', allRowData: calendarData || {} })
    }, [setOpenModal, calendarData])


    const { calendarEvents, minHour,  totalWeekTime } = parseWorkingTimeTimetable(calendarData, { startMessage: t('startWorkDay', 'Inicio jornada'), endMessage: t('endSWorkDay', 'Fin jornada') })

    useEffect(() => {
        if (tableLoaded === 'justLoadTable' || tableLoaded === 'loadFirstTableThenEmployee') {
            getCalendarData()
        }
    }, [getCalendarData, tableLoaded])
    return { selectedDay, nextWeek, prevWeek, startWeekDateParsed, endWeekDateParsed, calendarEvents, minHour, totalWeekTime, handleOpenEditModal, openModal, setOpenModal, getCalendarData }
}


const DeciderTimetableDay: React.ComponentType<EventProps<UserTimeControlEvent>> = ({title, event}) =>{
    const {t} = useTwinTranslation()
    const { subtitle, flexible, type, isHalfDay } = event
    if(!event.allDay){
        return <div className='whitespace-pre-wrap'>{title}</div>
    }
    if (type === 'freeDay' || (type === 'holidayDay' && !isHalfDay)) {
        return (<TimetableFreeOrHolidayDay text={type === 'freeDay' ? t('exFreeDay', '¡Día festivo!') : t('exHolidayDay', '¡Día vacaciones!')} />)
    } else if (type === 'noWorkDay'){
        return (<div className='p-8 h-60 border-b border-gray-D6'></div>)
    }
    return (<TimetableWorkDay {...{ title, subtitle, flexible, type, isHalfDay }} />)
}

interface TimetableWorkDayProps {
    title?: string
    subtitle?: string
    flexible?: boolean
    type?: EmployeeJourneyTy['type']
    isHalfDay?: boolean
}

const TimetableWorkDay: React.FC<TimetableWorkDayProps> = ({ title, subtitle, flexible, type, isHalfDay }) => {
    return (
        <div>
            <div className='p-8 h-60 border-b border-gray-D6'>
                <TimetableToday type={type} {...{ flexible, isHalfDay }} />
                <div className='overflow-x-auto'> {title}</div>
            </div>
            <TotalHoursTimetableDay totalDayTime={parseInt(subtitle || '')} />
        </div>
    )
}

interface TimetableFreeOrHolidayDayProps {
    text: string
}

const TimetableFreeOrHolidayDay: React.FC<TimetableFreeOrHolidayDayProps> = ({ text }) => {
    return (
        <div>
            <div className='p-8 h-60 border-b border-gray-D6'>
                <div className='rounded-lg flex justify-center items-center border p-9 border-green-43 text-green-43 h-42 '>
                    <TwinIcon className='h-24 mr-10 ' icon={faPartyHorn} />
                    <span className='regular16'>
                        {text}
                    </span>
                </div>
            </div>
        </div>
    )
}

interface TotalHoursTimetableDayProps {
    totalDayTime: number
}

const TotalHoursTimetableDay: React.FC<TotalHoursTimetableDayProps> = ({ totalDayTime }) => {
    return (
        <div className='flex items-center mt-10 px-8'>
            <div>
                <TwinTrans transKey='totalHours'>Total Horas</TwinTrans>
            </div>
            <div className='flex items-center px-10 py-5 text-gray-33 bg-gray-EE ml-auto rounded-lg'>
                <TwinIcon className='mr-6' icon={faClock} />
                <span>
                    {displayFormatedHourFromMins(totalDayTime)}
                </span>
            </div>
        </div>
    )
}


export default UserTimeControl