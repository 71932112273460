import { faArrowRotateBack } from '@fortawesome/pro-light-svg-icons'
import { useCallback } from 'react'
import useTwinTranslation from '../../../../../utils/hooks/useTwinTranslation'
import { VerticalSortItem } from '../../../../../baseComponents/Sorts/VerticalSortList/types'
import TwinForm from '../../../../../forms/TwinForm'
import { ModalCreateEditStructureLayout } from '../../../../../baseComponents/ModalsLayouts/ModalCreateEdit'
import HeaderModalTaskConfigColumns from '../HeaderModalTaskConfigColumns'
import Checkbox from '../../../../../forms/Checkbox'
import TwinTrans from '../../../../../baseComponents/TwinTrans'
import TwinIcon from '../../../../../baseComponents/TwinIcon'
import VerticalSortList from '../../../../../baseComponents/Sorts/VerticalSortList'
import { ModalTaskConfigColumnsBaseProps } from '../types'
import { TwinDictionary } from '../../../../../utils/globals/dictionary'
import { getTaskOnlyDefaultHeader, SelectedTaskColsFiltersHeader } from '../../../../../utils/hooks/tasks/useTaskConfigColumns'

interface ModalTaskConfigColumnsHeaderProps extends ModalTaskConfigColumnsHeaderLogicProps {
}

const ModalTaskConfigColumnsHeader: React.FC<ModalTaskConfigColumnsHeaderProps> = ({ tmpColumns, headers,  columns, onSave, ...logic }) => {
    const { changeChecked, changeOrder, resetSelected } = useModalTaskConfigColumnsHeaderLogic({ tmpColumns, columns, onSave, headers, ...logic })
    const { t } = useTwinTranslation()
    const firstColumn: JSX.Element[] = []
    const secondColumn: VerticalSortItem[] = []
    for (const key in headers) {
        const item = headers[key]
        if (item.label ) {
            const index = Object.keys(tmpColumns.selectedHeader).indexOf(item.id)
            const selected = index !== -1
            firstColumn.push(<div className='mt-15' key={key}><Checkbox label={item.label} onChange={() => changeChecked(item.id)} checked={selected} /></div>)
            if (selected) {
                secondColumn.push({
                    name: item.label,
                    id: item.id,
                    order: index + 1
                })
            }
        }
    }
    secondColumn.sort((a, b) => a.order - b.order)

    return (
        <TwinForm beforeSubmitHandler={onSave} className='flex flex-col flex-auto'>
            <ModalCreateEditStructureLayout loading={false} translations={{
                title: <HeaderModalTaskConfigColumns {...{ tmpColumns, columns, setTmpColumns: logic.setTmpColumns, name: logic.name, headers }} />,
                button: t('save', 'Guardar')
            }} haveButtonPermissions={true} className='modal_negative_margin'>
                <div className='overflow-auto h-full w-full'>
                    <div className='flex flex-wrap w-full h-fit config_columns_container'>
                        <div className='w-fit max-w-300'>
                            <div className='flex items-center mb-20'>
                                <h4 className='mr-18 '><TwinTrans transKey='elementsHeaderToShow'> Elementos de la cabecera a mostrar</TwinTrans></h4>
                                <div className='flex items-center text-green-21 cursor-pointer' onClick={resetSelected}>
                                    <TwinIcon icon={faArrowRotateBack} fontSize={14} className='mr-8' />
                                    <span className='mt-2 lh-14'><TwinTrans transKey='byDefault'>Por defecto</TwinTrans></span>
                                </div>
                            </div>
                            {firstColumn}
                        </div>
                        <div className='border-r border-gray-D6 w-1 mx-75 config_columns_border' ></div>
                        <div className='flex flex-col min-h-full flex-auto w-1 config_colums_sort_list'>
                            <h4 className='mb-20'><TwinTrans transKey='orderSelectedElements'>Ordena los elementos seleccionados</TwinTrans></h4>
                            <VerticalSortList items={secondColumn} setItems={(setItemsFake) => changeOrder(setItemsFake(secondColumn))} />
                        </div>
                    </div>
                </div>
            </ModalCreateEditStructureLayout>
        </TwinForm>
    )
}

interface ModalTaskConfigColumnsHeaderLogicProps extends ModalTaskConfigColumnsBaseProps { }

const useModalTaskConfigColumnsHeaderLogic = ({ setTmpColumns, selectedColsFiltersHeader, columns, headers }: ModalTaskConfigColumnsHeaderLogicProps) => {
    const changeChecked = useCallback((itemKey: string) => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            let tmpCopy: SelectedTaskColsFiltersHeader = JSON.parse(JSON.stringify(oldTmp))
            const item = tmpCopy.selectedHeader[itemKey]
            if (item) {
                delete tmpCopy.selectedHeader[itemKey]
            } else {
                tmpCopy.selectedHeader = { ...tmpCopy.selectedHeader, [itemKey]: selectedColsFiltersHeader.selectedHeader[itemKey] || {} }
            }
            return { ...tmpCopy }
        })
    }, [setTmpColumns, selectedColsFiltersHeader.selectedHeader])

    const changeOrder = useCallback((items: VerticalSortItem[]) => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            let header: TwinDictionary = {}
            for (const item of items) {
                header[item.id] = selectedColsFiltersHeader.selectedHeader[item.id] || {}
            }
            return { ...oldTmp, selectedHeader: header }
        })
    }, [selectedColsFiltersHeader, setTmpColumns])

    const resetSelected = useCallback(() => {
        setTmpColumns((oldTmp: SelectedTaskColsFiltersHeader) => {
            const defaultInfo = getTaskOnlyDefaultHeader(headers)
            
            const myColumns = { ...oldTmp, selectedHeader: defaultInfo.selectedHeader, filters: oldTmp.filters }
            return myColumns
        })
    }, [headers, setTmpColumns])

    return { setTmpColumns, changeChecked, changeOrder, resetSelected }
}


export default ModalTaskConfigColumnsHeader