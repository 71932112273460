import { faTrashCan } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../../../../../../../baseComponents/TwinIcon'
import TwinTrans from '../../../../../../../../baseComponents/TwinTrans'
import { WCalHolidayUsedDayDateSelected } from '../types'
import useTwinTranslation from '../../../../../../../../utils/hooks/useTwinTranslation'
import './tableHolidayRequests.sass'

interface TableHolidayRequestsProps {
    listHolidays: WCalHolidayUsedDayDateSelected[]
    removeHoliday: (pos: number)=>void
}

const TableHolidayRequests: React.FC<TableHolidayRequestsProps> = ({ listHolidays, removeHoliday }) => {
    const renderThis: JSX.Element[] = []
    for (const key in listHolidays) {
        const holiday = listHolidays[key]
        renderThis.push(<TableHolidayRequestRow key={key} posArr={parseInt(key)} data={holiday} removeHoliday={removeHoliday} />)
    }
    return (
        <div>
            <div className='regular20 mb-20'>
                <TwinTrans transKey='listOfHolidaysSelected'>Lista de vacaciones seleccionadas</TwinTrans>
            </div>
            <div className='flex items-center table_holiday_admin_row bg-gray-F7 p-10 px-14 medium14'>
                <div><TwinTrans transKey='startDate'>Fecha inicio</TwinTrans></div>
                <div><TwinTrans transKey='endDate'>Fecha fin</TwinTrans></div>
                <div></div>
            </div>
            {renderThis}
        </div>
    )
}

interface TableHolidayRequestRowProps {
    data: WCalHolidayUsedDayDateSelected
    posArr: number
    removeHoliday: (pos: number) => void
}

const TableHolidayRequestRow: React.FC<TableHolidayRequestRowProps> = ({ data, posArr, removeHoliday}) => {
    const {t} = useTwinTranslation()
    return (
        <div className='flex items-center table_holiday_admin_row p-10 px-14 border-b border-gray-F7'>
            <div>{new Date(data.start).toLocaleString().split(',')[0]}</div>
            <div>{data.isHalfDay ? t('halfDay', 'Medio día') : new Date(data.end).toLocaleString().split(',')[0]}</div>
            <div>
                <TwinIcon className='cursor-pointer hover:text-red-BA' icon={faTrashCan} onClick={()=>removeHoliday(posArr)}/>
            </div>
        </div>
    )
}


export default TableHolidayRequests