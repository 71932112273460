import { useCallback, useEffect, useState } from 'react'
import { ModalCreateEditStructureLayout } from '../../baseComponents/ModalsLayouts/ModalCreateEdit'
import { ModalComponentProps, ModalEditComponentProps } from '../../baseComponents/ModalsLayouts/types'
import { permissionCheck } from '../../baseComponents/PermissionChecker/function'
import { RowData } from '../../baseComponents/TwinTable/types'
import { FormRender } from '../../forms/FormRenderer/types'
import { twinFetchPostJSON } from '../../utils/globals/data'
import { valueOrDefaultValue } from '../../utils/globals/valueOrDefaultValue'
import useTwinTranslation from '../../utils/hooks/useTwinTranslation'
import { getAllTaskTypes, getCustomers, getEmployees, getGroups, getTaskCFields } from '../../utils/reducers/getters'
import TableTaskWorkLogs from './TaskBagOfHours'
import { parsedDataBagOfHoursDetail } from './TaskBagOfHours/functions'
import { METaskBagOfHoursModelTypeExtended } from './TaskBagOfHours/types'
import { useParams } from 'react-router'
import { ModalWithCloseButton } from '../../baseComponents/Modal'
import { ModalOpenedSetTy } from '../../baseComponents/Modal/types'
import FormRenderer from '../../forms/FormRenderer'
import TwinForm from '../../forms/TwinForm'
import { changeErrorMessage, changeSuccessMessage } from '../../utils/reducers/reduxDispatch'
import { useSingleTaskLogic, SingleTaskInner } from '../Task/SingleTask'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import TwinIcon from '../../baseComponents/TwinIcon'
import TwinTrans from '../../baseComponents/TwinTrans'
import { UserPermissionsSingle } from '../../baseComponents/CreateRoutering/types'
import { ConnectedProps, connect } from 'react-redux'
import withLoading from '../../utils/hoc/withLoading'
import { AllReduxPayloads } from '../../utils/reducers'
import { TaskCFieldPayload } from '../../utils/reducers/company/taskCFields'
import './modaledittaskbagofhours.sass'

type ModalEditTaskBagOfHoursProps = ModalEditComponentProps & ReduxModalEditTaskBagOfHours & { }

const ModalEditTaskBagOfHours: React.FC<ModalEditTaskBagOfHoursProps> = ({ allRowData, userPermissions, setOpened, taskCFields, ...rest }) => {
    const { selectedTaskId, setSelectedTaskId, returnModal} = useModalEditTaskBagOfHoursLogic({ setOpened })

        return (
            <ModalWithCloseButton size='modal_big' opened={true} setOpened={setOpened} setOpenedReturn={returnModal} showCloseButton={selectedTaskId ? true : false} className='text-gray-51 flex flex-col edit_bag_of_hours' onClickOut={false} >
                <div className={'flex flex-auto '}>
                    {selectedTaskId ?
                        <TaskBagOfHoursSingleTask selectedTaskId={selectedTaskId} setSelectedTaskId={setSelectedTaskId} />
                        :
                        <TaskBagOfHoursFormAndTable setSelectedTaskId={setSelectedTaskId} allRowData={allRowData} userPermissions={userPermissions} {...rest} setOpened={setOpened} />
                    }
                </div>
            </ModalWithCloseButton>)
    }

interface ModalEditTaskBagOfHoursLogicProps {
    setOpened: ModalOpenedSetTy
}

const useModalEditTaskBagOfHoursLogic = ({ setOpened }: ModalEditTaskBagOfHoursLogicProps) => {
    const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null)
   
    const returnModal = useCallback(() => {
        if (selectedTaskId) {
            setSelectedTaskId(null)
        } else {
            setOpened(null)
        }
    }, [setOpened, selectedTaskId, setSelectedTaskId])

    return { selectedTaskId, setSelectedTaskId, returnModal }
}

interface TaskBagOfHoursSingleTaskProps {
    selectedTaskId: number | null
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

const TaskBagOfHoursSingleTask: React.FC<TaskBagOfHoursSingleTaskProps> = ({ selectedTaskId, setSelectedTaskId }) => {
    const { onInvalidPermissions, onDeletedTask } = useTaskBagOfHoursSingleTaskLogic()
    const { refSubTask, data: dataSingleTask, updateTask, setData, customerDicts, changeDescription, changeName, openModalInvertedTime, setOpenModalInvertedTime } = useSingleTaskLogic({ id: String(selectedTaskId), onInvalidPermissions, onDeletedTask })
    const employees = getEmployees()
    const groups = getGroups()
    const taskCFields = getTaskCFields()
    const taskTypes = getAllTaskTypes()
    return (
        <div className={'flex flex-col w-full single_task overflow-auto'}>
        {dataSingleTask?.finished ?
            <div className='mr-auto flex items-center task_finished_message bg-green-23' >
                <TwinIcon icon={faCheckCircle} className='mr-10 h-20' />
                <TwinTrans transKey='finishedTask'>Tarea finalizada</TwinTrans>
            </div> : null}
        {dataSingleTask && <SingleTaskInner data={dataSingleTask} setSelectedSubtaskId={setSelectedTaskId}{...{ updateTask, setData, changeDescription, changeName, refSubTask, customerDicts, openModalInvertedTime, setOpenModalInvertedTime, employees, groups, taskCFields, taskTypes }} />}
        </div>
    )
}

const useTaskBagOfHoursSingleTaskLogic = () => {
    const { t } = useTwinTranslation()
    const taskNotAvailable = t('taskNotAvailable', 'Esta tarea no está disponible')
    const taskDeleted = t('taskDeletedSuccessfully', 'Tarea eliminada con éxito')
    const taskHasBeenDeleted = t('taskHasBeenDeleted', 'Esta tarea ha sido eliminada')

    const onInvalidPermissions = useCallback(() => {
        changeErrorMessage(taskNotAvailable)
    }, [taskNotAvailable])

    const onDeletedTask = useCallback((otherPerson?: boolean) => {
        if (otherPerson) {
            changeErrorMessage(taskHasBeenDeleted)
        } else {
            changeSuccessMessage(taskDeleted)
        }
    }, [taskDeleted, taskHasBeenDeleted])

    return { onInvalidPermissions, onDeletedTask }
}

type TaskBagOfHoursFormAndTableProps = TaskBagOfHoursFormAndTableLogicProps & ModalComponentProps & {
    userPermissions?: UserPermissionsSingle
    setSelectedTaskId: React.Dispatch<React.SetStateAction<number | null>>
}

const TaskBagOfHoursFormAndTable: React.FC<TaskBagOfHoursFormAndTableProps> = ({ allRowData, userPermissions, setSelectedTaskId, setOpened, onSubmit }) => {
    const { t } = useTwinTranslation()
    const { data, handleChangeData } = useTaskBagOfHoursFormAndTableLogic({ allRowData })
    const customers = getCustomers()
    const params = useParams<{ id: string }>()
    const CustomerId = parseInt(params.id || '') 
    const fields: FormRender<METaskBagOfHoursModelTypeExtended> = [
        {
            cols: 4,
            elements: [
                {
                    name: 'CustomerId',
                    label: t('customer', 'Cliente'),
                    component: 'CustomSelect',
                    required: true,
                    items: customers?.all || {},
                    value: data?.CustomerProject?.CustomerId || CustomerId,
                    readOnly: true,
                },
                {
                    name: 'CustomerProjectId',
                    label: t('project', 'Proyecto'),
                    component: 'CustomSelect',
                    required: true,
                    items: data ? { [data?.CustomerProjectId]: data?.CustomerProject } : {},
                    readOnly: true
                },
                {
                    name: 'name',
                    label: t('name', 'Nombre'),
                    component: 'InputWithLabelMargin',
                    type: 'text',
                    required: true,
                },
                {
                    name: 'timeLimit',
                    label: t('timeLimit', 'Tiempo límite'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    required: true,
                    value: String(data?.timeLimit || 0),
                    onFinalChange: (value) => handleChangeData('timeLimit', value)
                },
                {
                    name: 'remainingTime',
                    label: t('remainingTime', 'Tiempo restante'),
                    component: 'InputHourMinSecUnlimitedStateLess',
                    value: String(data?.remainingTime || 0),
                    onFinalChange: (value) => handleChangeData('remainingTime', value)
                },
                {
                    name: 'expireDate',
                    label: t('expiration', 'Vencimiento'),
                    component: 'InputCalendarStateFull',
                    onlyValids: true,
                },
                {
                    name: 'id',
                    component: 'InputHidden'
                }
            ]
        }
    ]
    if (allRowData && userPermissions) {
        fields[0].elements.push({
            name: 'active',
            label: t('active', 'Activo'),
            component: 'CheckboxMargin',
        })
        fields[0].elements.push({
            name: 'sharing',
            label: t('share', 'Compartida'),
            component: 'CheckboxMargin',
        })
        const parsedFields = valueOrDefaultValue(fields, userPermissions, allRowData)
        return (
            <TwinForm className={'flex flex-auto flex-col '} action='/api/app/task/bagOfHours/updateTaskBagOfHours' onSubmit={(res, values) => { onSubmit(res, values); setOpened(null) }}>
                <ModalCreateEditStructureLayout translations={{ title: t('editBagOfHours', 'Editar bolsa de horas'), button: t('save', 'Guardar') }} haveButtonPermissions={permissionCheck(userPermissions, 'update')} className='modal_negative_margin flex flex-col' loading={false}>
                    <div className='px-30 flex flex-col'>
                        {fields.length ?
                            <FormRenderer className='flex flex-wrap' sections={parsedFields} />
                            : null}
                    </div>
                    {data ? <div className='flex flex-auto mx-30 mt-50'><TableTaskWorkLogs data={data.Tasks} setSelectedTaskId={setSelectedTaskId} /></div> : null}
                </ModalCreateEditStructureLayout>
            </TwinForm>
        )
    }
    return null
}

interface TaskBagOfHoursFormAndTableLogicProps {
    allRowData?: RowData
}

const useTaskBagOfHoursFormAndTableLogic = ({ allRowData }: TaskBagOfHoursFormAndTableLogicProps) => {
    const [data, setData] = useState<METaskBagOfHoursModelTypeExtended | null>(null)
    const getData = useCallback(async () => {
        const bagOfHours = await twinFetchPostJSON('/api/app/task/bagOfHours/getTaskBagOfHoursForDetail', { id: allRowData?.id })
        if (bagOfHours) {
            const result = parsedDataBagOfHoursDetail(bagOfHours)
            setData(result)
        }
    }, [setData, allRowData?.id])

    const handleChangeData = useCallback((field: keyof METaskBagOfHoursModelTypeExtended, value: number) => {
        setData((old) => {
            if (!old) {
                return null
            }
            const copyOld = JSON.parse(JSON.stringify(old))
            const parseTimeLimit = parseInt(String(copyOld.timeLimit))
            const parseRemainingTime = parseInt(String(copyOld.remainingTime))
            if (field === 'timeLimit') {
                if (value < parseRemainingTime) {
                    copyOld.remainingTime = value
                }
            } else if (field === 'remainingTime') {
                if (value > parseTimeLimit) {
                    copyOld.timeLimit = value
                }
            }
            return { ...copyOld, [field]: value }
        })
    }, [setData])

    useEffect(() => {
        getData()
    }, [getData])

    return { data, handleChangeData }
}

const modalEditTaskBagOfHoursDispatch = {
    setTaskCFields: (payload: TaskCFieldPayload) => ({ type: 'CHANGE_TASKCFIELD', payload }),
}

type ReduxModalEditTaskBagOfHours = ConnectedProps<typeof modalEditTaskBagOfHoursConnect>
const mapModalEditTaskBagOfHoursConnector = (state: AllReduxPayloads) => ({ taskCFields: state.taskCFields })
const modalEditTaskBagOfHoursConnect = connect(mapModalEditTaskBagOfHoursConnector, modalEditTaskBagOfHoursDispatch)

const taskConnectLoading = withLoading(ModalEditTaskBagOfHours, [{ fetchUrl: '/api/app/task/customField/getAllTaskCFields', propName: 'taskCFields', setFunctionName: 'setTaskCFields' }])
const ModalEditTaskBagOfHoursConnect = modalEditTaskBagOfHoursConnect(taskConnectLoading)

export default ModalEditTaskBagOfHoursConnect